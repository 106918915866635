.addadminuser-box {
  padding: 35px 32px;

  h2 {
    font-size: 24px;
    @extend .fw_600;
  }

  .form-wrapper {
    .form-steps {
      .form_group {
        .radio-box {
          .radio-info {
            @extend .w_100;
            @extend .position_relative;
            @extend .flex-just-start;

            [type="radio"]:checked + label {
              @extend .border_0;
              padding-left: 4.4rem;
              @extend .w_100;

              &::before {
                left: 0;
              }

              &::after {
                left: 0.6rem;
                width: 1.2rem;
                height: 1.2rem;
              }
            }
          }

          &.disable_box {
            .radio-info {
              [type="radio"]:checked + label:after,
              [type="radio"]:not(:checked) + label:after {
                content: "";
                background: $light-gray;
              }

              [type="radio"]:not(:checked) + label:before {
                border: 0.2rem solid #bdbdbd;
              }

              &.active {
                background-color: $form-input-active-bg;
                border-color: #b6d4f8;

                [type="radio"]:checked + label:before {
                  border-color: $light-gray;
                }

                label {
                  @extend .fs_16;
                  @extend .fw_700;
                  color: #bdbdbd;
                  line-height: 140%;
                }
              }

              &.disable {
                background-color: $white-color;
                border-color: $form-input-border;

                [type="radio"]:checked + label:before {
                  border-color: $light-gray;
                }

                label {
                  @extend .fs_16;
                  @extend .fw_500;
                  color: $light-gray;
                  line-height: 140%;
                }
              }
            }
          }
        }
      }
    }
  }
  .form-wrapper {
    form {
      .form-steps {
        .form_group {
          .form_control {
            padding-right: 3.5rem;
          }
          .input_fild_remove {
            background-color: $white-color;
            &.not_edit_bg {
              background: transparent;
            }
          }
        }
        .form-box{
          @extend .justify_content_start;
          column-gap: 2.8rem;
        }
      }
    }
  }
}

.form-wrapper {
  padding-top: 6rem;

  form {
    @extend .w_100;
    max-width: 80rem;

    h2 {
      @extend .fw_600;
      @extend .fs_40;
      @extend .mb_15;
      @extend .text_center;
      line-height: 4.9rem;
      color: $black-color;
    }

    .form-steps {
      margin-bottom: 4.8rem;

      .step-info {
        margin-bottom: 2.4rem !important;
        .step-count {
          width: 2.4rem;
          height: 2.4rem;
          @extend .radius-50;
          border: 0.2rem solid $dark-bg;
          @extend .fs_16;
          @extend .fw_700;
          color: $dark-bg;
          @extend .d_flex;
          @extend .justify_content_center;
          @extend .align_items_center;
          margin-right: 1.6rem;

          &.active {
            background-color: $dark-bg;
            color: $white-color;
          }

          &.disable {
            background-color: $separtor-color;
            color: $white-color;
            border-color: $separtor-color;
          }
        }

        p {
          @extend .fw_700;
          font-size: 1.8rem;
          @extend .text_capitalize;
          color: $dark-bg;
        }

        .dont_edit {
          color: $tab-text;
          @extend .fs_14;
          @extend .fw_400;
          line-height: 140%;

          img {
            width: 1.6rem;
            height: 1.6rem;
            filter: brightness(0.4) invert(0);
          }
        }

        margin-bottom: 2rem;
      }

      .form_group {
        margin-bottom: 0rem;

        label {
          @extend .fs_14;
          @extend .fw_700;
          color: $form-lable-color;
          line-height: 140%;
        }

        .radio-box {
          gap: 32px;
          @extend .position_relative;

          .radio-info {
            padding: 1.4rem;
            border: 0.1rem solid $form-input-border;
            border-radius: 0.8rem;
            @extend .w_100;
            transition: all 0.2s ease;
            width: 14rem;
            @extend .d_flex;
            @extend .justify_content_center;

            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
              @extend .position_absolute;
              left: -9999px;
            }

            [type="radio"]:checked + label,
            [type="radio"]:not(:checked) + label {
              @extend .position_relative;
              padding-left: 4.4rem;
              @extend .cursor_pointer;
              line-height: 2rem;
              color: $tab-text;
              height: 4.2rem;
              @extend .d_flex;
              @extend .align_items_center;
              @include trans;
            }

            [type="radio"]:checked + label {
              border: 0.1rem solid $blue-color;
              border-radius: 0.7rem;
              padding: 1rem 1.5rem 1rem 4.4rem;
              width: 14rem;
              // @include apply_animation(radiobtn_animate);
            }

            [type="radio"]:checked + label:before,
            [type="radio"]:not(:checked) + label:before {
              content: "";
              @extend .position_absolute;
              left: 0px;
              top: 50%;
              width: 2.4rem;
              height: 2.4rem;
              border: 0.2rem solid $tab-text;
              @extend .radius_round;
              background: $white-color;
              transform: translateY(-50%);
            }

            [type="radio"]:checked + label:before {
              left: 1rem;
            }

            [type="radio"]:checked + label:after,
            [type="radio"]:not(:checked) + label:after {
              content: "";
              width: 1.3rem;
              height: 1.3rem;
              @include apply_bgcolor(Light_blue_clr);
              @extend .position_absolute;
              top: 50%;
              left: 5px;
              @extend .radius_round;
              transform: translateY(-50%);
              opacity: 1;
            }

            [type="radio"]:checked + label:after {
              left: 1.5rem;
            }

            [type="radio"]:not(:checked) + label:after {
              opacity: 0;
              -webkit-transform: scale(0);
              transform: scale(0);
            }

            [type="radio"]:checked + label:before {
              border-color: $primary-color;
            }

            label {
              margin-bottom: 0;
              @extend .fs_14;
            }

            &.active {
              background-color: $form-input-active-bg;
              border-color: $primary-color;
            }

            .info_img {
              @extend .d_flex_center;
              width: 1.6rem;
              height: 1.6rem;
              @extend .position_absolute;
              top: 0.9rem;
              right: 1rem;
              @extend .cursor_pointer;

              &.admin_tooltip {
                &:hover {
                  .tooltip {
                    @extend .d_block;
                    top: -7.5rem;
                    left: -5.5rem;
                  }
                }
              }

              &.partner_tooltip {
                &:hover {
                  .tooltip {
                    @extend .d_block;
                    top: -6.5rem;
                    left: 0;
                  }
                }
              }

              img {
                width: 1.6rem;
                height: 1.6rem;
                filter: brightness(0.5) invert(0);
              }
            }
          }
        }

        .form_control {
          min-height: 4.8rem;
          @extend .fs_16;
          @extend .montserrat_regular;
          -webkit-appearance: none;
          color: $black-color;
          &::placeholder {
            opacity: 60%;
          }
          &.not_editable {
            color: $light-gray;
          }
        }

        .options {
          // border: 1px solid #bdbdbd;
          // box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
          // @extend .montserrat_regular;
          // border-radius: 4px;
          // margin-top: 8px;
          // padding: 8px 16px;
          // max-height: 290px;
          // overflow: auto;
          // @extend .position_absolute;
          // z-index: 99;
          // background: $white-color;
          // width: 100%;
          &.add_option_list {
            .list_school {
              top: 0;

              &.modal_option_list {
                height: 12.5rem;
              }
            }
          }

          .all_location {
            &:hover {
              background-color: $form-input-active-bg;
            }
          }

          .location_icon {
            margin-right: 1rem;
            margin-left: 1.6rem;
            object-fit: contain;
          }

          .i_main_options {
            margin: 0.8rem;
            @extend .cursor_pointer;
            .option {
              @extend .fs_16;
              @extend .montserrat_medium;
              @extend .fw_500;
              line-height: 140%;
              color: $tab-text;
            }

            .i_sub_location {
              color: $tab-text;
              @extend .fs_12;
              line-height: 140%;

              .more_option {
                @extend .montserrat_bold;
                color: $black-color;
              }
            }
          }

          .i_add_partner {
            color: $primary-color;
            @extend .fs_16;
            @extend .montserrat_bold;
            @extend .fw_700;
            line-height: 150%;
            @extend .align_items_center;
            @extend .d_flex;
            padding: 1.2rem 0;

            img {
              margin-left: 16px;
              margin-right: 12px;
            }
          }
        }

        .phone_number {
          @extend .position_relative;
          @extend .d_flex;
          border: 0.1rem solid $form-input-border;
          border-radius: 0.4rem;

          .selected-dial-code {
            padding-left: 1rem;
            font-size: 1.6rem;
            line-height: 140%;
            color: $light-gray;
            @extend .d_flex;
            @extend .align_items_center;
          }

          .form_control {
            letter-spacing: 0.2rem;
            padding-left: 1rem;
            border: none;
            margin: 0;
            min-height: 4.4rem;

            &::placeholder {
              color: $light-gray;
            }
          }

          .flag-container {
            .selected-flag {
              border-right: 0.1rem solid #9babb8;
              background-color: #f3f5f8;
              width: 66px;
              height: 100%;
              padding: 0 10px;
              @extend .align_items_center;
              @extend .flex-between;

              .flag_img {
                width: 26px;
                height: 17px;
              }

              .i_arrow {
                display: contents;
              }
            }
          }

          &.blue_border {
            border-color: $blue-color;
            box-shadow: 0 0 0 0.1rem $blue-color;
            min-height: 4rem;
          }
        }

        .select_contry_list {
          .list_school {
            @extend .position_absolute;
            @extend .w_100;
            z-index: 99;
            border: 0.1rem solid #bdbdbd;
            background: $white-color;
            box-shadow: 0px 0px 3rem 0px rgba(116, 116, 116, 0.15);
            max-height: 22rem;
            overflow: auto;
            overflow-x: hidden;
            border-radius: unset;
            scrollbar-width: thin; /* For FireFox Only */

            .all_location {
              padding: 0.8rem 1.6rem;
              color: $tab-text;
              @extend .fs_16;
              @extend .fw_500;
              line-height: 140%;

              &:hover {
                background-color: $form-input-active-bg;
              }
            }

            &.edit_option_list {
              height: 12.5rem;
              top: 0;
            }

            .i_add_partner {
              color: $primary-color;
              @extend .fs_16;
              @extend .montserrat_bold;
              @extend .fw_700;
              line-height: 150%;
              @extend .align_items_center;
              @extend .d_flex;
              padding: 12px 0;
              @extend .cursor_pointer;

              img {
                margin-left: 16px;
                margin-right: 12px;
              }
            }
          }
        }
      }

      .form-box {
        @extend.justify_content_start;
        column-gap: 1.8rem;
        .form_group {
          width: 40%;
          margin-bottom: 2.4rem;

          label {
            @extend .fs_14;
          }
        }
      }

      .btn-wrapper {
        @extend .position_relative;
        margin-top: -1.5rem;
        .btn {
          min-width: 20rem;
          background-color: $grey-text;
          @extend .text_center;
          @extend .justify_content_center;
          // margin-left: 4.8rem;
          color: $white-color;
          &:not(.add_change_btn){
            &::after{
              display: none;
            }
          }
          img {
            margin-left: 1.2rem;
          }

          &.add_change_btn {
            @include apply_bgcolor(Light_blue_clr);

            img {
              @extend .d_none;
            }
          }
        }

        .simple-link {
          @extend .fs_16;
          line-height: 2.2rem;
          @extend .fw_700;
          color: $primary-color;
          @include trans;
          margin-right: 4.8rem;

          &:hover {
            color: $dark-bg;
          }
        }

        &.partner_tooltip {
          .primary_btn {
            .tooltip {
              @extend .d_block;
              min-height: auto;
              min-width: 25.8rem;
              top: 0;
            }
          }
        }
        .tooltip_hover {
          @extend .position_relative;
          &:hover {
            .tooltip {
              @extend .d_block;
              color: $white-color;
              @extend .fs_14;
              @extend .fw_400;
              line-height: 140%;
              top: -6rem;
              left: -4rem;
              min-width: 29rem;
              @extend .text_center;
              min-height: 5.6rem;

              .tool_more {
                @extend .d_block;
                @extend .fw_700;
              }
            }
          }
          &.partner_tooltip {
            &:hover {
              .tooltip {
                min-height: auto;
                top: -4rem;
              }
            }
          }
        }
      }
    }

    .selected_school_title {
      @extend .d_inline_block;
      color: $tab-text;
      @extend .fs_14;
      @extend .fw_700;
      line-height: 19px;
      margin-bottom: 1.2rem;
    }
  }
}

.mentors_form {
  .form_small_container {
    .form-wrapper {
      form {
        .form-steps {
          .form_group {
            .radio-box {
              .radio-info {
                [type="radio"]:checked + label {
                  min-width: 9.3rem;
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

.add_mentors_user {
  .form_small_container {
    .form-wrapper {
      .mentor_info {
        .form-steps {
          .form_group {
            .radio-box {
              .radio-info {
                width: auto;
                [type="radio"]:checked + label {
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

.admin_user_page {
  .comman_head {
    padding: 0;
    .main-head {
      padding: 1.25rem 3.2rem;
    }
    .filter_dropdown {
      border-top: 0.1rem solid $border-color;
      .form-wrapper {
        padding: 0 3.2rem;
        margin: 1.25 0;
      }
    }
  }
  .table_wrapper {
    .table_responsive {
      .table {
        tr {
          &.first_one {
            &:first-child {
              .dot_icon_modal {
                .dot_menu_modal {
                  @extend .position_absolute;
                }
              }
            }
          }
          &.last_three {
            .dot_icon_modal {
              .dot_menu_modal {
                bottom: 0 !important;
              }
            }
          }
          &.first_one.last_three {
            &:first-child {
              .dot_icon_modal {
                .dot_menu_modal {
                  bottom: auto !important;
                  @extend .position_fixed;
                }
              }
            }
          }
        }
      }
    }
  }
}

.add_admin_user_form {
  + .modal_comman {
    .add_partner {
      max-height: 62rem;
    }
  }
  .form_group {
    .input_fild_remove {
      &.not_edit_bg {
        img {
          filter: brightness(0.5) invert(1);
        }
      }
    }
  }

  .add_partner {
    &.add_plus_btn {
      &.add_disable {
        .form_group {
          .add_plus_icon {
            svg {
              rect {
                fill: $primary-color;
              }
              path {
                fill: $white-color;
                stroke: $white-color;
              }
            }
          }
        }
      }
    }
  }
}
