.surveys_wrapper {
  flex-basis: 100%;
  align-items: end;

  .survey_item {
    background-color: #b6d4f86e;
    padding: 13px;
    border-radius: 3px;
    border: solid #e1e1e1 1px;
    cursor: pointer;
    margin-bottom: 20px;
  }
}
.question_item {
  margin-bottom: 2em;
  font-size: 1.3em;
}

.pairs_page {
  .table_wrapper {
    .d_flex_between {
      &.mb_10 {
        margin-bottom: 0;
      }
    }
  }
  .pairs_activities {
    @extend .w_100;
    max-width: 94rem;
    @extend .m_auto;
    @extend .radius_8;
    border: 0.2rem solid #dceafb;
    padding: 3.2rem 0;
    margin: 7.3rem auto;
    @extend .position_relative;
    .inner_pairs_activity {
      padding: 0 2.9rem;
      .pagination {
        padding: 0;
      }
    }
    h2 {
      color: $black-color;
      @extend .fs_32;
      @extend .fw_600;
      @extend .text_center;
      @extend .mb_40;
      line-height: 110%; /* 35.2px */
    }
    .pair_tabs {
      margin-bottom: 3.2rem;
      ul.active_tabs {
        @extend .d_flex_center;
        gap: 0.8rem;
        li {
          @extend .d_flex_center;
          @extend .fs_18;
          @extend .fw_500;
          line-height: 140%;
          border-radius: 8px 8px 2px 2px;
          color: $tab-text;
          background: transparent;
          padding: 0.8rem 1.5rem;
          min-height: 4.7rem;
          @extend .w_100;
          min-width: 21.2rem;
          @extend .white_space_nowrap;
          @extend .text_center;
          @extend .cursor_pointer;
          border-bottom: 0.4rem solid transparent;

          &.active {
            @extend .fw_700;
            color: $dark-blue;
            background: $form-input-active-bg;
            border-bottom: 0.4rem solid $primary-color;
          }
        }
      }
    }
    .serch_media_file {
      @extend .d_flex_center;
      @extend .justify_content_between;
      margin-bottom: 3.2rem;
      .form_group {
        @extend .w_100;
        max-width: 66.5rem;
        @extend .position_relative;
        margin: 0;

        .form_control {
          @extend .fs_16;
          @extend .fw_400;
          line-height: 2.2rem;
          color: $title-color;
          border: 0.2rem solid $border-color;
          padding: 0.8rem;
          padding-left: 5rem;
          padding-right: 4rem;
          border-radius: 10rem;
          min-height: 4rem;
          &:focus {
            border: 2px solid $blue-color;
            box-shadow: 0px 0px 3rem 0px rgba(116, 116, 116, 0.15);
          }
        }
        .serch_icon {
          @extend .d_flex_center;
          width: 2.4rem;
          height: 2.4rem;
          @extend .position_absolute;
          left: 1.6rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .remove_search_history {
        @extend .position_absolute;
        @extend .d_flex;
        width: auto;
        height: auto;
        right: 1.6rem;
        top: 50%;
        transform: translateY(-50%);
      }
      .remove_search_history_text {
        @extend .position_absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 4rem;
        color: $dark-blue;
      }
      .events_select {
        label {
          @extend .fw_700;
          font-size: 1.2rem;
          margin-bottom: 0.4rem;
          @extend .d_block;
        }
        .select_form_control {
          @extend .w_100;
          border-radius: 0.4rem;
          border: 0.1rem solid $separtor-color;
          background: $white-color;
          width: 24rem;
          min-height: 4rem;
          background: url(../images/Dropdown_Arrow.svg) center right 1rem
            no-repeat;
          appearance: none;
          padding: 0 1.6rem;
        }
      }
      &.serch_event {
        align-items: end;
        .search_event_box {
          max-width: 60.4rem;
        }
        .event_dropdown {
          min-width: 24rem;
          padding: 0 1.6rem;
          background: url(../images/filter_drop_down_arrow.svg) center right
            1.5rem no-repeat;
          appearance: none;
          border-radius: 0.4rem;
        }
      }
    }
    .chat_pairs {
      @extend .radius_8;
      border: 0.1rem solid #dceafb;
      background: rgba(231, 241, 253, 0.35);
      padding: 2.4rem;
      max-height: 73.4rem;
      overflow-y: auto;
      @extend .d_flex;
      @extend .flex_direction_column_reverse;
      position: static;
      .msg_time {
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
      }
      .chat_history_day {
        @extend .d_flex_center;
        color: $form-input-color;
        @extend .fs_12;
        @extend .fw_700;
        line-height: 140%;
        min-height: 4.2rem;
      }
      .recive_msg {
        @extend .d_flex;
        @extend .align_items_end;
        @extend .justify_content_start;
        gap: 0.8rem;
        .pic_of_recived {
          @extend .d_flex;
          @extend .justify_content_center;
          @extend .w_100;
          max-width: 4.2rem;
          height: 4.2rem;
          @extend .radius_round;
          @extend .position_relative;
          margin-bottom: 0;
          @extend .align_items_end;
          &:hover {
            .tooltip {
              display: block !important;
              top: -4rem;
              left: -3rem;
            }
          }
          img {
            width: 4.2rem;
            height: 4.2rem;
            @extend .img_object_center;
            @extend .radius_round;
            @extend .radius_round;
            box-shadow: 2.3333334922790527px 2.3333334922790527px
              11.666666984558105px 0px rgba(134, 172, 218, 0.44);
            border: 0.1rem solid $white-color;
            width: 4.2rem;
            height: 4.2rem;
            @extend .radius_round;
            box-shadow: 2.3333334922790527px 2.3333334922790527px
              11.666666984558105px 0px rgba(134, 172, 218, 0.44);
          }
          .tooltip {
            @include tooltip();
          }
          .user_pic {
            padding: 1.5rem;
            width: 4.2rem;
            height: 4.2rem;
            font-size: 1.5rem;
          }
        }
        .msg_of_recived {
          @extend .position_relative;
          label {
            @extend .d_block;
            color: $black-color;
            @extend .fs_14;
            @extend .fw_700;
            line-height: 140%;
            margin-bottom: 0.8rem;
          }
          .img_view_show {
            img {
              width: 30rem;
              height: 20rem;
              @extend .img_object_center;
            }
          }
          .msg {
            color: $form-lable-color;
            @extend .fs_16;
            @extend .fw_500;
            line-height: 140%;
            padding: 1.6rem;
            border-radius: 0.8rem 0.8rem 0.8rem 0;
            border: 0.1rem solid $grey-100;
            background: $white-color;
            // margin-bottom: 0.8rem;
            word-break: break-word;
            span{
              word-break: break-word;
            }
            .link_btn{
              margin-left: 5px;
              color: $primary-color;
            }
            a:not(.link_btn) {
              color: $primary-color;
              font-style: normal;
              text-decoration: underline;
            }
            .link_btn{
              margin-left: 5px;
            }
          }
          
          .react_emoji {
            border: 0.1rem solid $primary-color;
            @extend .radius_round;
            width: 3rem;
            height: 3rem;
            @extend .d_flex_center;
            @extend .position_absolute;
            bottom: -0.8rem;
            right: -0.8rem;
            z-index: 1;
            background: $white-color;
            span {
              &.default_emoji {
                margin-top: 0;
                @extend .fs_16;
              }
            }
          }
        }
        &.send_msg {
          @extend .justify_content_end;
          .msg_of_recived {
            @extend .text_right;
            .msg {
              color: $white-color;
              border-radius: 0.8rem 0.8rem 0px 0.8rem;
              background: #1168d0;
              @extend .text_left;
              word-break: break-all;
              span{
                a{
                  color: currentColor;
                }
              }
            }
            .msg_time {
              color: $black-color;
              @extend .fs_12;
              @extend .fw_400;
              line-height: 140%;
              margin-top: 2.8rem;
              margin-bottom: 0.8rem;
              margin-top: 0.8rem;
            }
            .react_emoji {
              border: 0.1rem solid $primary-color;
              @extend .radius_round;
              width: 3rem;
              height: 3rem;
              @extend .d_flex_center;
              @extend .position_absolute;
              bottom: -1rem;
              right: auto;
              left: -0.5rem;
              z-index: 1;
              background: $white-color;
              cursor: default;
              span {
                &.default_emoji {
                  margin-top: 0;
                  @extend .fs_16;
                }
              }
            }
            .link_btn{
              color: $white-color;
            }
          }
          .pdf_send {
            @extend .cursor_pointer;
            @extend .d_flex;
            @extend .align_items_center;
            margin-right: 0;
            gap: 1rem;
            .pdf_file {
              width: 3.2rem;
              height: 3.2rem;

              img {
                width: 3.2rem;
                height: 3.2rem;
              }
            }
            .pdf_title {
              .pdf_name {
                color: $black-color;
                @extend .fs_18;
                @extend .fw_700;
                line-height: 140%;
              }
              .pdf_size {
                color: $black-color;
                @extend .text_right;
                @extend .fs_12;
                @extend .fw_400;
                line-height: 140%;
                margin-right: 2.5rem;
              }
            }
          }
          &.file_class {
            border: 0.1rem solid #cccccc8d;
            border-radius: 1rem;
            padding: 1rem;
          }
        }
      }
      .mesg_chat_loader {
        top: 28%;
        right: 0;
        left: 0;
        z-index: 9999;
      }
      .chat_loading_area_mesg {
        background-color: $primary-color;
        color: $white-color;
        border-radius: 1rem;
        max-width: 14rem;
        margin: auto;
        padding: 0.7rem 0;
        span {
          animation: spin 1s linear infinite;
          > img {
            filter: invert(21);
          }
          @keyframes spin {
            0% {
              transform: rotate(0);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
    }

    .Events_sec {
      .events_wrapper {
        h4 {
          @extend .fw_700;
          color: $form-input-color;
          font-size: 2rem;
        }
      }
      .card {
        margin: 0 -1.2rem;
        padding-top: 3rem;
        .card_body {
          width: 33.33%;
          padding: 0 1.2rem 2.4rem;
          .card_inner {
            border-radius: 0.8rem;
            border: 0.1rem solid $border-color;
            background: $white-color;
            box-shadow: 0.8rem 0.4rem 2.5rem 0 rgba(82, 99, 116, 0.04);
            min-width: 27.8rem;
            @extend .over_hidden;
            .card_content {
              padding: 1.6rem;
              img {
                @extend .w_100;
              }
              .card_title {
                @extend .fw_700;
                font-size: 1.8rem;
                line-height: 140%;
                color: $black-color;
                margin-bottom: 1.6rem;
                height: 7.5rem;
                @extend .over_hidden;
              }
              p {
                @extend .fw_700;
                color: $primary-color;
                font-size: 1.6rem;
                line-height: 150%;
                margin-bottom: 4rem;
              }
              span {
                @extend .fw_400;
                color: $black-color;
                font-size: 1.4rem;
                line-height: 140%;
              }
              .map_location {
                gap: 0.5rem;
                img {
                  width: auto;
                  max-width: 100%;
                  &.map_point_svg {
                    width: 2.4rem;
                    height: 2.4rem;
                  }
                }
              }
              .content_tag {
                @extend .fw_700;
                font-size: 1.2rem;
                color: $primary-color;
                margin-bottom: 0.8rem;
                @extend .d_block;
              }
              .post_time {
                font-size: 1.4rem;
                @extend .fw_400;
                color: $form-input-color;
                margin-bottom: 2rem;
              }
              .user_post_by {
                @extend .fw_500;
                color: $tab-text;
                @extend .over_hidden;
                gap: 0.8rem;
                img {
                  width: 3.2rem;
                  height: 3.2rem;
                  border-radius: 10rem;
                }
              }
            }
            img {
              max-width: 100%;
              height: 14.8rem;
              @extend .img_object_center;
              @extend .w_100;
            }
          }
        }
      }
      &.content_accessed_sec {
        .card {
          .card_body {
            .card_inner {
              .card_content {
                padding: 2rem;
                .card_title {
                  height: 5rem;
                  margin-bottom: 0.8rem;
                  max-width: 22rem;
                }
              }
            }
          }
        }
        .slide_btn_wrapper {
          gap: 3.2rem;
          padding-right: 2.4rem;
          .content_arrow {
            gap: 1.6rem;
            .slide_btn {
              border-radius: 10rem;
              border: 0.1rem solid $tab-text;
              width: 2.4rem;
              height: 2.4rem;
            }
          }
          .back_content {
            @extend .d_flex;
            gap: 1.2rem;
            img {
              transform: rotate(180deg);
            }
          }
          span {
            @extend .fw_700;
            font-size: 1.4rem;
            color: $primary-color;
          }
        }
        .events_wrapper {
          padding: 1.5rem 0 1rem 0;
          .content_slide_view {
            > .content_arrow {
              gap: 4rem;
              > .slide_btn {
                border-radius: 10rem;
                border: 0.1rem solid $tab-text;
                width: 2.4rem;
                height: 2.4rem;
              }
              span {
                @extend .position_relative;
                margin: 0 0 0 -2.4rem;
                &::after {
                  content: "";
                  width: 1rem;
                  height: 1rem;
                  background-color: $primary-color;
                  border-radius: 10rem;
                  @extend .position_absolute;
                  top: 50%;
                  right: -2.5rem;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }
      &.events_projects {
        .card {
          .card_body {
            .card_inner {
              .card_content {
                .hastag {
                  gap: 0.5rem;
                }
                .card_title {
                  max-width: 22rem;
                  height: 5rem;
                  margin-bottom: 1rem;
                }
                .user_of_prosess {
                  @extend .d_flex_center;
                  gap: 1.5rem;
                  margin-bottom: 2.4rem;
                  .user_pic {
                    @extend .d_flex_center;
                    @extend .w_100;
                    max-width: 2.4rem;
                    height: 2.4rem;
                    border-radius: 23.668px;
                    border: 0.1rem solid $white-color;
                    box-shadow: 1.7222224473953247px 1.7222224473953247px
                      8.611111640930176px 0px rgba(134, 172, 218, 0.44);
                    padding: 0;
                    &.user_profile_process_data {
                      max-width: 3.4rem;
                      height: 3.4rem;
                      margin-right: 0;
                    }
                    img {
                      @extend .w_100;
                      height: 100%;
                      border-radius: 23.668px;
                      @extend .img_object_center;
                    }
                  }
                  .user_progresing {
                    @extend .w_100;
                    .user_progess_percentage {
                      @extend .d_flex_center;
                      @extend .justify_content_between;
                      margin-bottom: 0.8rem;
                      .progess {
                        @extend .d_block;
                        color: $black-color;
                        @extend .fs_14;
                        @extend .fw_600;
                      }
                      .percentage {
                        @extend .d_block;
                        color: $black-color;
                        @extend .fs_14;
                        @extend .fw_500;
                      }
                    }
                    .progrees_bar {
                      @extend .w_100;
                      height: 0.7rem;
                      border-radius: 100px;
                      background: #d9d9d9;
                      @extend .over_hidden;
                      .progress {
                        @extend .d_block;
                        @include apply_bgcolor(Light_blue_clr);
                        @extend .w_100;
                        height: 100%;
                      }
                    }
                  }
                }
              }
              .multi_member {
                gap: 1.6rem;
                margin-bottom: 3rem;
                padding-left: 0.8rem;
                span {
                  overflow: hidden;
                  width: 2.5rem;
                  height: 2.5rem;
                  @extend .d_inline_block;
                  margin-left: -0.8rem;
                  img {
                    border-radius: 10rem;
                    width: 2.5rem;
                    height: 2.5rem;
                  }
                }
                .count_member {
                  margin-bottom: 0;
                  @extend .fw_500;
                  font-size: 1.4rem;
                  color: $tab-text;
                  span {
                    height: auto;
                    @extend .fw_700;
                    color: $primary-color;
                  }
                }
              }
            }
          }
        }
      }
      .custom_modalbox {
        background-color: rgba(22, 35, 83, 0.3);
        box-shadow: 0 0 3rem 0 hsla(0, 0%, 45%, 0.15);
        @extend .d_grid;
        @extend .position_fixed;
        height: 100%;
        @extend .w_100;
        left: 0;
        @extend .over_hidden;
        place-items: center;
        top: 0;
        z-index: 99;
        animation: popupRoll 0.6s ease-in-out forwards;
        @keyframes popupRoll {
          0% {
            opacity: 0;
            transition: all 0.6s ease-in-out;
          }
          100% {
            opacity: 1;
            transition: all 0.6s ease-in-out;
          }
        }
      }
    }
    &::after {
      content: "";
      @extend .position_absolute;
      border: 0.1rem solid #dceafb;
      top: -25rem;
      right: 0;
      left: 0;
      width: 0.1rem;
      height: 25rem;
      margin: 0 auto;
    }
    .message_box {
      height: auto;
      .chat_box {
        padding-top: 0;
        width: 26.1rem;
        .chatbox_area {
          padding: 0;
          height: auto;
          overflow: initial;
          .content_wrapper_video {
            .content_video_inner {
              .content_video {
                > p {
                  background-color: $dark-blue;
                  border-radius: 0 0 0.8rem 0.8rem;
                }
              }
              > span {
                &.msg_content_pic {
                  img {
                    height: 15rem;
                    border-radius: 0.8rem;
                  }
                }
              }
            }
          }
          .chatting {
            .react_emoji {
              bottom: 0;
            }
          }
        }
      }
    }
  }
  .Pagination_wrapper {
    border-top: 0.1rem solid #dceafb;
    background: $white-color;
    box-shadow: 0px 0px 3rem 0px rgba(214, 226, 245, 0.2);
  }
  .switch {
    margin-bottom: 1.2rem;
  }
  .no_conversation {
    .no_conversation_wrapper {
      background: rgba(231, 241, 253, 0.35);
      border-radius: 0.8rem;
      padding: 2.4rem;
      .image {
        width: 11.5rem;
        height: 11.5rem;
        background-color: $form-input-active-bg;
        border-radius: 10rem;
        @extend .d_flex;
        @extend .align_items_center;
        @extend .justify_content_center;
        margin: 0 auto 2.4rem;
      }
      .content {
        h3 {
          @extend .fw_600;
          font-size: 2.4rem;
          line-height: 110%;
          color: $tab-head;
          @extend .text_center;
          margin-bottom: 1.6rem;
        }
        span {
          @extend .fw_400;
          font-size: 1.6rem;
          line-height: 140%;
          max-width: 30.4rem;
          @extend .d_block;
          @extend .text_center;
          margin: 0 auto 1.5rem;
        }
      }
      &.no_result_event {
        background: transparent;
        padding-top: 7rem;
        padding-bottom: 5rem;
        .image {
          img {
            width: 5.6rem;
            height: 5.6rem;
          }
        }
      }
    }
  }

  .potential_match_details {
    .main_send_msg {
      .inner_details {
        .profile_img {
          margin-bottom: 1.6rem;
        }
        button {
          min-width: 18.2rem;
          @extend .gap_12;
          min-height: 4rem;
          padding: 0.6rem 2.4rem;
          &:hover {
            border: 0.2rem solid $primary-color;
            > img {
              filter: brightness(21);
            }
          }
        }
      }
    }
    .pairs_activity_detail {
      .pair_activity_info {
        &:first-child {
          padding-left: 4rem;
        }
      }
      .main_user_info {
        border: 0;
        &:first-child {
          .user_info {
            &.pair_activity_userinfo {
              padding-right: 0;
              padding-left: 9rem;
              min-width: 29rem;
              max-width: 29rem;
            }
          }
        }
        &:last-child {
          .user_info {
            &.pair_activity_userinfo {
              padding-right: 0;
              padding-left: 2rem;
              min-width: 29rem;
              max-width: 29rem;
            }
          }
        }
      }
    }
  }
  .i_popup {
    .i_popup_content {
      .send_main_form {
        .form-group.mail_msg {
          textarea {
            min-height: 13.2rem;
          }
        }
      }
    }
  }

  .table_wrapper {
    .table_responsive {
      .pairs_user_listing {
        min-width: 135rem;
        &.disabled {
          tbody {
            tr {
              opacity: 0.65;
              background: #f6f6f6;
            }
          }
        }
      }
    }
  }
  .chat_pairs::-webkit-scrollbar {
    width: 0.8rem;
    height: 8rem;
  }
  /* Track */
  .chat_pairs::-webkit-scrollbar-track {
    background: $form-input-active-bg;
    border-radius: 5px;
  }

  /* Handle */
  .chat_pairs::-webkit-scrollbar-thumb {
    background: $lighten-dark-blue;
    border-radius: 100px;
    height: 8rem;
  }
  .i_popup {
    &.i_new_msg_popup {
      .i_popup_content {
        max-height: 82rem;
        height: 90%;
        @extend .d_flex;
        @extend .flex_direction_column;
        .i_popup_header {
          padding-bottom: 3.2rem;
        }
      }
    }
  }

  .pair_report_main {
    padding-top: 3rem;
    padding-bottom: 3rem;
    .pair_report_inner {
      padding: 0 3.2rem;
      .reporting_ul {
        .pairs_reporting_li {
          border-radius: 0.8rem;
          border: 0.1rem solid $border-color;
          background: $white-color;
          box-shadow: 0.8rem 0.4rem 2.5rem 0 rgba(82, 99, 116, 0.04);
          min-width: 26.476rem;
          max-width: 27rem;
          min-height: 40rem;
          .reporting_head {
            border-radius: 0.8rem 0.8rem 0 0;
            background: rgba(19, 115, 231, 0.1);
            padding: 2rem 0 2rem 2rem;
            @extend .d_flex;
            @extend .align_items_center;
          }
          .pairs_reporting_main_wrap {
            > ul {
              .inner_div_pairs {
                min-height: 20rem;
              }
              li {
                padding: 1rem 0;
                .progress_detail {
                  .sent_by_name {
                    color: $black-color;
                  }
                  .persantage {
                    color: $light-gray;
                  }
                }
                .progress_graph {
                  background-color: $border-color;
                  .graph {
                    background-color: $border-color;
                    min-height: 0.4rem;
                    @extend .w_100;
                    @extend .d_block;
                    border-radius: 1rem;
                    max-width: 100%;
                    &.Pairs_graph_active {
                      background-color: $primary-color;
                    }
                  }
                }
              }
            }
            .message {
              color: $light-gray;
            }
            .counting {
              color: $primary-color;
            }
          }

          .pairs_reporting_main_wrap.skeleton_li {
            .skeleton_profile {
              width: 15%;
              height: 3.2rem;
              .react-loading-skeleton {
                height: 100%;
              }
            }
            .message_width {
              width: 70%;
            }
            .sent_by_name {
              width: 64%;
            }
            .persantage {
              width: 20%;
            }
            .progress_graph {
              height: 0.7rem;
              .react-loading-skeleton {
                height: 0 !important;
              }
            }
          }
        }
      }
    }
  }
  .voice_msg {
    max-width: 33rem;
  }
}

.i_popup {
  &.i_new_msg_popup {
    .i_popup_content {
      .send_main_form {
        flex: 1;
        overflow: auto;
      }
      .i_popup_footer {
      }
      .mesg_image_upld_wrapper {
        .upload_img_mesg_li {
          .mesg_image_upld {
            border: 0.1rem solid $border-color;
            border-radius: 0.8rem;
            @extend .over_hidden;
            @extend .d_flex_center;
            > img {
              height: 12rem;
            }
            .loader_bg {
              background: transparent;
              .loader {
                > img {
                  padding: 4.5rem;
                }
              }
            }
            .white_bg {
              background-color: $white-color;
              border-radius: 0.8rem;
            }
          }
        }
      }
      .upload_file_mesg {
        overflow: auto;
      }
    }
  }
}

.modal_popup.audio_popup.zoomin_popup {
  @extend .position_fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(4.9064698219px);
  background-color: rgba(45, 45, 45, 0.65);
  height: 16.6rem;
  max-width: 85.2rem;
  @extend .w_100;
  padding: 2.4rem 3.2rem;
  border-radius: 2rem;
  @include trans;
  animation: zoom 0.6s ease-in-out forwards;

  @keyframes zoom {
    0% {
      opacity: 0;
      transition: all 0.4s ease-in-out;
    }
    100% {
      opacity: 1;
      transition: all 0.8s ease-in-out;
    }
  }
}
.audio_popup {
  .modal_heading {
    margin-bottom: 4rem;
  }
  h2.text_left {
    color: $white-color;
    @extend .fs_24;
    @extend .fw_600;
    line-height: normal;
    margin-bottom: 0;
  }
  .modal_popup_close {
    img {
      filter: brightness(0) invert(1);
    }
  }
  .control__box {
    .inner__controls {
      @extend .d_flex;
      @extend .align_items_center;
      @extend .justify_content_around;
      gap: 3rem;
      padding: 0 1rem;
      height: 4.1rem;
      .play_push_btn {
        img {
          width: 3.8rem;
          height: 3.8rem;
          object-fit: contain;
        }
      }
      .play_btn_circle_pause {
        background-color: $white-color;
        width: 3.8rem;
        height: 3.8rem;
        @extend .d_flex_center;
        border-radius: 10rem;
        img {
          width: 1.2rem;
          height: 1.2rem;
          filter: brightness(0.3);
        }
      }
    }
    .spanVideo {
      color: $white-color;
      font-size: 23.512px;
      @extend .fw_400;
      line-height: normal;
      min-width: fit-content;
    }
    .progress_bar {
      @extend .w_100;
      max-width: 51.4rem;
      input {
        @extend .w_100;
      }
    }
  }
}
.modal_popup.video_popup.zoomin_popup {
  @extend .position_fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @extend .w_100;
  height: 59.9rem;
  border-radius: 3rem;
  overflow: hidden;
  max-width: 1024px;
  animation: zoom 0.6s ease-in-out forwards;

  @keyframes zoom {
    0% {
      opacity: 0;
      transition: all 0.4s ease-in-out;
    }
    100% {
      opacity: 1;
      transition: all 0.8s ease-in-out;
    }
  }
}
.video_popup {
  video {
    object-fit: cover;
  }
  .modal_popup_close {
    z-index: 999;
    cursor: pointer;
  }
  .modal_heading {
    @extend .position_absolute;
    top: 0;
    background: transparent;
    padding: 2.4rem 3.2rem;
    @extend .w_100;
    opacity: 0;
    transition: all 0.6s ease-in-out;
  }
  .mid__container {
    @extend .position_absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @extend .d_flex;
    @extend .align_items_center;
    gap: 11.5rem;
    opacity: 0;
    transition: all 0.6s ease-in-out;
    button {
      > img {
        width: 5rem;
        height: 5rem;
        object-fit: contain;
      }
    }
  }
  .bottom__container {
    @extend .position_absolute;
    bottom: 0;
    @extend .w_100;
    max-width: 944px;
    margin: 0 auto;
    background: var(--Button, rgba(45, 45, 45, 0.65));
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    opacity: 0;
    transition: all 0.6s ease-in-out;
    .play_btn_circle_pause {
      background-color: $white-color;
      width: 3.8rem;
      height: 3.8rem;
      @extend .d_flex_center;
      border-radius: 10rem;
      img {
        width: 1.2rem;
        height: 1.2rem;
        filter: brightness(0.3);
      }
    }
  }
  h2.text_left {
    color: $white-color;
    @extend .fs_24;
    @extend .fw_600;
    line-height: normal;
    margin-bottom: 0;
  }
  .modal_popup_close {
    img {
      filter: brightness(0) invert(1);
    }
  }
  .control__box {
    .inner__controls {
      @extend .d_flex;
      @extend .align_items_center;
      justify-content: space-around;
      gap: 3rem;
      button {
        > img {
          width: 3.8rem;
          height: 3.8rem;
          object-fit: contain;
        }
      }
    }
    .play_push_btn {
      width: 3.58rem;
      height: 3.58rem;
      border-radius: 100%;
      background: $white-color;
    }
    .spanVideo {
      color: $white-color;
      font-size: 23.512px;
      @extend .fw_400;
      line-height: normal;
      min-width: fit-content;
    }
    .progress_bar {
      @extend .w_100;
      max-width: 51.4rem;
      input {
        @extend .w_100;
      }
    }
  }

  .play_btn {
    border-bottom: 2.5rem solid $white-color;
    border-left: 2.5rem solid $white-color;
    border-color: $white-color $white-color transparent transparent;
    border-radius: 0.5rem;
    border-style: solid;
    border-width: 2.5rem;
    height: 5rem;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 5rem;
    @extend .d_flex;
  }

  &:hover {
    .modal_heading {
      opacity: 1;
      transition: all 0.6s ease-in-out;
    }
    .video_popup {
      .mid__container {
        opacity: 1;
        transition: all 0.6s ease-in-out;
      }
      .bottom__container {
        opacity: 1;
        transition: all 0.6s ease-in-out;
      }
    }
  }
}
.highlighted_mentor_search {
  background-color: #dceafb;
  color: #333333;
  @extend .fw_700;
  padding: 0.45rem 0.35rem;
}
.highlighted_mentee_search {
  background-color: #072851;
  color: #ffff;
  @extend .fw_700;
  padding: 0.45rem 0.35rem;
}
.search_length {
  width: auto;
  height: auto;
  right: 3.8rem;
  top: 50%;
  transform: translateY(-50%);
}
.msg {
  white-space: pre-line;
}

.chat_admin_msg,
.chat_admin_file{
  max-width: calc(100% - 10rem);
  width: 100%;
  margin: 0 auto 30px;
  + .msg_time{
    @extend .text_center;
  }
  .msg_time{
    @extend .text_center; 
    margin-bottom: 0.8rem;
    width: 100%;
    order: 1;
  }
  ul{
    @extend .d_flex;
    @extend .flex_direction_column;
    @extend .align_items_center;
    li{
      border-radius: 0px 4px 4px 4px;
      background: $dark_gray;
      padding: 16px;
      font-style: italic;
      @extend .fw_500;
      @extend .fs_16;
      line-height: 140%; 
      color: $white-color;
      border: 1px solid #FFF;
      @extend .text_center;
      .link_btn{
        margin-left: 5px; 
        color: $white-color;
      }
      span{
        a {
          color: currentColor;
          font-style: normal;
          text-decoration: underline
        }
        @extend .fs_16;
      }
    }
  }
}

.chat_admin_file{
  .msg_time{
    @extend .text_center;
  }
  .admin_file_list{
    @extend .d_flex;
    @extend .flex_wrap;
    @extend .align_items_center;
    @extend .justify_content_center;
    gap: 2rem;
    &:not(.msg_time) > div{
      order: 2;
    }
    .recive_msg{
      &.send_msg {
        order: 2;
      }
    }
    .admin_pdf_send{
      @extend .d_flex;
      @extend .align_items_center;
      order: 2;
      gap: 1rem;
      .pdf_file{
        img{
          @extend .d_block;
        }
      }
      .pdf_title{
        @extend .d_flex;
        @extend .align_items_center;
        gap: 1rem;
        padding: 0 10px;
        .pdf_name {
          color: $black-color;
          @extend .fs_18;
          @extend .fw_700;
          line-height: 140%;
        }
        .pdf_size {
          color: $black-color;
          @extend .text_right;
          @extend .fs_12;
          @extend .fw_400;
          line-height: 140%;
        }
      }
    }
    .admin_img_send{
      max-width: 180px;
      width: 100%;
      border: 1px solid $border-color;
      border-radius: 8px;
      position: relative;
      padding-bottom: 29.2%;
      overflow: hidden;
      order: 2;
      img{
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.calender_sl_main{
  padding: 1.6rem 0;
  .calender_sl{
    @extend .d_flex;
    @extend .align_items_center;
    max-width: 39.5rem;
    width: 100%;
    margin: 0 auto;

    .slick-list{
      padding: 0 0 1.2rem;
      .calender_item{
        @extend .cursor_pointer;
        h3{
          @extend .fs_16;
          line-height: 1.4;
          color: $tab-text;
          @extend .montserrat_bold;
          @extend .text_center;
        }
        &.current_month{
          h3{
            color:$primary-color;
            text-decoration: underline;
            text-underline-offset: 7px;
            text-decoration-thickness: 2px;
          }
        }
      }
    }
    .slick-arrow{
      position: static;
      transform: translate(0px, -7px);
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      // background: url(../images/left_arrow.svg) no-repeat center 100%;
      transition: all 0.2s ease-in-out;
      &:before{
        content: none;
      }
      &.slick-next{
        // background: url(../images/right_arrow.svg) no-repeat center 100%;
      }
      &.slick-disabled{
        opacity: 0;
      }
      &.arrow_disable{
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
  .calender_date_list{
    ul{
      @extend .d_flex;
      @extend .align_items_center;
      @extend .justify_content_center;
      gap: 8px;

      li{
        width: 24px;
        height: 24px;
        cursor: pointer;
        span{
          @extend .w_100;
          @extend .h_100;
          @extend .d_flex;
          @extend .align_items_center;
          @extend .justify_content_center;
          border-radius: 50%;
          @extend .fs_14;
          @extend .montserrat_medium;
        }
        &.active_date{
          span{
            background: $primary-color;
            color: $white-color;
          }
        }
      }
    }
  }
}

.cl_message_history{
  .cl_ms_inner{
    padding: 0.5rem 0;
    @extend .d_flex;
    @extend .align_items_center;
    @extend .justify_content_center;
    .cl_ms_inner_list{
      @extend .position_relative;
      ul{
        @extend .d_flex;
        @extend .align_items_center;
        @extend .justify_content_center;
        gap: 10px;
        li{
          width: 2.2rem;
          height: 2.2rem;
          background: transparent;
          border: 1px solid $dark_gray_full;
          border-radius: 4px;
          &.gray_chat_box{
            background: $dark_gray_full;
            border-color: transparent;
          }
          &.pr_border_onl_box{
            border-color: $primary-color; 
            background: transparent;
          }
          &.pr_msg_box{
            border-color: $primary-color;
            background: $pair-bg;
          }
          &.both_msg_box{
            border-color: transparent;
            background: $pair-bg;
          }
          &.pr_event_msg{
            border-color: $primary-color;
            background: $pair-bg;
            @extend .position_relative;
            &:before{
              content: "";
              @extend .position_absolute;
              width: 0.8rem;
              height: 0.8rem;
              background: $primary-color;
              bottom: 0.2rem;
              right: 0.2rem;
              border-radius: 0.2rem;
            }
          }
          &.pr_event_invitation{
            border-color: $primary-color;
            background: $pair-bg;
            @extend .position_relative;
            &:before{
              content: "";
              @extend .position_absolute;
              width: 0.8rem;
              border:1px solid $primary-color;
              height: 0.8rem;
              background: $white-color;
              bottom: 0.2rem;
              right: 0.2rem;
              border-radius: 0.2rem;
            }
          }
          &.pr_event_invitation_accept{
            // border-color: $primary-color;
            background: transparent;
            @extend .position_relative;
            &:before{
              content: "";
              @extend .position_absolute;
              width: 0.8rem;
              border:1px solid $primary-color;
              height: 0.8rem;
              background: transparent;
              bottom: 0.2rem;
              right: 0.2rem;
              border-radius: 0.2rem;
            }
          }
          &.pr_event_invitation_decline{
            border-color: $primary-color;
            background: transparent;
            @extend .position_relative;
            &:before{
              content: "";
              @extend .position_absolute;
              width: 0.8rem;
              border:1px solid $primary-color;
              height: 0.8rem;
              background: transparent;
              bottom: 0.2rem;
              right: 0.2rem;
              border-radius: 0.2rem;
            }
          }
          &.pr_event_msg_pair{
            border-color: $primary-color;
            background: transparent;
            @extend .position_relative;
            &:before{
              content: "";
              @extend .position_absolute;
              width: 0.8rem;
              height: 0.8rem;
              background: $primary-color;
              bottom: 0.2rem;
              right: 0.2rem;
              border-radius: 0.2rem;
            }
          }
          &.pr_event_msg_ind{
            background: $dark_gray_full;
            background: transparent;
            @extend .position_relative;
            &:before{
              content: "";
              @extend .position_absolute;
              width: 0.8rem;
              height: 0.8rem;
              background: $primary-color;
              bottom: 0.2rem;
              right: 0.2rem;
              border-radius: 0.2rem;
            }
          }
          &.pr_border_event_msg{
            border-color: transparent; 
            background: $pair-bg;
            @extend .position_relative;
            &:before{
              content: "";
              @extend .position_absolute;
              width: 0.8rem;
              height: 0.8rem;
              background: $primary-color;
              bottom: 0.2rem;
              right: 0.2rem;
              border-radius: 0.2rem;
            }
          }
          &.pr_border_not_event_msg{
            border-color: transparent; 
            background: $pair-bg;
            @extend .position_relative;
            &:before{
              content: "";
              @extend .position_absolute;
              width: 0.8rem;
              height: 0.8rem;
              background: white;
              bottom: 0.2rem;
              right: 0.2rem;
              border-radius: 0.2rem;
              border: 1px solid $primary-color;
            }
          }
        }
      }
      h3{
        @extend .position_absolute;
        top: 50%;
        left: calc(100% + 8px);
        top: 1px;
        @extend .fs_16;
        color: $tab-text;
        @extend .montserrat_medium;
      }
    }
  }
}
.pr_message_info{
  .pr_red_dot{
    @extend .d_flex;
    @extend .align_items_center;
    @extend .d_block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: $red-border;
    margin-right: 1rem;
    }
}