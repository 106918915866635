[type="file"]#file_upload {
  &:hover {
    + .upload_img {
      background-color: $Light-blue-clr;
      color: $white-color;
      @include trans;
      img {
        filter: brightness(21);
      }
    }
  }
}

.main_picture_upload {
  .container-editPopup {
    .reactEasyCrop_CropAreaRound {
      width: 30.5rem !important;
      height: 30.5rem !important;
    }
  }
}

.upload_photo,
.upload_img {
  @include button();
  // @include apply_btn(upload_img, (
  //   background: $success-msg-green,
  //   color: $Light-blue-clr,
  // ));
  font-size: 1.4rem;
  line-height: 140%;
  max-width: 18.7rem;
  color: $light-black;
  @extend .w_100;
  border: 0.2rem solid $Light-blue-clr;
  margin: 0;
  @include trans;
  img{
    margin: 0;
  }
  &:hover {
    background-color: $Light-blue-clr;
    color: $white-color;
    @include trans;
    img {
      filter: brightness(0) invert(1);
    }
  }
}

ul.react-tabs__tab-list {
  @extend .d_flex;
  @extend .justify_content_start;
  gap: 0.8rem;
  border: 0;
  li.react-tabs__tab {
    @extend .d_flex_center;
    @extend .fs_18;
    @extend .fw_500;
    line-height: 140%;
    border-radius: 8px 8px 2px 2px;
    color: $tab-text;
    background: transparent;
    padding: 0.8rem 1.5rem;
    min-height: 4.7rem;
    //    @extend .w_100;
    min-width: 21.2rem;
    @extend .white_space_nowrap;
    @extend .text_center;
    @extend .cursor_pointer;
    border-bottom: 0.4rem solid transparent;
    @include trans;

    &.active {
      @extend .fw_700;
      color: $dark-blue;
      background: $form-input-active-bg;
      border-bottom: 0.4rem solid $Light-blue-clr !important;
      @include trans;
    }
  }
}

.mentor_page {
  .table_wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

.switch_account_wrapper {
  &::after {
    content: "";
    @extend .position_absolute;
    background: url(../images/imentor_white_2.svg) no-repeat bottom center;
    bottom: 0;
    left: 0;
    width: 130.5rem;
    height: 37.3rem;
  }
  .login_as {
    @extend .text_right;
    padding: 6.4rem 8rem;
    @extend .position_relative;
    height: 100%;
    .switch_account_sec {
      @extend .d_flex;
      @extend .justify_content_center;
      @extend .flex_wrap;
      @extend .position_absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      .user_pic {
        width: 13rem;
        border: 0.2rem solid $white-color;
        height: 13rem;
        margin-bottom: 1.6rem;
        @extend .position_relative;
        @extend .text_center;
        img {
          max-width: 13rem;
          border-radius: 12.4rem;
        }
        span.switch {
          @extend .position_absolute;
          bottom: -0.5rem;
          right: -1.5rem;
          left: auto;
          width: auto;
          background: $white-color;
          margin: 0;
          @extend .d_flex_center;
          border-radius: 50%;
          box-shadow: 0px 0px 3rem 0px rgba(116, 116, 116, 0.15);
          width: 4.8rem;
          height: 4.8rem;
          padding: 0.5rem;
          img {
            object-fit: scale-down;
            animation: spin_witch 2s linear infinite;
            top: 0;
          }

          @keyframes spin_witch {
            0% {
              transform: rotate(-360deg);
            }

            100% {
              transform: rotate(0);
            }
          }
        }
        span.switch_profile {
          @extend .position_absolute;
          bottom: -0.5rem;
          right: -1.5rem;
          left: auto;
          width: auto;
          background: $white-color;
          margin: 0;
          @extend .d_flex_center;
          border-radius: 50%;
          box-shadow: 0px 0px 3rem 0px rgba(116, 116, 116, 0.15);
          width: 4.8rem;
          height: 4.8rem;
          padding: 0.5rem;
          img {
            object-fit: scale-down;
            animation: spin 2s linear infinite;
            top: 0;
          }

          @keyframes spin {
            0% {
              transform: rotate(360deg);
            }

            100% {
              transform: rotate(0);
            }
          }

          &.switching {
            img {
              animation: switching 2s linear infinite;
            }

            @keyframes switching {
              0% {
                transform: rotate(360deg);
              }

              100% {
                transform: rotate(0);
              }
            }
          }
        }
        .user_profile {
          @extend .d_flex_center;
          @extend .fs_36;
          line-height: 4rem;
          width: 13rem;
          border: 0.2rem solid $white-color;
          height: 13rem;
          margin-bottom: 1.6rem;
          @extend .position_relative;
          @extend .text_center;
        }
      }
      span {
        @extend .w_100;
        @extend .text_center;
        @extend .fw_400;
        color: $light-gray;
        line-height: 140%;
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
      }
      p {
        @extend .w_100;
        @extend .text_center;
        @extend .fw_600;
        font-size: 2.4rem;
        color: $dark-blue;
        line-height: 110%;
      }
    }
  }
}

// .comman_head{
//   .main-head{
//       .select_user{
//           margin-top: 2.5rem;
//       }
//   }
// }

.form_group {
  .input_remove {
    @extend .position_absolute;
    top: 4.4rem;
    right: 1.5rem;
    @extend .d_flex;
    background-color: $white-color;
    &.phone_remove {
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {
      .tooltip {
        @extend .d_block;
        top: -5.5rem;
        left: -4rem;
      }
    }
  }
  .css-b62m3t-container {
    .css-1p3m7a8-multiValue {
      border-radius: 3rem;
      background: $grey-100;
      min-height: 3.3rem;
      @extend .align_items_center;
      padding: 0 1.3rem 0 1rem;
      margin: 3px 0 0 0;
      @extend .position_relative;
      .css-wsp0cs-MultiValueGeneric {
        @extend .fw_400;
      }
      svg {
        opacity: 0;
        z-index: 999;
        @extend .cursor_pointer;
      }
      &::after {
        content: "";
        @extend .position_absolute;
        background: url(../images/Remove_Mentees.svg) no-repeat center;
        top: 50%;
        transform: translateY(-50%);
        right: 1.2rem;
        width: 1.7rem;
        height: 1.6rem;
      }
      .css-12a83d4-MultiValueRemove {
        &:hover {
          background: transparent;
        }
      }
    }
  }
  .css-1nmdiq5-menu {
    .custom_dropdown {
      @extend .d_flex_between;
      @extend .flex_direction_row_reverse;
      @extend .w_100;
      .checked {
        @extend .cursor_pointer;
      }
      .inner_custom_dropdown {
        @extend .d_flex;
        @extend .align_items_center;
        @extend .fw_500;
        font-size: 1.6rem;
        img {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1rem;
        }
        > div {
          @extend .fs_16;
          color: $light-black;
          p {
            margin-top: 0 !important;
            @extend .fs_12;
            color: $tab-text;
          }
        }
      }
      .checked {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}

.add_reference_btn {
  @extend .fw_700;
  color: $Light-blue-clr;
  font-size: 1.6rem;
  line-height: 150%;
  margin: 0 2.4rem 3.6rem auto;
  gap: 1.2rem;
}

table {
  tr {
    td {
      .matches,
      .mentees {
        border-radius: 0.8rem;
        width: 6.3rem;
        height: 4rem;
        @extend .d_flex;
        .matches_pairs,
        .mentees_pairs {
          @extend .cursor_pointer;
          @extend .d_flex_center;
          @extend .text_center;
          @extend .w_100;
          gap: 1rem;
          @extend .fw_700;
        }
      }
      .disable_matches,
      .disable_mentees {
        .matches,
        .mentees {
          background: $disabled-clr;
        }
        .active_pairs {
          @extend .d_none;
        }
        .disable_pairs {
          @extend .d_block;
        }
      }
      .active_matches,
      .active_mentees {
        .matches,
        .mentees {
          background: rgba(66, 135, 255, 0.13);
        }
        .active_pairs {
          @extend .d_block;
        }
        .disable_pairs {
          @extend .d_none;
        }
      }
    }
  }
}

.mentor_template {
  .ql-editor {
    padding: 0 !important;
    p {
      font-size: 1.6rem;
      line-height: 2.24rem;
    }
    a {
      cursor: pointer !important;
      color: rgb(19, 115, 231);
      //style="color: rgb(19, 115, 231); cursor: pointer;"
    }
  }
}

.quill.mentor_template.active_tag_color {
  .ql-editor {
    padding: 0 !important;
    p {
      font-size: 1.6rem;
      line-height: 2.24rem;
    }
    a {
      color: black !important;
      //style="color: rgb(19, 115, 231); cursor: pointer;"
    }
  }
}

/* Styles for the category container */
.category {
  background-color: $disabled-clr;
  padding: 1rem;
  margin: 1rem;
  border: 0.1rem solid #ddd;
  border-radius: 0.4rem;
}

/* Styles for the question item */
.question-item {
  background-color: $white-color;
  padding: 0.8rem;
  margin: 0.8rem 0;
  border: 0.1rem solid #ddd;
  border-radius: 0.4rem;
  cursor: grab;
  transition: background-color 0.3s ease-in-out;
}

/* Styles for the question item on hover */
.question-item:hover {
  background-color: #f7f7f7;
  cursor: grabbing;
}

/* Placeholder styles for the droppable area */
.droppable-placeholder {
  background-color: #d7d7d7;
  height: 0.8rem;
  border-radius: 0.4rem;
  margin: 0.8rem 0;
}