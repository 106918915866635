.flex {
  display: flex;
}

.grid {
  display: grid;
}

.flex-center {
  display: flex;
  align-items: center;
}
.flex-just-center {
  justify-content: center;
}

.flex-just-start {
  justify-content: start;
}

.flex-between {
  justify-content: space-between;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
  content: "";
}
.radius-50 {
  border-radius: 80px;
}
.vertical-center {
  top: 50%;
  transform: translateY(-50%);
}
.border-bottom {
  border-bottom: 1.6px solid $border-color;
}
.btn {
  @extend .fs_16;
  line-height: 2.4rem;
  @extend .fw_700;
  border-radius: .8rem;
  color: $white-color;
  padding: .8rem 2.4rem;
  background-color: $primary-color;
  @extend .d_flex_between;
  @include trans;
  @extend .position-relative;
  @extend .over_hidden;
  z-index: 0;
  &:after {
    @extend .position-absolute;
    @extend .w_100;
    height: 100%;
    background-color: $dark-blue;
    top: 0;
    right: -100%;
    opacity: 0;
    @include trans;
    z-index: -1;
  }
  &:hover {
    &:after {
      right: 0;
      opacity: 1;
    }
  }
}
.mb-80 {
  margin-bottom: 128px;
}
.my-50 {
  margin-block: 80px;
}
.py-50 {
  padding-block: 80px;
}
.pt-50 {
  padding-top: 80px;
}
.pb-50 {
  padding-bottom: 80px;
}
.custom-checkbox {
  @extend .position-relative;
  .checked {
    & + span {
      @extend .position-relative;
      @extend .cursor_pointer;
      padding: 0;
    }
    & + span::before {
      content: "";
      @extend .d_inline_block;
      vertical-align: text-top;
      width: 1.6rem;
      height: 1.6rem;
      background-color: $white-color;
      border: 1.6px solid $tab-text;
      border-radius: .3rem;
      margin-left: 0;
      margin-top: 1.6px;
      @include trans;
    }
    span::before {
      background-color: $primary-color;
      border-color: transparent;
    }
    &:checked + span:before {
      background-color: $primary-color;
      border-color: transparent;
      @include trans;
    }
    & + span::after {
      content: "";
      position: absolute;
      top: -0.1rem;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 0.4rem;
      height: 1rem;
      border: 1.6px solid $white-color;
      border-width: 0 0.2rem 0.2rem 0;
      transform: scale(0) rotate(45deg);
      @include trans;
    }
    &:checked + span::after {
      transform: scale(1) rotate(45deg);
    }
  }
}

a {
  text-decoration: none;
  color: $dark-blue;
}
ul li {
  list-style-type: none;
}
a:focus,
:focus-visible,
a:hover {
  outline: none;
}

.radio-box {
  .radio-info {
    padding-top: 0;
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: inherit;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
