.user-info {
  .user_profile {
    @extend .d_flex_center;
    padding: 0.8rem;
    border: 0.2rem solid transparent;
    @extend .cursor_pointer;
    &.active {
      border-radius: 0.4rem;
      border: 0.2rem solid $primary-color;
      background: $white-color;
      box-shadow: 0px 0px 3rem 0px rgba(116, 116, 116, 0.15);
    }
    &:hover {
      border: 0.2rem solid $primary-color;
      .login_as_popup {
        // @extend .d_block;
      }
    }
    .user_name_pic {
      width: 4rem;
      height: 4rem;
      @extend .radius_round;
      @extend .d_flex_center;
      color: $white-color;
      @extend .fs_16;
      @extend .fw_600;
      background: $primary-color;
    }
    .user_profile_pic {
      width: 4rem;
      height: 4rem;
      @extend .d_flex_center;
    }
  }
  .login_as_popup {
    @extend .position_absolute;
    top: 9.4rem;
    right: 3.2rem;
    max-width: 340px;
    @extend .w_100;
    background-color: $white-color;
    border: 0.1rem solid $form-input-border;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 3rem 0px rgba(116, 116, 116, 0.15);
    @extend .over_hidden;
    z-index: 999;
    .top_header {
      background-color: $grey-color;
      padding: 1rem 1.2rem;
      min-height: 4rem;
      .title_name {
        color: $tab-text;
        @extend .fw_700;
      }
      .add_account {
        gap: 1.2rem;
        color: $primary-color;
        @extend .fw_700;
        img {
          width: 1.6rem;
        }
      }
      .select_btn {
        @extend .d_none;
      }
      &.add_back_btn {
        @include back_btn;
        .select_btn {
          @extend .d_block;
        }
        .title_name {
          @extend .fs_14;
        }
        .back_btn_img {
          border: 0.1rem solid $primary-color !important;
          margin-right: 1.2rem !important;
        }
        .add_account {
          span {
            width: 10.5rem;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            @extend .over_hidden;
          }
        }
      }
    }
    .all_account_data {
      margin: 4px 0;
      .inner_all_user {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 389px;
        height: 100%;
      }
      .sub_account {
        .remove_user {
          opacity: 0;
          @include trans;
        }
        &:hover {
          .total_count {
            background: $primary-color;
            color: $white-color;
          }
          .remove_user {
            @extend .position_relative;
            @extend .d_flex_center;
            opacity: 1;
            margin-left: auto;
            @include trans;
            &:hover {
              @include trans;
              .tooltip {
                @extend .d_block;
                top: 3.5rem;
                left: -12rem;
                @include trans;
              }
            }
            .remove_icon {
              margin-left: auto;
              @extend .d_block;
            }
          }
        }
        .user_profile {
          padding: 0;
          border: 0;
        }
        .btn {
          padding: 0.4rem 0;
          @include apply_btn(
            "assign_btn",
            $additional_properties: (color: $Light-blue-clr)
          );
          &.assign_btn {
            &:hover {
              color: $primary-color;
              &::after {
                opacity: 0;
              }
            }
            img {
              margin-left: 1.2rem;
            }
          }
        }
        .total_count {
          border-radius: 4.5rem;
          background: rgba(66, 135, 255, 0.13);
          width: 2.4rem;
          height: 2.4rem;
          color: var(--secondary-normal, $primary-color);
          @extend .fs_12;
          @extend .fw_700;
          @extend .d_flex_center;
        }
        .remove_icon {
          @extend .d_none;
        }
        &:last-child {
          &:hover {
            .remove_user {
              @extend .position_relative;
              &:hover {
                .tooltip {
                  @extend .d_block;
                  left: -14rem;
                  top: 1rem;
                }
              }
            }
          }
        }
      }
    }
    .more_option_sec {
      .all_account_data {
        margin: 0;
        .sub_account {
          gap: 12px;
          padding: 1.6rem 1.2rem;
          margin: 0.4rem 0;
        }
      }
    }
  }
}

.sub_account {
  padding: 1.2rem;
  @extend .cursor_pointer;
  @include trans;
  &:not(:last-child) {
    border-bottom: 0.1rem solid $form-input-border;
  }
  &:hover {
    background-color: $form-input-active-bg;
    @include trans;
  }
  .user_profile {
    width: 4rem;
    height: 4rem;
    border-radius: 5rem;
    @extend .over_hidden;
    margin-right: 16px;
    img {
      @extend .img_object_center;
      @extend .w_100;
    }
  }
  .u_name {
    padding-top: 0.1rem;
    h6 {
      color: $secondary-color;
      max-width: 20rem;
      @extend .fs_16;
      @extend .fw_600;
    }
    p {
      color: $light-gray;
      font-size: 1.2rem;
      line-height: 19.6px;
      @extend .fw_500;
    }
  }
}
