@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@font-face {
  font-family: "Inter_bold";
  src: url("../fonts/inter/Inter-Bold.woff2") format("woff2"),
    url("../fonts/inter/Inter-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Extra_Inter";
  src: url("../fonts/inter/Inter-ExtraBold.woff2") format("woff2"),
    url("../fonts/inter/Inter-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter_light";
  src: url("../fonts/inter/Inter-Light.woff2") format("woff2"),
    url("../fonts/inter/Inter-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter_medium";
  src: url("../fonts/inter/Inter-Medium.woff2") format("woff2"),
    url("../fonts/inter/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter_ragular";
  src: url("../fonts/inter/Inter-Regular.woff2") format("woff2"),
    url("../fonts/inter/Inter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter_semibold";
  src: url("../fonts/inter/Inter-SemiBold.woff2") format("woff2"),
    url("../fonts/inter/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat_bold";
  src: url("../fonts/montserrat/Montserrat-Bold.eot");
  src: url("../fonts/montserrat/Montserrat-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/montserrat/Montserrat-Bold.woff2") format("woff2"),
    url("../fonts/montserrat/Montserrat-Bold.woff") format("woff"),
    url("../fonts/montserrat/Montserrat-Bold.ttf") format("truetype"),
    url("../fonts/montserrat/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  font-stretch:	normal;
}

@font-face {
  font-family: "Montserrat_extra";
  src: url("../fonts/montserrat/Montserrat-ExtraBold.eot");
  src: url("../fonts/montserrat/Montserrat-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/montserrat/Montserrat-ExtraBold.woff2") format("woff2"),
    url("../fonts/montserrat/Montserrat-ExtraBold.woff") format("woff"),
    url("../fonts/montserrat/Montserrat-ExtraBold.ttf") format("truetype"),
    url("../fonts/montserrat/Montserrat-ExtraBold.svg#Montserrat-ExtraBold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  font-stretch:	normal;
}

@font-face {
  font-family: "Montserrat_ragular";
  src: url("../fonts/montserrat/Montserrat-Regular.eot");
  src: url("../fonts/montserrat/Montserrat-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/montserrat/Montserrat-Regular.woff2") format("woff2"),
    url("../fonts/montserrat/Montserrat-Regular.woff") format("woff"),
    url("../fonts/montserrat/Montserrat-Regular.ttf") format("truetype"),
    url("../fonts/montserrat/Montserrat-Regular.svg#Montserrat-Regular")
      format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  font-stretch:	normal;
}

@font-face {
  font-family: "Montserrat_medium";
  src: url("../fonts/montserrat/Montserrat-Medium.eot");
  src: url("../fonts/montserrat/Montserrat-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/montserrat/Montserrat-Medium.woff2") format("woff2"),
    url("../fonts/montserrat/Montserrat-Medium.woff") format("woff"),
    url("../fonts/montserrat/Montserrat-Medium.ttf") format("truetype"),
    url("../fonts/montserrat/Montserrat-Medium.svg#Montserrat-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  font-stretch:	normal;
}

@font-face {
  font-family: "Montserrat_light";
  src: url("../fonts/montserrat/Montserrat-Light.eot");
  src: url("../fonts/montserrat/Montserrat-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/montserrat/Montserrat-Light.woff2") format("woff2"),
    url("../fonts/montserrat/Montserrat-Light.woff") format("woff"),
    url("../fonts/montserrat/Montserrat-Light.ttf") format("truetype"),
    url("../fonts/montserrat/Montserrat-Light.svg#Montserrat-Light")
      format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  font-stretch:	normal;
}

@font-face {
  font-family: "Montserrat_semibold";
  src: url("../fonts/montserrat/Montserrat-SemiBold.eot");
  src: url("../fonts/montserrat/Montserrat-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/montserrat/Montserrat-SemiBold.woff2") format("woff2"),
    url("../fonts/montserrat/Montserrat-SemiBold.woff") format("woff"),
    url("../fonts/montserrat/Montserrat-SemiBold.ttf") format("truetype"),
    url("../fonts/montserrat/Montserrat-SemiBold.svg#Montserrat-SemiBold")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  font-stretch:	normal;
  
}

@font-face {
  font-family: "Montserrat_thin";
  src: url("../fonts/montserrat/Montserrat-Thin.eot");
  src: url("../fonts/montserrat/Montserrat-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/montserrat/Montserrat-Thin.woff2") format("woff2"),
    url("../fonts/montserrat/Montserrat-Thin.woff") format("woff"),
    url("../fonts/montserrat/Montserrat-Thin.ttf") format("truetype"),
    url("../fonts/montserrat/Montserrat-Thin.svg#Montserrat-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  font-stretch:	normal;
}
// Start check font
// @font-face {
//   font-family: "national_regular";
//   src: url("../fonts/national_ragular/national_regular.otf");
// }

// .national_regular {
//   font-family: "national_regular" !important;
//   font-weight: 600 !important;
// }
// testing Font end

.montserrat_light {
  font-family: "Montserrat_thin";
  font-weight: bold;
}
.montserrat_regular {
  font-family: "Montserrat_ragular";
  font-weight: 400;
}
.montserrat_medium {
  font-family: "Montserrat_medium";
  font-weight: 500;
}
.montserrat_semibold {
  font-family: "Montserrat_semibold";
  font-weight: 600;
}
.montserrat_bold {
  font-family: "Montserrat_bold";
  font-weight: 700;
}
.Montserrat_extra {
  font-family: "Montserrat_extra";
  font-weight: 900;
}

.inter_ragular {
  font-family: "Inter_ragular";
}
.inter_medium {
  font-family: "Inter_medium";
}
.inter_semibold {
  font-family: "Inter_semibold";
}
.inter_bold {
  font-family: "Inter_bold";
}

.poppins_font {
  font-family: "Poppins", sans-serif;
}

@font-face {
  font-family: "fontello";
  src: url("../fonts/star/fontello_3.eot");
  src: url("../fonts/star/fontello_3.eot?#iefix") format("embedded-opentype"),
    url("../fonts/star/fontello_3.woff2") format("woff2"),
    url("../fonts/star/fontello_3.woff") format("woff"),
    url("../fonts/star/fontello_3.ttf") format("truetype"),
    url("../fonts/star/fontello_3.svg#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.fontello_star {
  font-family: fontello !important;
}
