.message_box {
  @extend .d_grid;
  grid-template-columns: 0fr 2fr;
  height: 100vh;
  //padding-top: 9.1rem;
  .comman_lay {
    padding: 2.5rem 0;
    border-right: 0.1rem solid $border-color;
    scrollbar-width: thin; /* For FireFox Only */
    &.connection_list{
      @extend .d_flex;
      @extend .flex_direction_column;
    }
    .serchbox {
      padding: 0 3.2rem;
      form {
        @extend .position_relative;
        min-width: 29.2rem;
        input {
          background-color: $search-input;
          @extend .w_100;
          height: 5.5rem;
          border-radius: 5rem;
          border: 0;
          padding-left: 5rem;
          @extend .montserrat_medium;
          font-size: 1.6rem;
          color: $tab-text;
          line-height: 2.2rem;
          padding-right: 4.5rem;
          &:active,
          &:focus {
            outline: none;
          }
        }
        span {
          border: 0;
          background-color: transparent;
          @extend .position_absolute;
          left: 1.9rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .serch_icon_remove {
        @extend .position_absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 2rem;
        left: auto;
        &:hover {
          .tooltip {
            @extend .d_block;
            top: -3.5rem;
            left: auto;
            right: 0;
          }
        }
      }
    }
    h2 {
      font-size: 2.4rem;
      @extend .montserrat_medium;
      color: $black-color;
      padding: 2.5rem 3.2rem;
      line-height: normal;
    }
    .chat_listing {
      overflow: auto;
      height: calc(100vh - 245px);
      scrollbar-width: thin; /* For FireFox Only */
      .chat_info {
        padding: 2rem 3rem;
        border-top: 0.1rem solid $border-color;
        &:hover,
        &.selected {
          background-color: rgba(214, 226, 245, 0.2);
        }
        .chat_profile {
          p {
            @extend .montserrat_semibold;
            font-size: 1.6rem;
            color: $black-color;
            max-width: 16rem;
            @extend .over_hidden;
            @extend .text_ellipsis;
            @extend .white_space_nowrap;
          }
          img {
            box-shadow: 0px 0.2rem 0.4rem 0px rgba(0, 0, 0, 0.25);
            border-radius: 5rem;
            margin-right: 1rem;
            width: 4rem;
            height: 4rem;
          }
          .user-info {
            .user_profile {
              @extend .border_0;
              &:hover {
                @extend .border_0;
              }
            }
          }
        }
        span {
          color: $light-gray;
          @extend .montserrat_medium;
          font-size: 1.4rem;
          max-width: 20rem;
          @extend .text_ellipsis;
          @extend .over_hidden;
          @extend .white_space_nowrap;
          @extend .d_block;
          &.user_profile_pic {
            &.user_pic {
              @extend .d_flex_center;
              text-transform: uppercase;
              color: $white-color;
              width: 4rem;
              height: 4rem;
              margin-right: 0;
              padding: 0;
              > img {
                width: 4rem;
                height: 4rem;
                @extend .img_object_center;
                margin-right: 0;
              }
            }
          }
        }
        .chat_status {
          span {
            @extend .d_flex;
            &.chat_time {
              font-size: 1.3rem;
            }
            &.msg_num {
              width: 1.8rem;
              height: 1.8rem;
              border-radius: 5rem;
              color: $white-color;
              font-size: 1rem;
              @extend .montserrat_bold;
              background-color: $primary-color;
              @extend .justify_content_center;
              @extend .align_items_center;
              margin-left: auto;
              margin-top: 0.1rem;
            }
          }
          img {
            margin-left: auto;
            text-align: right;
            @extend .d_block;
          }
        }
        &:last-child {
          border-bottom: 0.1rem solid $border-color;
        }
      }
    }
    .chat_listing_wrapper {
      overflow: auto;
      height: 71.1rem;
    }
    &.mobile-hide {
      padding-top: 11rem;
    }
  }
  .chat_box {
    padding-bottom: 0;
    padding-top: 9rem;
    .chat_box_info {
      padding: 2.5rem 3.2rem;
      border-bottom: 0.1rem solid $border-color;
      position: sticky;
      top: 0;
      .chat_box_profile {
        img {
          width: 5.5rem;
          min-width: 5.5rem;
          max-width: 5.5rem;
          height: 5.5rem;
          border-radius: 5rem;
          margin-right: 1.2rem;
        }
        .chat_profile_name {
          p {
            @extend .montserrat_semibold;
            color: $black-color;
            font-size: 1.6rem;
          }
        }
        .user-info {
          .user_profile {
            @extend .border_0;
            &:hover {
              @extend .border_0;
            }
          }
        }
        span {
          &.user_profile_pic {
            &.user_pic {
              @extend .d_flex_center;
              text-transform: uppercase;
              color: $white-color;
              padding: 0;
              @extend .fs_12;
            }
          }
        }
      }
      .chat_close {
        @extend .msg_border_icon;
        &:hover {
          background-color: $dark-blue;
          img {
            filter: brightness(21);
          }
        }
      }
    }
    .chatbox_area {
      overflow-y: auto;
      overflow-x: hidden;
      padding: 2.5rem 2.7rem 0;
      &::-webkit-scrollbar {
        width: 0.6rem;
      }
      &::-webkit-scrollbar-track {
        background: $border-color;
      }
      &::-webkit-scrollbar-thumb {
        @include apply_bgcolor(Light_blue_clr);
      }
      height: calc(100vh - 318px);
      @extend .position_relative;
      .suggestion_tab {
        @extend .position_absolute;
        bottom: 2.5rem;
        left: 3.4rem;
        ul {
          list-style-type: none;
          column-gap: 1.2rem;
          row-gap: 1rem;
          @extend .flex_wrap;
          li {
            @extend .montserrat_medium;
            font-size: 1.6rem;
            color: $tab-text;
            line-height: 2.2rem;
            padding: 1rem 2rem;
            background-color: $search-input;
            border-radius: 5rem;
          }
        }
      }
      .chatting {
        @extend .d_flex;
        @extend .flex_direction_column;
        .media_img_wrapper {
          .media_img {
            // width: 30rem;
            // height: 20rem;
            object-fit: cover;
            max-height: 30rem;
            max-width: 30rem;
            width: auto;
            height: auto;
            border-radius: 1rem;
          }
          &:hover {
            .media_img_icon_section {
              opacity: 1;
            }
          }
          .media_img_icon_section {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            opacity: 0;
            .media_img_icon {
              width: 36px;
              height: 36px;
              background: rgba(30, 37, 56, 0.5);
              border-radius: 3px;
              display: flex;
              margin: 0 5px;
              cursor: pointer;
              svg {
                margin: auto;
              }
            }
          }
        }

        .message {
          //@extend .d_flex;
          @extend .flex_direction_column;
          margin-bottom: 2.5rem;
          @extend .justify_content_end;
          @extend .align_items_end;
          display: inline-flex;
          @extend .position_relative;
          
          &:not(.other_message){
            float: right;
          }
          p {
            padding: 1.4rem 2.2rem;
            @extend .montserrat_regular;
            font-size: 1.6rem;
            line-height: 2.2rem;
            border-radius: 0.8rem;
            border: 0.1rem solid $border-color;
            background-color: $white-color;
            color: $tab-text;
            word-break: break-word;
            @extend .d_inline_block;
            .link_btn {
              color: $white-color;
              // @extend .fw_700;
              text-decoration: underline;
              margin-left: 0.5rem;
            }
          }
          span {
            font-size: 1.2rem;
            @extend .montserrat_regular;
            line-height: 2.2rem;
            letter-spacing: 0.06px;
            color: $msg-date;
            margin-top: 0.5rem;
            @extend .d_flex;
            @extend .align_items_center;
            @extend .justify_content_start;
            > img {
              margin-left: 0.5rem;
              @extend .position_relative;
              top: 0.2rem;
              &.mesg_tick {
                width: auto;
                max-width: 1.6rem;
              }
            }
          }
          &.my_message {
            p {
              background-color: $dark-blue;
              color: $white-color;
              border-bottom-right-radius: 0;
              @extend .d_inline_block;
              a:not(.link_btn) {
                color: currentColor;
                text-decoration: underline;
              }
              span{
                color: $white-color;
                @extend .fs_16;
              }
            }
            .react_emoji {
              bottom: -0.8rem;
              left: -0.8rem;
            }
            &.content_message {
              .react_emoji {
                bottom: 2rem;
                left: -1rem;
              }
            }
          }
          &.other_message {
            border-top-left-radius: 0;
            @extend .justify_content_start;
            @extend .align_items_start;
            border-top-left-radius: 0;
            p {
              .link_btn {
                color: $primary-color;
                @extend .fw_700;
                text-decoration: underline;
              }
              a:not(.link_btn) {
                color: $primary-color;
                text-decoration: underline;
              }
              span{
                @extend .fs_16;
              }
            }
            &.other_file {
              .react_emoji {
                right: -0.8rem;
                left: auto;
                bottom: 2rem;
                z-index: 10;
              }
              > div {
                > div {
                  > img {
                    object-fit: cover;
                    max-height: 30rem;
                    max-width: 30rem;
                    width: auto;
                    height: auto;
                    border-radius: 1rem;
                  }
                }
                .file_class {
                  border: 1px solid #cccccc8b;
                  border-radius: 1rem;
                  padding: 1rem;
                }
              }
              p {
                border: 0;
                background: 0;
                @extend .d_flex;
                @extend .align_items_center;
                gap: 1rem;
                padding: 0;
                img {
                  width: 3.2rem;
                }
              }
            }
            > div.position_relative {
              div.text_right {
                text-align: left;
              }
            }
            &.my_message {
              .react_emoji {
                bottom: 2rem;
                right: -0.5rem;
                left: auto;
              }
            }
          }
          &.my_file {
            p {
              border: 0;
              color: $black-color;
              @extend .d_flex_align;
              padding: 0;
              img {
                margin-right: 1rem;
                width: 3.2rem;
                height: 3.2rem;
                vertical-align: middle;
              }
            }
            img {
              width: 25rem;
            }
            > div {
              > div {
                > img {
                  object-fit: cover;
                  max-height: 30rem;
                  max-width: 30rem;
                  width: auto;
                  height: auto;
                  border-radius: 1rem;
                }
              }
            }
            &.other_message {
              > div {
                > div {
                  > img {
                    object-fit: cover;
                    max-height: 30rem;
                    max-width: 30rem;
                    width: auto;
                    height: auto;
                    border-radius: 1rem;
                  }
                }
              }
            }
            .react_emoji {
              left: -1.8rem;
              bottom: 1.5rem;
            }
            .file_class {
              border: 1px solid #cccccc8b;
              border-radius: 1rem;
              padding: 1rem;
            }
          }
          &.my_message_left {
            @extend .justify_content_start;
            @extend .align_items_start;
            max-width: 50%;
          }
          .voice_msg {
            background-color: #e8f1fd;
            border-radius: 2.4rem;
            padding: 0.8rem 1.2rem;
            max-width: 100%;
            margin-bottom: 0.5rem;
            .volume_box {
              @extend .d_flex;
              @extend .align_items_center;
              gap: 1.2rem;
              p {
                border: 0;
                padding: 0;
                @extend .montserrat_regular;
                @extend .fs_14;
                color: $black-color;
                opacity: 0.6;
                background: transparent;
                white-space: nowrap;
                img {
                  width: 4rem;
                  height: 4rem;
                  @extend .img_object_center;
                }
              }
              .wave {
                max-width: 17rem;
              }
              > img {
                width: auto;
              }
            }
          }
          &.voice_msg {
            .volume_box {
              p {
                background-color: transparent;
                min-width: 5rem;
              }
            }
          }
          p {
            img {
              width: 2rem;
              max-width: 100%;
              @extend .img_object_center;
            }
          }
          .react_emoji {
            border: 0.1rem solid $primary-color;
            @extend .radius_round;
            width: 3rem;
            height: 3rem;
            @extend .d_flex_center;
            @extend .position_absolute;
            bottom: -0.6rem;
            right: -1rem;
            z-index: 1;
            background: $white-color;
            span {
              &.default_emoji {
                margin-top: 0;
                @extend .fs_16;
              }
            }
          }
        }
        .user_left {
          @extend .d_flex;
          .user_left_wrapper {
            border-radius: 4rem;
            border: 0.1rem solid $border-color;
            background: $white-color;
            box-shadow: 0px 0.4rem 4rem 0px rgba(36, 73, 137, 0.08);
            @extend .text_center;
            display: inline-flex;
            margin: 0 auto;
            padding: 1.6rem 2rem;
            max-width: 56rem;

            p {
              font-size: 1.6rem;
              @extend .fw_600;
              color: $msg-date;
            }
          }
        }
        .content_wrapper_video {
          box-shadow: 0px 0.4rem 4rem 0px rgba(36, 73, 137, 0.08);
          border: 0.1rem solid $border-color;
          border-radius: 0.8rem;
          .content_video_inner {
            > span {
              img {
                @extend .w_100;
                @extend .m_0;
              }
              &.msg_content_pic {
                img {
                  @extend .w_100;
                  max-width: 35rem;
                  @extend .img_object_center;
                }
              }
            }
            .content_video {
              .content_dec {
                padding: 2rem;
                > h4 {
                  max-width: 18rem;
                  @extend .text_ellipsis;
                  @extend .white_space_nowrap;
                  @extend .over_hidden;
                }
                > div {
                  .content_text {
                    color: $primary-color;
                    @extend .fs_12;
                    @extend .fw_700;
                  }
                  .vid_icon {
                    @extend .fs_14;
                    color: $form-input-color;
                    padding-right: 6rem;
                  }
                }
              }
              p {
                padding: 2rem;
                border-top: 0.1rem solid $border-color;
                @extend .fs_16;
                @extend .fw_500;
                color: $white-color;
                margin: 0;
                @extend .w_100;
                max-width: 35rem;
              }
            }
          }
        }
        .react_emoji {
          border: 0.1rem solid $primary-color;
          @extend .radius_round;
          width: 3rem;
          height: 3rem;
          @extend .d_flex_center;
          @extend .position_absolute;
          bottom: 0.6rem;
          right: -1.5rem;
          z-index: 0;
          background: $white-color;
          span {
            &.default_emoji {
              margin-top: 0;
              @extend .fs_16;
            }
          }
        }
      }
      .emoji-picker-wrapper {
        border: 0.1rem solid $border-color;
        box-shadow: 0.1rem 0.1rem 0.1rem #d3d3d3;
        background-color: $white-color;
        min-width: 18.5rem;
        padding: 1.2rem;
        z-index: 1;
        @extend .position_absolute;
        left: 5.5rem;
        bottom: -1rem;
        @extend .radius_8;
        .emoji-picker {
          .icon {
            line-height: 2.6rem;
            @include trans;
            &:hover {
              @extend .fs_28;
              @include trans;
            }
          }
        }
      }
    }
    .chat_footer {
      // padding: 2.5rem 0;
      background-color: $white-color;
      position: sticky;
      bottom: 0;
      z-index: 111;
      .chat_footer_box {
        column-gap: 1.2rem;
        border-top: 0.1rem solid $border-color;
        // padding-top: 2rem;
        padding: 2.5rem;
        .circle_box {
          @extend .msg_border_icon;
          @include trans;
          &:hover {
            background-color: $dark-blue;
            @include trans;
            img {
              filter: brightness(21);
              @include trans;
            }
          }
        }
        .chat_input {
          .message_textarea {
            background-color: $search-input;
            padding: 1.6rem;
            @extend .d_flex_center;
            .message_textarea_field {
              font-size: 1.6rem;
              @extend .montserrat_regular;
              line-height: 2.2rem;
              color: $tab-text;
              padding: 0 0.5rem;
              background-color: transparent;
              min-width: 32.2rem;
              border: none;
              &:active,
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
      &.disable {
        opacity: 0.2;
        z-index: -1;
      }
      .voice_msg_box {
        padding: 3.2rem;
        background-color: $search-input;
        position: inherit;
        @extend .w_100;
        .voice_text {
          margin-bottom: 2.5rem;
          p {
            @extend .montserrat_semibold;
            @extend .fs_16;
            line-height: 1.9rem;
            color: $black-color;
          }
          span {
            @extend .msg_border_icon;
            background-color: $white-color;
            img {
              width: 2.4rem;
              height: 2.4rem;
            }
          }
        }
        .sound_icon {
          @extend .text_center;
          span {
            @extend .position_relative;
            @extend .d_flex_center;

            z-index: 0;
            &::after {
              content: "";
              @extend .position_absolute;
              width: 8.9rem;
              height: 8.9rem;
              -webkit-animation-delay: 0s;
              animation-delay: 0s;
              -webkit-animation: pulsate1 4s;
              animation: pulsate1 4s;
              -webkit-animation-direction: forwards;
              animation-direction: forwards;
              -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
              border-radius: 50%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              @include apply_bgcolor(Light_blue_clr);
              opacity: 0.3;
              z-index: -1;
            }
            @-webkit-keyframes pulsate1 {
              0% {
                -webkit-transform: translate(-50%, -50%) scale(0.6);
                transform: translate(-50%, -50%) scale(0.6);
                opacity: 1;
                opacity: 0.3;
              }
              50% {
                -webkit-transform: translate(-50%, -50%) scale(1);
                transform: translate(-50%, -50%) scale(1);
                opacity: 0.3;
              }
              80% {
                -webkit-transform: translate(-50%, -50%) scale(1.1);
                transform: translate(-50%, -50%) scale(1.1);
                opacity: 0.4;
              }
              100% {
                -webkit-transform: translate(-50%, -50%) scale(1.2);
                transform: translate(-50%, -50%) scale(1.2);
                opacity: 0.2;
              }
            }

            @keyframes pulsate1 {
              0% {
                -webkit-transform: translate(-50%, -50%) scale(0.6);
                transform: translate(-50%, -50%) scale(0.6);
                opacity: 0.3;
              }
              50% {
                -webkit-transform: translate(-50%, -50%) scale(1);
                transform: translate(-50%, -50%) scale(1);
                opacity: 0.3;
              }
              80% {
                -webkit-transform: translate(-50%, -50%) scale(1.1);
                transform: translate(-50%, -50%) scale(1.1);
                opacity: 0.4;
              }
              100% {
                -webkit-transform: translate(-50%, -50%) scale(1.2);
                transform: translate(-50%, -50%) scale(1.2);
                opacity: 0.2;
              }
            }
          }
        }
      }
      &.chat_footer_opacity {
        pointer-events: none;
        border: 0;
        .chat_box {
          padding: 0;
          .chatbox_area {
            padding: 0;
          }
        }
        .chat_footer_box {
          opacity: 0.2;
        }
      }
    }
  }
  &.expand_box {
    grid-template-columns: 1fr;
    .comman_lay {
      border-right: 0;
      padding-top: 12rem;
      overflow: auto;
    }
    .chat_box,
    .content_share {
      @extend .d_none;
    }
    .comman_lay {
      .chat_listing {
        .chat_info {
          .chat_profile {
            p {
              @extend .w_100;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  .content_share {
    padding: 0;
    @extend .d_grid;
    grid-template-rows: repeat(3, 1fr);
    .content_box {
      padding: 0 2.5rem;
      border-bottom: 0.1rem solid $border-color;
      .empty_box {
        @extend .d_flex_align;
        height: 100%;
        @extend .justify_content_center;
        p {
          @extend .text_center;
          color: $primary-color;
          font-size: 1.6rem;
          @extend .montserrat_semibold;
        }
      }
      h2 {
        padding-inline: 0;
      }
      .list_box {
        margin-bottom: 1.5rem;
        span {
          @extend .msg_border_icon;
          img {
            width: 2.4rem;
            height: 2.4rem;
          }
        }
        .text_info {
          margin-left: 1rem;
          p {
            color: $black-color;
            @extend .fs_16;
            @extend .montserrat_medium;
            @extend .text_capitalize;
          }
          a {
            color: $primary-color;
            line-height: normal;
            border-bottom: 0.1rem solid;
            font-size: 1.4rem;
            @extend .montserrat_regular;
          }
        }
        &.list_box_project {
          span {
            background-color: $project_bg;
            img {
              width: auto;
              height: auto;
            }
          }
        }
      }
    }
  }
  &.chat_wrapper_area {
    @extend .d_block;
    padding-top: 9rem;
    .chat_listing_inner {
      height: 71.3rem;
      overflow: auto;
    }
  }
}
.image_full_view {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1001;
  .image_full_view_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    .image_full_view_header {
      display: flex;
      align-items: center;
      padding: 15px 20px;
      position: sticky;
      top: 0;
      .image_full_title {
        width: calc(100% - 30px);
        text-align: center;
        font: normal 500 2.2rem/2.4rem "Mulish", sans-serif;
        color: #fff;
      }
      .image_full_action {
        display: flex;
        align-items: center;
        .u_plain-icon {
          cursor: pointer;
          line-height: 0;
          display: block;
          color: #4e525f;
          position: relative;
        }

        .u_mr-10 {
          margin-right: 10px;
        }
        .close-icon {
          border: 2px solid #fff;
          width: 30px;
          height: 30px;
          display: flex !important;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          cursor: pointer;
        }
        .u_cp {
          cursor: pointer;
        }
        .u_ml-10,
        .u_mx-10 {
          margin-left: 10px;
        }
      }
    }
    .image_full_view-body {
      margin: auto;
      width: calc(100% - 112px);
      height: 100%;
      min-height: 1px;
      text-align: center;
      .image_full_popup {
        text-align: center;
        margin: auto;
        display: inline-flex;
        height: 100%;
        align-items: center;
        .u_image-zoom {
          display: inline-flex;
          overflow: hidden;
          width: 100%;
          height: 100%;
          margin: auto;
          img {
            margin: auto;
            height: auto;
            max-height: 100%;
          }
        }
      }
    }
    .u_thumbnails-file {
      height: 100px;
    }
  }
}
.voice_msg {
  background-color: #e8f1fd;
  border-radius: 2.4rem;
  padding: 0.8rem 1.2rem;
  max-width: 32.2rem;
  .volume_box {
    @extend .d_flex;
    @extend .align_items_center;
    gap: 1.2rem;
    p {
      border: 0;
      padding: 0;
      @extend .montserrat_regular;
      @extend .fs_14;
      color: $black-color;
      opacity: 0.6;
      background: transparent;
      height: 4rem;
      width: 4rem;
      @extend .d_flex;
      @extend .align_items_center;
      img {
        width: 4rem;
        height: 4rem;
        @extend .img_object_center;
        background: $white-color;
        border-radius: 10rem;
      }
    }
    .pauseIcon {
      width: 4rem;
      height: 4rem;
      path {
        width: 4rem;
        height: 4rem;
      }
    }
    .wave {
      max-width: 17rem;
    }
    .duration {
      max-width: 5rem;
    }
  }
}
.shared_msg_box {
  padding: 3.2rem;
  background-color: $search-input;
  @extend .position_absolute;
  @extend .w_100;
  bottom: 0;
  left: 0;
  right: 0;

  .close_btn {
    @extend .position_absolute;
    right: 3.2rem;
    span {
      @extend .msg_border_icon;
      background-color: $white-color;
      img {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
  p {
    margin: 1rem 0;
    @extend .montserrat_semibold;
    @extend .fs_16;
    color: $black-color;
  }
  span {
    @extend .montserrat_regular;
    color: $light-gray;
    @extend .fs_16;
  }
  .message,
  .youtube_info {
    @extend .d_none;
  }
  &.potrait_view {
    max-width: 26.1rem;
    box-sizing: border-box;
    padding: 0;
    position: unset;
    background-color: $white-color;
    .close_btn {
      @extend .d_none;
    }
    .landscape_view {
      background-color: $white-color;
      border: 0.1rem solid $border-color;
      @extend .over_hidden;
      min-height: 30.7rem;
      border-radius: 0.8rem;
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .big_img {
        @extend .w_100;
      }
      p {
        @extend .d_none;
      }
      span {
        @extend .montserrat_semibold;
        font-size: 1.8rem;
        color: $black-color;
        @extend .d_block;
        padding: 2.3rem 1.8rem;
      }
      .youtube_info {
        padding-inline: 1.8rem;
        margin-bottom: 2.5rem;
        @extend .d_flex;
        a {
          @extend .montserrat_bold;
          color: $primary-color;
          @extend .fs_14;
        }
        span {
          margin-left: 1.6rem;
          padding: 0;
          img {
            margin-right: 0.7rem;
            @extend .d_inline_block;
            vertical-align: middle;
            width: 2.2rem;
          }
          @extend .montserrat_regular;
          color: $black-color;
          @extend .fs_14;
        }
      }
    }
    .message {
      min-width: 26.1rem;
      @extend .d_block;
      p {
        @extend .d_block;
        margin: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.8rem;
      }
    }
  }
}
