//popup design
.i_popup {
  @extend .i_popup;
  
  &.i_RecommendPopup_events{
    .i_popup_content {
      min-height: auto;
      @include trans;
      &.i_popup_content_height{
        min-height: 52.6rem;
        @include trans;
        .i_popup_footer{
          @extend .position_fixed;
          bottom: 0;
          right: 0;
          @extend .w_100;
          @include trans;
        }
      }
      .status_tab{
        .annaunce_mentees{
          .pairs_img_view{
            .common_match{
              border-color: $white-color;
              .generate_pic{
                min-width: 1.6rem;

              }
            }
          }
        }
      }
    }
    .i_popup_footer{
      button.btn{
        &:not(.blue_btn){
          pointer-events: none;
        }
      }
    }
  }
 
  &.i_session_expired {
    .i_popup_content {
      max-width: 54.8rem;
      .all_inner_content {
        p {
          line-height: 2.6rem;
        }
      }
      .i_popup_footer {
        padding-top: 0;
        button {
          max-width: 12.8rem;
        }
      }
    }
  }
  &.i_add_note_popup {
    + .delete_note_details {
      background: 0;
      + .delete_note_details {
        background: 0;
        .i_popup_footer {
          .primary_btn {
            color: $primary-color;
            &:hover {
              color: $white-color;
            }
          }
        }
      }
    }
  }
  .i_popup_content {
    background-color: $white-color;
    padding: 3.2rem;
    margin: auto;
    border-radius: 2rem;
    box-shadow: 0rem 0rem 3rem 0rem rgba(116, 116, 116, 0.15);
    @extend .position_fixed;
    @extend .w_100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 70rem;
    overflow-y: auto;
    overflow-x: hidden;
    @include trans;

    .i_popup_header {
      padding-bottom: 3.5rem;

      .i_main_inquiry {
        border-radius: 0.8rem;
        background: $table-hover;
        padding: 1rem 2.2rem;
        max-width: 17.9rem;
        @extend .w_100;

        .i_inquiry_tilte {
          @extend .fs_14;
          line-height: 140%;
        }

        .i_inquiry_date {
          @extend .fs_16;
          @extend .fw_700;
          margin-top: 1rem;
          line-height: 150%;
        }
      }

      .i_popup_title {
        @extend .fw_700;
        @extend .fs_20;
        line-height: 2.4rem;
      }

      .i_close {
        width: 4rem;
        height: 4rem;
      }

      .i_back_btn {
        @include back_btn;
      }

      &.popup_center_title {
        .i_close {
          margin: unset;
        }

        .i_popup_title {
          margin: auto;
          max-width: 29.3rem;
          @extend .w_100;
          @extend .text_center;
          @extend .fs_18;

          &.announce_title {
            @extend .text_left;
            margin-left: 1.6rem;
          }
        }
      }
    }

    .i_personal_info,
    .i_contact_info {
      padding: 3.2rem 0;

      .preferred_header {
        @extend .fs_16;
        @extend .montserrat_semibold;
        font-weight: 600;
        line-height: 2.24rem;
        color: $tab-text;
      }

      .preferred_data {
        @extend .fs_18;
        @extend .fw_700;
        line-height: 140%;
        padding-top: 1.65rem;
        max-width: 40rem;
      }
    }

    .i_contact_info {
      .i_contact_info_data {
        table {
          @extend .w_100;

          tr {
            td {
              @extend .w_50;
              word-wrap: break-word;
            }
          }

          &.i_email {
            margin-bottom: 3.2rem;
          }
        }
      }
    }

    .i_sub_title {
      @extend .fw_700;
      @extend .fs_20;
      line-height: 140%;
      padding-bottom: 3.2rem;
    }

    .imentor_info {
      p {
        @extend .fs_16;
        @extend .text_center;
        line-height: 140%;
      }

      .imentor_about {
        padding-bottom: 2.2rem;
      }
    }

    .send_main_form {
      .form-group {
        padding: 1.6rem 3.2rem;

        label {
          @extend .fs_16;
          @extend .fw_700;
          line-height: 150%;
          max-width: 7rem;
          @extend .w_100;
        }

        input,
        textarea {
          border: none;
          @extend .fs_16;
          line-height: 140%;
          color: $form-input-color;
          margin: 0 1rem;
          @extend .w_100;
          background: transparent;

          &::placeholder {
            @extend .fs_16;
            line-height: 140%;
            color: $form-input-color;
            @extend .montserrat_regular;
          }
        }

        &.mail_msg {
          padding: 3.2rem 3.2rem;
          @extend .position_relative;

          .mentor_template {
            padding-bottom: 2rem;
          }

          textarea {
            @extend .fw_400;
            height: 10rem;
            @extend .w_100;
            resize: none;
            min-height: auto;
          }

          button {
            &.fill_template {
              @extend .position_absolute;
              right: 3.2rem;
              top: 2.3rem;
              background: 0;
              color: $primary-color;
              @extend .fs_14;
              @extend .fw_700;

              &:hover {
                background: 0;

                &::after {
                  background: 0;
                  opacity: 0;
                }
              }
            }
          }

          .suggetion_word {
            @extend .w_100;
            @extend .position_absolute;
            bottom: 0;
            left: 0;

            .inner_suggetion {
              padding: 1.2rem 3.2rem;

              .left_title {
                h4 {
                  @extend .fw_700;
                  color: $light-gray;
                  @extend .fs_14;
                }
              }

              .right_suggetion {
                ul {
                  gap: 1rem;

                  li {
                    border-radius: 3rem;
                    border: 0.1rem solid $primary-color;
                    padding: 1rem 1.2rem;
                    @extend .cursor_pointer;

                    span {
                      @extend .fw_400;
                      @extend .fs_12;
                      color: $black-color;
                    }

                    &.active {
                      border: 0.2rem solid $primary-color;
                      background: $table-hover;
                    }

                    &.suggetion_close {
                      border: 0;
                      padding: 0;
                    }
                  }
                }
              }
            }
          }
          .ql-container {
            height: 38rem;
            overflow: auto;
          }
        }
      }

      .multi_user {
        gap: 2rem;

        li {
          @extend .position_relative;

          &::after {
            content: "";
            background-color: $border-color;
            @extend .position_absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -1.1rem;
            width: 0.1rem;
            height: 2rem;
          }

          &:last-child {
            &::after {
              @extend .d_none;
            }
          }
          .user_pic{
            margin-right: 0;
          }
        }
      }
    }

    .error_restored {
      border-radius: 0.4rem;
      background: rgba(214, 68, 68, 0.1);
      padding: 1rem;

      h6 {
        @extend .montserrat_semibold;
        @extend .fs_14;
        margin-left: 1rem;
        line-height: 2.7rem;
      }

      p {
        margin-top: 1.2rem;
      }

      &.pairs_error_restored {
        p {
          @extend .fs_14;
        }
      }
    }

    .i_popup_footer {
      padding: 3.2rem 0 0;

      button {
        @include button;
        min-height: 4.8rem;
        padding: 0.9rem 2.4rem;
        line-height: 2.1rem;
        max-width: 22.8rem;
        gap: 1.2rem;
        @extend .white_space_nowrap;

        &.discard_save_btn {
          border: 0.1rem solid $primary-color;
          max-width: 18.5rem;
          color: $form-lable-color;

          &:hover {
            background-color: $primary-color;
            color: $white-color;
          }
        }
        &.assign_btn{
          // color: $white-color;
          &:hover {
            color: $white-color;
          }
          &.save_btn{
            color: $white-color;
          }
        }
      }

      .btn_reject {
        color: $white-color;
        background: $red-required;
        margin-left: 0.8rem;

        &.btn_cancel {
          max-width: 21.1rem;

          &.events_yes_btn {
            max-width: 13.9rem;
          }
        }
      }

      .btn_request_info {
        color: $form-lable-color;
        border: 0.2rem solid $primary-color;

        &:hover {
          color: $white-color;
          background-color: $primary-color;

          img {
            filter: brightness(21);
          }
        }
      }

      .btn_approve {
        background: $primary-color;
        max-width: 17.7rem;
      }

      .btn_Delete {
        background: transparent;
        color: $primary-color;
        margin: 0.8rem auto 0;
        // &:hover{
        //     border: 0.2rem solid  $primary-color;
        // }
      }

      .i_footer_title {
        @extend .fs_16;
        @extend .fw_700;
        line-height: 150%;
        margin: 2.4rem 0;
        @extend .text_center;
      }

      &.pairs_sendmessage_footer {
        background: $white-color;
        .left_side {
          max-width: 15rem;
          column-gap: 1.8rem;
        }
      }
    }

    .i_easther_howard {
      .imentor_logo {
        @extend .position_relative;
        @extend .d_inline_block;

        &::after {
          content: "";
          @extend .position_absolute;
          top: 0;
          right: -5.4rem;
          background-image: url(../images/red_close.svg);
          background-repeat: no-repeat;
          @extend .w_100;
          height: 100%;
        }
      }

      .i_easther_title {
        @extend .fs_16;
        @extend .fw_500;
        line-height: 140%;
        margin-left: 3rem;
      }
    }

    &.successfully_disabled {
      min-width: 45.5rem;
      max-width: 45.5rem;

      &::after {
        content: "";
        width: 2.8rem;
        height: 4.6rem;
        background: url(../images/Polygon.svg) no-repeat center;
        @extend .position_absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -2.7rem;
      }

      h4 {
        @extend .fw_700;
        @extend .fs_18;
      }

      .mentees_success_disable {
        @extend .fw_400;
        color: $black-color;
        margin-bottom: 1.6rem;
      }

      .i_popup_header {
        padding: 0;
      }

      .toster_waper {
        position: inherit;

        .msgbox {
          &.toster {
            margin-bottom: 0;
            padding: 1.8rem 2.4rem;
            min-height: auto;
          }
        }
      }
    }

    &.discard_changes {
      .after_deletion {
        @extend .fw_400;
        margin-bottom: 2.4rem;
        padding-bottom: 2.4rem;
      }

      .error_restored {
        background: $blue-bg;
        margin-bottom: 3.6rem;
      }

      .i_close {
        @extend .position_absolute;
        top: 2rem;
        right: 2rem;
        width: 3rem;
        height: 3rem;

        img {
          width: 3rem;
          height: 3rem;
        }
      }

      .alert_icon {
        + div {
          > p {
            @extend .fw_400;
            @extend .fs_14;
            color: $black-color;
            max-width: 36rem;
          }
        }
      }
    }

    &.i_disable_mentees {
      .all_inner_content {
        .error_restored {
          background: 0;
          padding: 2.4rem;

          .mentees_remain_disable {
            margin-bottom: 2.4rem;
            margin-top: 0;
          }

          .i_footer_title {
            @extend .fs_16;
          }
        }
      }
    }

    &.edit_profile_popup_inner {
      .i_close {
        @extend .position_absolute;
        right: 3.2rem;
        top: 2.5rem;
      }
    }

    &.announve_modal {
      max-width: 71.2rem;
    }
  }

  &.edit_profile_popup {
    .container {
      max-width: 70rem;
      margin: 0 auto;
    }

    .edit_profile_popup_inner {
      .i_popup_header {
        border-bottom: 0;
        padding-bottom: 3.4rem;

        .i_popup_title {
          @extend .w_100;
          margin-left: 0;
        }
      }

      .main_picture_upload {
        .profile_img {
          @extend .w_100;
          max-width: 34.1rem;
          margin: 0 auto;
          border-radius: 1.6rem;
          @extend .over_hidden;
          @extend .position_relative;

          img {
            @extend .w_100;
            @extend .img_object_center;
            height: 100%;
          }

          .img_overly {
            @extend .d_block;
            background: #2d425299;
            border-radius: 1.6rem;
            @extend .w_100;
            height: 99%;
            @extend .position_absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            .overly {
              @extend .d_block;
              @extend .d_flex_center;
              width: 50%;
              height: 50%;
              margin: auto;
              background: #d9d9d982;
              @extend .position_absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              @extend .radius_round;
            }
          }
        }

        ul {
          gap: 1.5rem;
          li{
            .upload_img{
              color: $white-color;
            }
          }
        }

        .upload_profile_main {
          border-radius: 1.6rem;
          background: rgba(231, 241, 253, 0.5);
          max-width: 34rem;
          margin: 0 auto 3.8rem;
          padding: 2.4rem;
          @extend .position_relative;

          .upload_profile_img {
            max-width: 8.8rem;
            margin: auto;
            border-radius: 6.7rem;
            border: 0.2rem solid var(--secondary-light-active, #b6d4f8);
            background: #dceafb;
            height: 8.8rem;
            @extend .d_flex;
            @extend .over_hidden;

            .edit_profile_view {
              width: 8.8rem;
              height: 8.8rem;
              @extend .radius_round;
              @extend .img_object_center;
            }
          }

          img {
            width: auto;
            @extend .img_object_center;
          }

          .dragBox {
            margin: 2rem auto;
            @extend .text_center;
          }

          .upload_img {
            margin: auto;
            background-color: $white-color;
            @extend .justify_content_center;
          }

          .custom_upload_btn {
            @extend .position_relative;

            input[type="file"] {
              @extend .position_absolute;
              left: 0;
              right: 0;
              @extend .text_center;
              opacity: 0;
              height: 100%;
              @extend .cursor_pointer;
              width: 20rem;
              margin: 0 auto;
              background-color: $white-color;
              + .upload_pic {
                &:hover {
                  background-color: $primary-color;
                  color: $white-color;
                }
              }
            }
          }
        }

        .blue_text.text_center {
          margin-left: 0;
          margin: auto;
        }

        .show_error_msg {
          margin-bottom: 1.5rem;
          padding-left: 0;
          @extend .d_block;
        }
      }
    }

    .upload_img {
      margin: 0 0 0 auto;
      min-width: 20rem;
      @extend .justify_content_center;

      img {
        // @extend .d_none;
      }
      .loader {
        img {
          @extend .d_block;
        }
      }
    }
  }

  &.i_InquiryDetailsPopup {
    .i_popup_content {
      max-width: 87rem;
      @extend .w_100;
      max-height: 84rem;
      .i_popup_header {
        .i_popup_title {
          margin-right: 7.5rem;
        }
      }
    }

    .i_popup_footer {
      button {
        max-height: 4.8rem;

        img {
          margin: 0;
        }

        &.btn_reject {
          max-width: 24.9rem;
        }

        &.btn_request_info {
          max-width: 24.9rem;
        }

        &.btn_approve {
          max-width: 24.9rem;
        }
        &.disable_btn{
          background: $grey-text;
          border-color: transparent;
          color: $white-color;
          pointer-events: none;
          img{
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }

  &.i_RejectMentor_popup {
    z-index: 999999;

    .i_popup_content {
      max-width: 70rem;
      @extend .w_100;

      .form_group {
        margin-bottom: 0;

        label {
          @extend .fs_14;
          @extend .fw_700;
          line-height: 140%;
          color: $form-input-color;
        }

        .inquiry_textbox {
          @extend .w_100;
          padding: 1.6rem;
          border-radius: 0.4rem;
          border: 0.1rem solid #bdbdbd;
          background: $white-color;
          margin: 0.8rem 0 0;
          max-height: 14.4rem;
          height: 14.4rem;
          @extend .fs_16;
          @extend .fw_400;
          line-height: 140%;
          color: $form-input-color;
          resize: none;
        }

        .rejection_email {
          @extend .fs_14;
          color: $form-input-color;
        }
      }

      .i_popup_footer {
        padding: 0;

        .btn_Cancel {
          max-width: 20rem;
          margin: 0.8rem 0 0.8rem 0;
        }

        .btn_reject {
          max-width: 22.8rem;
        }
      }
    }

    .form_group {
      &.error_msg {
        + .show_error_msg {
          margin-top: 0;
          margin-bottom: 1rem;
        }
      }
    }
  }

  &.i_approveMentor_popup {
    z-index: 99999;

    .i_popup_content {
      max-width: 70rem;
      @extend .w_100;

      .i_popup_header {
        .i_close {
          margin: unset;
          @extend .position_absolute;
          top: 2.6rem;
          right: 3.2rem;
        }

        .i_popup_title {
          margin: auto;
        }
      }

      .i_easther_howard {
        .imentor_logo {
          &::after {
            background-image: url(../images/Success_Checkmark.svg);
          }
        }
      }

      .i_popup_footer {
        padding: 0;

        button {
          max-width: 20rem;
        }
      }
    }
  }

  &.i_deleteInquiry_popup {
    z-index: 999999;

    .i_popup_content {
      .i_easther_howard {
        .imentor_logo {
          &::after {
            background-image: url(../images/trash_round.png);
          }
        }
      }

      .i_popup_footer {
        padding: 0;

        .btn_Cancel {
          max-width: 20rem;
        }

        .btn_reject {
          max-width: 22.8rem;
          margin-left: 0;
        }
      }
    }

    &.unassign_modal {
      .i_popup_header {
        padding-bottom: 2.4rem;
      }

      .i_popup_content {
        @extend .w_100;
        max-width: 47.5rem;
        min-width: 47.5rem;

        h2 {
          color: $black-color;
          @extend .text_center;
          @extend .fs_20;
          @extend .fw_700;
          line-height: 140%;
          margin-bottom: 2.2rem;
        }

        .un_shool_name {
          max-width: 31.2rem;
          margin: 0 auto;
          @extend .radius_8;
          background: $form-input-active-bg;
          padding: 1.2rem 2.4rem;
          margin-bottom: 2.4rem;
          @extend .position_relative;

          .unassign_close_icon {
            @extend .d_block;
            width: 2.4rem;
            height: 2.4rem;
            @extend .position_absolute;
            top: -0.8rem;
            left: -0.8rem;
            @extend .cursor_pointer;
          }

          h3 {
            color: $secondary-color;
            @extend .fs_16;
            @extend .fw_700;
            @extend .text_center;
            line-height: 150%;
          }
        }

        .unasign_content {
          color: $form-lable-color;
          @extend .text_center;
          @extend .fs_14;
          @extend .fw_400;
          line-height: 140%;
          padding-bottom: 2.4rem;
          border-bottom: 0.05rem solid $separtor-color;
        }

        .unassign_btn {
          gap: 3.2rem;
          margin-top: 2.4rem;

          .assign_btn {
            min-width: 18.9rem;
            min-height: 4.8rem;

            &.save_btn {
              background: $primary-color;
              text-transform: inherit;
            }
          }
        }
      }
    }
  }

  &.i_requestInfo_popup {
    .i_popup_content {
      max-width: 70rem;
      @extend .w_100;
      padding: 0;

      &.group_mesg_pop {
        height: 71rem;
        overflow-y: hidden;
        .i_new_msg_mentor {
          height: auto;
        }
      }

      .i_popup_header {
        padding: 3.2rem;
        padding-bottom: 3.3rem;

        .i_popup_title {
          @extend .w_100;
          margin-left: auto;
          line-height: 2.5rem;
        }
      }

      .i_popup_footer {
        padding: 2.2rem;

        .btn_select {
          max-width: 22.8rem;

          img {
            margin-right: 0;
            margin-left: 0;
          }
        }

        .resend_btn {
          max-width: 22.8rem;

          img {
            margin-right: 0;
          }

          &.resend_disable {
            background-color: $grey-text;
          }
        }

        .btn_Cancel {
          max-width: 20rem;
        }
        &.send_mesg_footer {
          @extend .position_fixed;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $white-color;
        }
      }
      .show_error_msg {
        padding: 0 3.2rem;
      }
    }

    &.i_media_files_pop {
      .i_popup_header {
        padding: 2.85rem;
        padding-bottom: 3.3rem;

        .i_popup_title {
          @extend .w_100;
          @extend .text_center;
        }
      }

      .i_popup_content {
        max-width: 56.4rem;
        @extend .over_hidden;

        .active_tabs {
          gap: 2rem;

          li {
            min-width: 15rem;
            max-width: 15rem;

            &.active {
              border-radius: 0.8rem 0.8rem 0.2rem 0.2rem;
              border-bottom: 0.4rem solid $primary-color;
              background: $form-input-active-bg;
            }

            span {
              @extend .fw_500;
              @extend .fs_18;
              color: $tab-text;
            }
          }
        }
      }

      .inner_content {
        .pairs_activities {
          margin: 0;
          border: 0;
          padding: 0;

          .pair_tabs {
            margin-bottom: 0;

            .active_tabing {
              padding-bottom: 0;
            }
          }

          &::after {
            @extend .d_none;
          }
        }

        .photos_gallery {
          height: 54rem;
          overflow: auto;
          box-shadow: 0 0 3rem 0 rgba(116, 116, 116, 0.15);
          padding-top: 2rem;
          ul {
            padding: 0 0.9rem 1.6rem 1.6rem;
            @extend .d_flex;
            @extend .flex_wrap;
            overflow: auto;
            gap: 1.4rem;
            li {
              width: calc(33.33% - 0.95rem);
              @extend .position_relative;
              padding-bottom: 31.5%;
              > img {
                @extend .w_100;
                @extend .h_100;
                @extend .img_object_center;
                top: 0;
                left: 0;
                @extend .position_absolute;
              }
            }
          }
        }

        .files_inner {
          box-shadow: 0 0 3rem 0 rgba(116, 116, 116, 0.15);

          ul {
            overflow: auto;
            height: 54rem;

            li {
              border-bottom: 0.1rem solid $border-color;
              @include trans;

              &.group_add_li {
                padding-right: 2.2rem;
              }

              .files_wrapper {
                gap: 1.6rem;
                padding: 1.6rem 2.4rem;

                .pdf {
                  width: 4rem;
                  height: 4rem;
                  background-color: $form-input-active-bg;
                  border-radius: 10rem;

                  &.member_pic {
                    width: 3.2rem;
                    height: 3.2rem;
                    @extend .over_hidden;

                    img {
                      width: 3.2rem;
                      height: 3.2rem;
                      @extend .img_object_center;
                    }
                  }
                }

                .pdf_name {
                  @extend .fw_600;
                  color: $secondary-color;
                  @extend .fs_16;

                  span {
                    @extend .fs_12;
                    color: $grey-text;
                    &.pair_title_name {
                      color: $secondary-color;
                    }
                  }

                  &.group_m_names {
                    @extend .fw_500;
                    color: $tab-text;
                    @extend .fs_16;
                    line-height: 2.4rem;
                  }
                }
              }

              .download_file {
                opacity: 0;
                @extend .d_flex;
                @include trans;
                margin-right: 2rem;
              }

              .group_names {
                @extend .fw_700;
                color: $secondary-color;
                @extend .fs_12;
                cursor: default;
              }

              &:hover {
                background-color: $form-input-active-bg;
                @include trans;

                .download_file {
                  opacity: 1;
                  @include trans;
                  @extend .d_flex;
                }
              }
            }
          }
          .no_data_found_text {
            @extend .position_absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @extend .fs_16;
            @extend .fw_600;
          }
        }
      }
    }
  }

  &.i_delete_invited_popup {
    .i_popup_content {
      max-width: 62.4rem;
      @extend .w_100;

      &.discard_changes {
        max-width: 58.2rem;
      }

      .i_popup_header {
        padding-bottom: 2.5rem;
      }

      .all_inner_content {
        .partner_region {
          .sub_data {
            max-height: 11rem;
            overflow: auto;
          }
        }
        .mentor_details {
          padding: 2.4rem 0;
          column-gap: 3rem;
          .sub_data {
            &:first-child {
              width: 60%;
            }
            &:last-child {
              width: 40%;
            }

            .data_value {
              @extend .fs_16;
              @extend .fw_700;
              line-height: 150%;
              margin-top: 0.8rem;
              word-break: keep-all;
              .invited {
                @extend .fs_14;
                @extend .fw_400;
                color: $tab-head;
                border-radius: 0.4rem;
              }
            }
            .sub_data_list{
              &:not(:last-child){
                margin-bottom: 1.5rem;
              }
            }
          }
        }

        .partner_region {
          .data_title {
            margin-bottom: 0.8rem;
          }

          .institution_icon {
            background-color: $form-input-active-bg;
            border-radius: 5rem;
            width: 4rem;
            height: 4rem;
            @extend .d_flex;
            @extend .justify_content_center;
            margin-right: 1.6rem;

            img {
              @extend .w_100;
              padding: 0.9rem;
              min-height: auto;
            }
          }

          .data_value {
            color: $secondary-color;
            @extend .fw_600;
            @extend .fs_16;
            @extend .montserrat_semibold;
            line-height: normal;
          }
          .title_text_wraper {
            .mentor_delete_partner_text {
              @extend .fs_16;
              @extend .fw_600;
              color: $secondary-color;
            }
            .popup_partner_region_icon {
              border-radius: 10rem;
              width: 4rem;
              height: 4rem;
              background: $form-input-active-bg;
              > img {
                width: 1.6rem;
                height: 1.6rem;
              }
            }
          }
        }

        .i_mentees {
          padding: 2.4rem 0;

          .User_doesnot_have {
            color: $secondary-color;
            margin-top: 1.4rem;
          }

          .all_mentor_data {
            max-height: 12rem;
            height: 100%;
            overflow: auto;

            .inner_data {
              margin-top: 1.6rem;
              .user_pic{
                margin: 0;
              }

              h6 {
                color: $secondary-color;
                @extend .montserrat_semibold;
                margin-left: 1.6rem;
                @extend .fs_14;
              }
            }

            .mentor_profile {
              @include profile_small;
              @extend .over_hidden;

              img {
                @extend .img_object_center;
                @extend .w_100;
                @extend .h_100;
              }
            }
          }
        }
      }

      .i_popup_footer {
        padding: 0;

        button {
          max-width: 17.5rem;
          padding: 1.2rem 2.3rem;

          &.save_exit_btn {
            margin: 0;
            border: 0.2rem solid $primary-color;
            padding: 0;
            min-height: 4.6rem;
            color: $form-lable-color;
            max-width: 21.1rem;

            &:hover {
              color: $white-color;

              img {
                filter: brightness(0) invert(1);
              }
            }

            &.events_no_btn {
              border: 0;
              color: $primary-color;
              max-width: 13.9rem;
              min-width: 13.9rem;

              &:hover {
                color: $white-color;
              }
            }
          }
        }

        .btn_Delete {
          margin: 0.8rem 0 0;

          &.back_form {
            margin: 2rem auto 0;
          }
        }

        .disable_mentees {
          padding-top: 0.6rem;

          .btn_reject {
            &.btn {
              background-color: $primary-color;

              &.great_btn {
                background-color: $primary-color;
              }
            }
          }
        }
      }
    }

    &.i_delete_partner_popup {
      .i_popup_header {
        .i_close {
          @extend .position_absolute;
          top: 1.7rem;
          right: 2.2rem;
        }
      }

      .i_popup_footer {
        .btn_Delete {
          margin: 0.8rem 0 0.8rem;

          &:hover {
            color: $white-color;
          }
        }
      }

      .mentor_details {
        padding: 2.4rem 0 2.4rem 2.2rem !important;

        .sub_data {
          width: 100% !important;

          &:not(:first-child) {
            .data_title {
              padding-top: 1.6rem;
            }
          }

          .status_tab {
            @extend .fs_14;
            @extend .fw_500;
            color: $tab-head;
            padding: 0.9rem 1.2rem;
            min-height: 3rem;
            line-height: 140%;
            @extend .radius_30;
            @extend .d_inline_block;
            background: $grey-color;
            color: $black-color;
            margin-bottom: 0.5rem;
          }

          .plus_more {
            @include plus_more;
          }

          .inner_data {
            padding: 1.2rem 0;

            .Partner_profile {
              width: 4rem;
              height: 4rem;
              border-radius: 5rem;
              @extend .over_hidden;
              margin-right: 1.6rem;

              img {
                @extend .img_object_center;
                @extend .w_100;
              }
            }

            .p_name {
              h6 {
                color: $secondary-color;
                @extend .fs_16;
                @extend .fw_600;
              }

              p {
                color: $light-gray;
                @extend .fs_12;
                line-height: 1.96rem;
                @extend .fw_500;
              }
            }
          }
        }
      }
    }

    &.i_partners_regions_popup {
      .i_popup_content {
        max-width: 74.3rem;
        @extend .w_100;
        padding: 0;

        .i_popup_header {
          padding: 3.2rem 3.2rem 2.4rem 3.2rem;

          .i_popup_title {
            max-width: 40rem;
          }

          .i_close {
            @extend .position_absolute;
            top: 1.7rem;
            right: 2.2rem;
          }
        }

        .all_inner_content {
          .sub_info_data {
            padding: 0 3.2rem;
            margin: 2.4rem 0;
            overflow: auto;
            max-height: 28.8rem;
            height: 100%;
          }

          .all_info_partners {
            tr {
              padding: 3.2rem 1.6rem;

              &:not(:last-child) {
                @extend .border_bottom_1;
              }

              display: table;
              @extend .w_100;
              table-layout: fixed;

              td {
                &:first-child {
                  width: 38%;
                }
              }
            }

            .title {
              @extend .fs_14;
              @extend .fw_400;
              line-height: 140%;
              padding-bottom: 0.8rem;
            }

            .value {
              color: $form-input-color;
              @extend .fs_16;
              @extend .fw_700;
              line-height: 150%;
              @extend .position_relative;

              &:hover {
                .tooltip {
                  @extend .d_block;
                  top: -4.5rem;
                  left: 2rem;
                }
              }

              .Partner_profile {
                @include profile_small;
                margin-left: -0.3rem;

                img {
                  @extend .w_100;
                  @extend .img_object_center;
                }
              }

              .total_num {
                margin-left: 0.8rem;
              }
            }

            .plus_more {
              @include plus_more;
              @extend .fs_16;
            }
          }

          .error_restored {
            margin: 0 3.2rem;
          }
        }

        .i_popup_footer {
          padding: 0 3.2rem 3.2rem;

          .btn_reject {
            max-width: unset;
            width: auto;
          }

          .btn_Delete {
            margin: 0.8rem 0 0.8rem;

            &:hover {
              color: $white-color;
            }
          }
        }
      }
    }
  }

  &.i_resend_invite_popup {
    .i_popup_content {
      max-width: 47.5rem;
      @extend .w_100;

      .i_popup_header {
        padding-bottom: 2.5rem;

        .i_close {
          @extend .position_absolute;
          top: 1rem;
          right: 2rem;
        }
      }

      .all_inner_content {
        h6 {
          @extend .montserrat_bold;
          @extend .fs_14;
          margin-top: 0.8rem;
        }

        .email_address {
          padding: 2.4rem 0;

          h6 {
            margin-top: 0;
          }
        }

        .user_pic {
          img {
            width: 6.4rem;
            height: 6.4rem;
            border-radius: 10rem;
            @extend .img_object_center;
          }
        }

        .user_info {
          .user_pic {
            width: 6.4rem;
            height: 6.4rem;
            margin: auto;
            @extend .d_flex_center;
            @extend .radius_round;
            color: $white-color;
            @extend .fs_18;
            letter-spacing: 0.2rem;
            padding: 0;
          }

          img {
            width: 6.4rem;
            height: 6.4rem;
            border-radius: 10rem;
          }
        }

        + .i_popup_footer {
          .btn_approve {
            max-width: 21rem;

            img {
              margin: 0;
            }
          }
        }
      }

      .i_popup_footer {
        padding: 0;

        .last_resent {
          padding: 2.4rem 0;

          span {
            @extend .montserrat_bold;
            @extend .fs_14;
          }
        }

        button {
          img {
            margin-right: 0;
            margin-left: 1.2rem;
          }
        }
      }
    }

    &.i_resend_userlists_popup {
      .all_inner_content {
        // padding: 24px;
        .date_wise_data {
          &:not(:first-child) {
            border-top: 0.1rem solid $grey-color;
            padding-top: 1.6rem;
          }
          &:last-child {
            border-bottom: 0.1rem solid $grey-color;
          }
        }

        .all_sub_data {
          max-width: 27rem;
          @extend .w_100;
          margin: auto;
          padding-bottom: 1.6rem;
        }

        .user_info {
          @extend .gap_8;

          img {
            width: 3.5rem;
            height: 3.5rem;
          }

          h6 {
            margin-top: 0;
            margin-bottom: 0.3rem;
          }

          .user_pic {
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 10rem;
            @extend .d_flex_center;
            color: $white-color;
            margin: 0;
            letter-spacing: 0;
            flex-shrink: 0;
            @extend .fs_14;
          }
        }

        .last_resent {
          margin-top: 1.6rem;

          p {
            color: $light-gray !important;

            span {
              @extend .montserrat_semibold;
            }
          }
        }
      }
      .all_inner_content{
        max-height: 55rem;
        overflow: auto;
      }

      .i_popup_footer {
        padding-top: 3.2rem;

        .btn_approve {
          gap: 0.5rem;
          max-width: 22rem;

          img {
            margin-left: 0;
          }
        }
      }
    }
  }

  &.i_new_msg_popup {
    .i_popup_content{
      max-height: 82rem;
      height: 90%;
      @extend .d_flex;
      @extend .flex_direction_column;
    }
    .send_main_form {
      .mentor_profile {
        @include profile_small;
        @extend .radius_round;
        @extend .over_hidden;
        margin-right: 1rem;
        &:empty{
          display: none;
        }

        img {
          @extend .img_object_center;
          width: 2.4rem;
          height: 2.4rem;
        }

        &.groups_mesg_profile {
          span {
            width: 2.4rem;
            height: 2.4rem;
            border-radius: 3.4rem;
            background: #eff6fe;
          }

          + .groups_tab_mesg_title {
            @extend .position_relative;
            @include trans;

            + .tooltip {
              opacity: 0;
              @extend .d_flex;
              @include trans;
              right: 2rem;
              left: auto;
            }

            &:hover {
              color: $primary-color;
              @include trans;

              &::after {
                content: "";
                @extend .position_absolute;
                bottom: 0.3rem;
                left: 0;
                right: 0;
                height: 0.1rem;
                @extend .w_100;
                background-color: $primary-color;
                @include trans;
              }

              + .tooltip {
                opacity: 1;
                right: 2rem;
                left: auto;
                @include trans;
              }
            }
          }
        }
      }

      h6 {
        @extend .fs_16;
        @extend .fw_700;
        line-height: 2.1rem;
        color: $form-input-color;
        @extend .d_flex_align;
        @extend .cursor_pointer;
        @extend .position_relative;
        &:hover {
          color: $primary-color;
          .tooltip {
            @extend .position_fixed;
            @extend .d_block;
            top: 7rem;
            left: 7rem;
          }
        }
      }

      .more_pairs {
        span {
          @extend .fw_700;
          @extend .fs_14;
          gap: 1.6rem;
        }
      }
      .send_mentors_mesg {
        overflow: auto;
        height: 46rem;
      }
    }

    .i_popup_footer {
      padding-top: 0 !important;

      .left_side {
        max-width: 10.8rem;
        @extend .position_relative;
        @extend .w_100;
        @extend .d_flex;

        img {
          &:not(:last-child) {
            margin-right: 1.6rem;
            object-fit: unset;
          }
        }

        &.mentees_message_left {
          gap: 1.6rem;
        }
        .mentor_newMessage {
          max-width: 3.4rem;
          @extend .position_relative;
          @extend .w_100;

          input[type="file"] {
            opacity: 0;
            @extend .position_absolute;
            width: 2rem;
            top: 0;
            right: 0;
            left: 0.1rem;

            &:last-child {
              right: 0;
              left: 4rem;
            }
          }
        }

        input[type="file"] {
          opacity: 0;
          @extend .position_absolute;
          width: 2rem;
          top: 0.2rem;
          right: 0;
          left: 0.1rem;

          &:last-child {
            right: 0;
            left: 4rem;
          }
        }
      }

      .right_side_btn {
        @extend .w_100;
        @extend .justify_content_end;
      }
    }

    .mesg_image_upld_wrapper {
      padding: 0 3.2rem 5rem;
      overflow: auto;
      ul {
        @extend .d_flex;
        @extend .align_items_center;
        padding-bottom: 3rem;
        max-width: 60rem;
        overflow-x: auto;
        overflow-y: hidden;
        margin-bottom: 3rem;
        &::-webkit-scrollbar {
          width: 0.6rem;
          height: 1.8rem;
        }
        &::-webkit-scrollbar-thumb {
          background: #0e56ad;
          border-radius: 10rem;
          border: 0.6rem solid $white-color;
        }
        &::-webkit-scrollbar-track {
          background: $white-color;
          border-radius: 10rem;
        }

        li {
          .mesg_image_upld {
            width: 12rem;
            height: 12rem;
            img {
              width: 12rem;
              height: auto;
              @extend .img_object_center;
              border-radius: 0.8rem;
            }

            .remove_btn {
              background-color: $white-color;
              border: 10rem;
              border-radius: 10rem;
              @extend .position_absolute;
              top: -0.6rem;
              right: -0.4rem;
              padding: 0.5rem;

              img {
                width: 1.6rem;
                height: 1.6rem;
                padding: auto;
              }
            }
          }
        }
      }

      .profile_img {
        gap: 2.4rem;

        .pdf_wrapper {
          background: #dceafb;
          border: 0.2rem solid #b6d4f8;
          border-radius: 10rem;
          max-width: 8.8rem;
          width: 100%;
          height: 8.8rem;
          .loader_bg {
            @extend .over_hidden;
            border-radius: 10rem;
            background: transparent;
            border: 0.1rem solid $border-color;
            .loader {
              img {
                max-width: 2.8rem;
              }
            }
          }
        }

        .pdf_name {
          @extend .fs_14;
          @extend .fw_700;
          color: $form-input-color;
          max-width: 40rem;
          @extend .text_ellipsis;
          @extend .over_hidden;
          @extend .white_space_nowrap;
        }

        .remove_btn {
          border: 0.1rem solid $primary-color;
          border-radius: 10rem;
          padding: 0.6rem;
          @extend .d_flex_center;
          flex-shrink: 0;

          img {
            width: 1.9rem;
            height: 1.9rem;
          }
        }
      }
    }
  }

  &.i_matches_popup {
    .i_popup_content {
      max-width: 50.8rem;
      @extend .w_100;
      padding: 0;
      @extend .over_hidden;
      min-height: 41.8rem;

      .i_popup_header {
        padding: 1.6rem 3.2rem 1.7rem;

        .i_close {
          margin: unset;
        }

        .i_popup_title {
          margin: auto;
          @extend .fs_16;
          line-height: 4rem;
          span {
            @extend .fw_400;
            display: contents;
          }

          img {
            margin-right: 0.8rem;
          }
        }
      }

      .inner_contect {
        &::after {
          content: "";
          @extend .position_absolute;
          bottom: -2.2rem;
          background: linear-gradient(
            360deg,
            $white-color 0%,
            rgba(255, 255, 255, 0) 75.42%
          );
          @extend .w_100;
          height: 11.9rem;
          pointer-events: none;
        }

        table {
          @extend .w_100;
          border-collapse: collapse;

          thead,
          tbody tr {
            display: table;
            @extend .w_100;
            table-layout: fixed;
          }
          
          thead{
            tr{
              td{
                  p.thead{
                    @extend .d_flex;
                    @extend .align_items_center;
                  }
              }
            }
          }

          td {
            padding: 1.6rem 2.2rem 1.6rem 0;
            

            &:first-child {
              width: 50%;
              padding-left: 3.8rem;
            }

            &:last-child {
              @extend .text_center;
              padding-left: 2.2rem;
              .thead{
                @extend .justify_content_center;
              }
            }
          }

          thead {
            // box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
            tr {
              @extend .border-bottom;
              padding-left: 2.2rem;

              td {
                .thead {
                  @extend .fw_700;
                  @extend .fs_16;
                }
              }
            }
            .thead {
              @extend .d_block;
              .down_arrow {
                @extend .d_inline_block;
                > img {
                  vertical-align: middle;
                  margin-top: 0;
                }
              }
            }
          }

          tbody {
            overflow: auto;
            max-height: 48.5rem;
            height: 100%;
            @extend .d_block;
            transition: all 0.6s ease-in-out;
            tr {
              &:not(:last-child) {
                @extend .border-bottom;
              }

              &:hover {
                background-color: $table-hover;
              }

              td {
                padding: 1.2rem 2.2rem 1.2rem 0;
                height: 7.2rem;

                &:last-child {
                  @extend .d_flex_center;
                }

                .mentor_profile {
                  @include profile_small;
                  @extend .img_object_center;
                  img {
                    width: 2.4rem;
                    height: 2.4rem;
                    @extend .radius_round;
                  }
                }

                p {
                  @extend .fw_500;
                  margin-left: 1rem;
                  color: $tab-text;
                  align-self: center;
                }

                .i_per {
                  border-radius: 0.6rem;
                  max-width: 7.7rem;
                  @extend .w_100;
                  padding: 0.4rem 1.2rem;
                  height: 4.8rem;
                  @extend .fw_700;
                  color: $tab-text;
                  @extend .fs_16;
                }
              }
            }
          }
        }
        .table_timezone{
          @extend .d_flex;
          @extend .align_items_center;
            .name_toolkit {
              @extend .position_relative;
              .time_zone_label{
                line-height: normal;
                background: $gray-100;
                margin-left: 8px;
              }
              .full_username{
                color: $white-color;
                @extend .fs_14;
                @extend .fw_400;
                line-height: 140%;
                padding: 0.8rem 1rem;
                @extend .radius_4;
                background: $dark-bg;
                @extend .d_flex_align;
                gap: 1rem;
                @extend .position_absolute;
                top: -3.7rem;
                display: none;
                @extend .white_space_nowrap;
                left: unset ;
                right: 50% !important;
                transform: translate(50%, 0px);
              }
              &:hover {
                .full_username {
                  @extend .d_flex;
                }
              }
            }
        }
      }
      table {
        tbody {
          tr {
            td {
              .user_pic {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
  &.i_choose_image_popup{
    .i_popup_content {
      min-height: unset;
      max-height: 38rem;
      min-height: 38rem;
      max-width: 56.4rem;
      padding: 0 0 2rem;
      @extend .d_flex;
      @extend .flex_direction_column;
      .inner_contect::after{
        content: none;
      }
      .inner_contect{
        flex: 1;
        overflow: auto;
        @extend .position_relative;
        .library_img_list{
          padding: 3.2rem 3.3rem 0rem;
          ul{
            @extend .d_flex;
            @extend .flex_wrap;
            li{
              padding: 1rem 1.6rem;
              width: 25%;
              .lib_img_main{
                width: 100%;
                border-radius: 0.6rem;
                padding-bottom: 100%;
                @extend .position_relative;
                @extend .over_hidden;
                @extend .cursor_pointer;
                img{
                  @extend .w_100;
                  @extend .h_100;
                  @extend .img_object_center;
                  @extend .position_absolute;
                  left: 0;
                  top: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  &.add_another_account_popup {
    .i_popup_content {
      max-width: 45rem;
      @extend .w_100;
      padding: 0;

      .i_popup_header {
        border-bottom: 0.1rem solid $form-input-border;
        padding: 3.2rem 3.2rem 1.7rem 3.2rem;

        h2 {
          margin-bottom: 0.4rem;
        }

        p {
          @extend .fs_16;
        }

        .i_close {
          @extend .position_absolute;
          top: 1.7rem;
          right: 2.2rem;
          width: unset;
          height: unset;
        }
      }

      .all_inner_content {
        padding-bottom: 1.6rem;

        .all_user_data {
          overflow: auto;
          max-height: 40rem;
          height: 100%;

          .sub_account {
            padding: 2rem 1.2rem;
          }
        }
      }
    }
  }

  &.i_pair_archived {
    .i_popup_content {
      max-width: 45.5rem;
    }
  }

  &.i_requestInfo_popup {
    &.i_media_files_pop {
      &.i_add_group_member {
        .i_popup_content {
          max-width: 71.8rem;

          .i_popup_header {
            .i_popup_title {
              margin-left: 0;
            }
          }

          .inner_content {
            .active_tabing {
              padding-bottom: 2.5rem;

              .active_tabs {
                padding: 0 2.2rem;

                li {
                  max-width: 100%;
                  min-width: auto;
                  @extend .w_100;
                  @extend .text_center;
                  @extend .fw_500;
                  @extend .fs_18;
                  color: $tab-text;
                  min-height: 4.7rem;

                  &.active {
                    @extend .fw_700;
                    @extend .fs_18;
                    color: $secondary-color;
                  }
                }
              }
            }

            .group_member_main {
              @extend .d_block;
              margin: 0;
              padding-bottom: 1.5rem;

              .form_group {
                max-width: 100%;

                .form_control {
                  border-radius: 10rem;
                  min-height: 4rem;
                }

                ul {
                  padding: 0 3.2rem;
                  max-height: 45.8rem;
                  min-height: 45.8rem;
                  overflow: auto;

                  &.sub_group_members {
                    padding-top: 0;
                    @extend .w_100;
                    min-height: auto;

                    .group_members_li {
                      padding-right: 2.2rem;
                    }
                  }

                  li {
                    min-height: 7.2rem;
                    padding-right: 0;
                    border-bottom: 0.1rem solid $border-color;

                    &:last-child {
                      border-bottom: 0;
                    }

                    .group_selection {
                      gap: 2.4rem;
                      padding-right: 2.2rem;
                      min-height: 7.2rem;
                      @extend .w_100;

                      .group_selection_wrapper {
                        gap: 2.7rem;
                      }

                      .pair_btn {
                        button {
                          @extend .fw_700;
                          color: $secondary-color;
                          @extend .fs_12;
                        }
                      }
                    }

                    .custom-checkbox {
                      margin-bottom: 0;
                      .checked {
                        position: initial;
                      }
                      .checked + span {
                        &::before {
                          width: 2.4rem;
                          height: 2.4rem;
                        }

                        &::after {
                          width: 0.8rem;
                          height: 1.7rem;
                          top: 0.3rem;
                        }
                      }
                    }

                    span {
                      @extend .fw_500;
                      color: $form-lable-color;
                      @extend .fs_16;
                      &.selected_mentees {
                        color: $tab-head;
                        @extend .fs_12;
                        @extend .fw_400;
                        line-height: 140%;
                        padding: 0.4rem 0.8rem;
                        border-radius: 4px;
                        background: $grey-color;
                        @extend .position_relative;
                        &::after {
                          content: "";
                          @extend .d_block;
                          @extend .position_absolute;
                          height: 100%;
                          width: 0.1rem;
                          background: $form-input-border;
                          top: 0;
                          right: -2rem;
                        }
                      }
                    }

                    .pairs_match {
                      .user_pic {
                        padding: 0;
                        width: 3.2rem;
                        height: 3.2rem;
                        .common_match {
                          border: 0.3rem solid #e3e9f1;
                          border-radius: 10rem;
                          @extend .d_block;
                          max-width: 3rem;
                          @extend .w_100;
                          height: 3rem;
                          @extend .position_relative;

                          img {
                            width: 2.5rem;
                            height: 2.5rem;
                            border-radius: 10rem;
                            border: 0.3rem solid $white-color;

                            &.top_pic {
                              @extend .position_absolute;
                              top: -0.8rem;
                              left: -1.2rem;
                            }

                            &.bottom_pic {
                              @extend .position_absolute;
                              bottom: -0.8rem;
                              right: -1.1rem;
                            }
                          }

                          &.sub_common {
                            @extend .border_0;

                            img {
                              @extend .border_0;
                              width: 3rem;
                              height: 3rem;
                            }
                          }
                          .generate_name_of_mentees {
                            width: 2rem;
                            height: 2rem;
                            border-radius: 10rem;
                            color: $white-color;
                            font-size: 0.6rem;
                            border: 0.1rem solid $white-color;
                            @extend .d_flex_center;
                            &.top_pic {
                              @extend .position_absolute;
                              top: -0.7rem;
                              left: -1rem;
                            }

                            &.bottom_pic {
                              @extend .position_absolute;
                              bottom: -0.7rem;
                              right: -1rem;
                            }
                          }
                        }
                        &.mentess_genrat_name {
                          width: 3rem;
                          height: 3rem;
                          color: $white-color;
                          @extend .fs_12;
                        }
                      }
                    }

                    .mentees_mentors_name {
                      color: $tab-text;

                      p {
                        @extend .fw_500;
                        @extend .d_inline_block;
                        color: $tab-text;
                        @extend .fs_16;
                        margin-bottom: 0.2rem;

                        + span {
                          margin: 0 0.8rem;
                        }
                      }

                      .group_tag {
                        max-width: 20.4rem;

                        span {
                          @extend .fw_700;
                          color: $secondary-color;
                          @extend .fs_10;
                        }
                      }
                    }

                    .drop_down_arrow {
                      img {
                        transform: rotate(180deg);
                        @include trans;
                      }
                    }

                    &.sub_menu_active {
                      .drop_down_arrow {
                        img {
                          transform: rotate(360deg);
                          @include trans;
                          bottom: -0.8rem;
                          right: -1.4rem;
                        }
                      }
                    }
                  }
                  &.mentees_mentors {
                    li {
                      .group_selection {
                        .custom-checkbox {
                          .checked {
                            @extend .position_absolute;
                          }
                        }
                      }
                    }
                  }
                }

                &.group_pop_search {
                  padding: 0 1.6rem;
                  margin-bottom: 1.5rem;

                  .serch_icon {
                    left: 2.7rem;
                    @extend .position_absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    @extend .d_flex;
                  }

                  .form_control {
                    padding-left: 3.8rem;
                  }

                  .serch_icon_remove {
                    @extend .position_absolute;
                    right: 3rem;
                    top: 50%;
                    transform: translateY(-50%);
                    @extend .d_flex;
                  }
                }
              }
            }
          }
        }

        .i_popup_footer {
          border-radius: 2rem;
          background: $white-color;
          box-shadow: 0 0 3rem 0 rgba(116, 116, 116, 0.15);
          padding: 0 0 3.2rem;

          &.i_add_group_member {
            .btn_select {
              max-width: 24.9rem;
            }
            .btn-wrapper {
              .blue_btn {
                min-width: max-content;
              }
            }
          }

          .clear_btn {
            @extend .fw_700;
            color: $grey-text;
            padding: 0 0 0 2.4rem;
            margin: 0;
            width: auto;

            &.clear_blue_btn {
              color: $primary-color;
            }
          }
        }
      }

      &.i_group_memebrs_list {
        .i_popup_content {
          max-width: 71.8rem;

          .active_tabs {
            @extend .justify_content_start;
            padding: 0 2.2rem;

            li {
              max-width: min-content;
              min-width: 12.1rem;
              padding: 0 3rem;
            }
          }
        }
        &.i_group_memebrs_list {
          .form_group {
            margin-bottom: 0;
            .custom-checkbox {
              .checkmark {
                &::before {
                  width: 2.4rem;
                  height: 2.4rem;
                }
                &::after {
                  width: 1.2rem;
                  height: 2rem;
                }
              }
            }
          }
        }
      }
    }

    .i_close {
      @extend .position_absolute;
      right: 3.2rem;
    }
  }

  &.i_archive_content {
    .i_popup_header {
      padding-bottom: 3.4rem;
      .i_popup_title {
        max-width: unset !important;
      }
    }

    &.remove_que {
      .i_popup_content {
        max-width: 67.1rem;

        .i_popup_header {
          padding-bottom: 4.4rem;

          .i_close {
            @extend .position_absolute;
            right: 3.2rem;
          }
        }
      }
    }

    .i_inner_content {
      @extend .over_hidden;
      border-radius: 0;
      margin: 0 -3.2rem;

      &::before {
        bottom: -10rem !important;
      }
    }

    .all_inner_content {
      .red_msg {
        width: 2.4rem;
      }
    }

    .i_popup_footer {
      padding-top: 0;
      button {
        margin-top: 0;
      }
      .danger_btn {
        max-width: unset;
        width: auto;

        img {
          margin-right: 0;
        }
      }
    }
  }

  &.i_RecommendPopup {
    .send_main_form {
      .form-group {
        textarea {
          min-height: 17.7rem !important;
        }
      }

      .i_inner_content {
        padding: 1.7rem 0;
        .archive_data {
          margin-right: 1rem;
        }
        .inner_wrap_data {
          img {
            width: 1.6rem;
          }
        }
      }

      .user_info {
        background-color: rgba(231, 241, 253, 0.67);
        border-radius: 0.4rem;
        margin: 0 3.2rem;
      }
      .inner_wrap {
        .all_details {
          .user_que {
            height: 8rem;
          }
        }
      }
    }
    .i_popup_content {
      .inner_content {
        .form-wrapper {
          .form_group {
            .dropdown_with_btn {
              .App {
                .css-1n6sfyn-MenuList,
                .css-1nmdiq5-menu {
                  .inner_custom_dropdown {
                    > .mentees_profile {
                      padding: 0;
                    }
                    img {
                      margin-right: 0;
                    }
                    > .flex_wrap {
                      padding: 0.3rem 1.6rem;
                    }
                  }
                }
              }
            }
            .css-1nmdiq5-menu {
              .custom_dropdown {
                padding: 1rem 1.6rem;
              }
            }
          }
        }
      }
    }
    .css-1nmdiq5-menu {
      .custom_dropdown {
        &.recommend_custom_dropdown {
          padding: 1rem 1.6rem !important;
          min-height: 5.6rem;
          @extend .justify_content_start;
          flex-direction: inherit;
          gap: 2rem;
        }
      }
    }
  }

  &.i_archive_que_popup {
    .i_popup_content {
      .i_popup_header {
        padding-bottom: 0;
      }

      .i_popup_footer {
        .btn_reject {
          img {
            filter: brightness(15);
          }
        }
      }
    }
  }

  &.i_AvailableTo {
    .i_popup_content {
      max-width: 50.8rem;
      @extend .over_hidden;

      .i_popup_title {
        margin-left: 0;
      }

      .inner_content {
        .tab_data {
          &.comman_head {
            padding: 0.8rem 1.6rem;
          }
        }

        .check_with_name {
          padding: 0.8rem 1.6rem !important;

          .schoo_name {
            margin-left: 1rem !important;
          }
        }
      }
    }

    &.i_make_available_to {
      .inner_content {
        .check_with_name {
          .custom-checkbox {
            margin: 0 0.5rem;
            margin-left: 1rem;
          }
        }

        .check_with_name {
          .schoo_name {
            color: $form-lable-color !important;
            font-family: "Montserrat_medium" !important;
            font-weight: 500 !important;
          }
        }
      }

      .i_popup_footer {
        .assign_btn {
          max-width: 22.8rem;
        }
      }
    }
  }

  &.i_pop_event_details {
    .i_popup_content {
      max-width: 104.9rem;
      overflow: auto;
      max-height: 73.5rem;
      border-radius: 0;

      .i_popup_header {
        .i_popup_title {
          @extend .m_0;
          @extend .fs_18;
        }
        .icon_with_button {
          .edit_events_btn {
            border-radius: 1rem;
            background: #e7f1fd;
            padding: 0 1.6rem;
            min-height: 4rem;
            color: $form-lable-color;
            @extend .fs_16;
            @extend .fw_500;
            line-height: 140%;
            max-width: 15.6rem;
          }
        }
      }

      .events_wrapper {
        .image {
          img {
            max-width: 100%;
            @extend .w_100;
            max-height: 26.7rem;
            @extend .img_object_center;
          }
        }

        .content_area {
          padding-top: 2.4rem;

          > h3 {
            @extend .fw_600;
            @extend .fs_18;
            color: $black-color;
            margin-bottom: 0.5rem;
          }

          > span {
            @extend .fw_500;
            color: $primary-color;
            @extend .fs_16;
            margin-bottom: 2.4rem;
            @extend .d_block;
          }

          .desc {
            border-radius: 0.8rem;
            border: 0.1rem solid $border-color;
            background: $white-color;
            box-shadow: 0 0.4rem 4rem 0 rgba(36, 73, 137, 0.08);
            padding: 2.6rem;
            margin-bottom: 1.6rem;

            p.para {
              @extend .fw_500;
              @extend .fs_14;
              line-height: 2.2rem;
              color: $light-gray;

              span {
                color: $primary-color;
                font-style: italic;
              }
            }

            h3 {
              @extend .fw_500;
              @extend .fs_20;
              margin-bottom: 0.9rem;
            }

            .inner_date_time {
              gap: 2rem;
              margin-bottom: 1.5rem;

              span {
                gap: 0.8rem;
                @extend .position_relative;
                @extend .fs_14;
                @extend .fw_500;
                color: $light-gray;
                &::after {
                  content: "";
                  background-color: $secondary-color;
                  width: 0.2rem;
                  height: 1.6rem;
                  @extend .position_absolute;
                  top: 0.1rem;
                  right: -1rem;
                }

                &:last-child {
                  &::after {
                    @extend .d_none;
                  }
                }
              }
              + .by_name {
                gap: 1.5rem;
                @extend .fs_14;
                @extend .fw_500;
                color: $light-gray;
              }
            }
            .meet_link {
              gap: 1.6rem;
              @extend .fw_400;
              color: $primary-color;
              @extend .fs_16;
              margin-top: 1.5rem;
              .google_link {
                border-radius: 10rem;
                background: $form-input-active-bg;
                width: 4rem;
                height: 4rem;
              }
            }
            ul {
              padding-top: 1.5rem;

              .guest_li {
                .guest_inner {
                  min-height: 7.2rem;
                  .guest_name {
                    .guest_detail {
                      gap: 2.2rem;
                      @extend .fs_16;
                      @extend .fw_500;
                      color: $tab-text;
                      .guest_pic {
                        width: 3.2rem;
                        height: 3.2rem;
                        border-radius: 10rem;
                        @extend .over_hidden;
                        img {
                          width: 3.2rem;
                          height: 3.2rem;
                        }
                      }
                    }
                    .status_tab {
                      border-radius: 3rem;
                      @extend .fs_12;
                      @extend .fw_500;
                      color: $black-color;
                      line-height: 1.4rem;
                      &.declined {
                        background-color: #ea332333;
                      }
                    }
                  }
                }
              }
            }
          }
          .event_time_popup{
            @extend .d_flex;
            @extend .align_items_center;
            margin: 0 0 1.5rem;
            .table_timezone{
              .name_toolkit {
                @extend .position_relative;
                .time_zone_label{
                  line-height: normal;
                  background: $gray-100;
                  margin-left: 8px;
                }
                .full_username{
                  color: $white-color;
                  @extend .fs_14;
                  @extend .fw_400;
                  line-height: 140%;
                  padding: 0.8rem 1rem;
                  @extend .radius_4;
                  background: $dark-bg;
                  @extend .d_flex_align;
                  gap: 1rem;
                  @extend .position_absolute;
                  top: -3.7rem;
                  display: none;
                  @extend .white_space_nowrap;
                  left: unset ;
                  right: 50% !important;
                  transform: translate(50%, 0px);
                }
                &:hover {
                  .full_username {
                    @extend .d_flex;
                  }
                }
              }
            }
          }
        }
      }
      &.popup_header__content {
        padding: 0;
        @extend .over_hidden;
        .popup_header_sticky {
          padding: 3.2rem;
          padding-bottom: 3.5rem;
          + .events_wrapper {
            height: 63.5rem;
            overflow: auto;
            padding: 0 3.2rem 3.2rem;
          }
        }
      }
    }
  }

  &.i_pop_approve_event {
    .i_popup_content {
      max-width: 44.9rem;

      .i_popup_header {
        padding-bottom: 1.6rem;
      }

      .i_close {
        @extend .position_absolute;
        top: 2rem;
        right: 2rem;
      }
    }

    .i_popup_footer {
      padding: 0;

      button {
        max-height: 4.8rem;

        &.btn_Cancel {
          max-width: 10.5rem;
        }

        &.btn_approve {
          max-width: 10.5rem;
        }
      }
    }
  }

  &.i_pop_reject_event {
    .i_popup_content {
      max-width: 44.9rem;

      .i_popup_header {
        padding-bottom: 1.6rem;
      }

      .i_close {
        @extend .position_absolute;
        top: 2rem;
        right: 2rem;
      }
    }

    .form_group {
      margin-bottom: 1.6rem;

      .form_control {
        resize: none;
        height: 16rem;
        max-width: 38.5rem;
        padding: 1.6rem;

        &::placeholder {
          color: $form-input-color;
          @extend .fs_16;
          @extend .fw_400;
        }
      }
    }

    .i_popup_footer {
      padding: 0;

      button {
        max-height: 4.8rem;

        &.btn_Cancel {
          max-width: 10.5rem;
        }

        &.btn_reject {
          max-width: 10.5rem;
        }
      }
    }
  }

  &.i_guest_popup {
    .i_popup_content {
      max-width: 62.4rem;

      .i_popup_header {
        .i_popup_title {
          margin-left: 0;
        }

        .i_close {
          @extend .position_absolute;
          top: 2.7rem;
          right: 3.2rem;
        }
      }
    }

    .guest_wrapper_pop {
      ul {
        height: 51.1rem;
        overflow: auto;

        li {
          padding-right: 1.2rem;

          .inner_guest_pop {
            min-height: 7.2rem;

            .check {
              width: 20%;
              @extend .text_center;
            }

            .guest_info {
              span {
                width: 2.4rem;
                height: 2.4rem;
                @extend .radius_round;

                img {
                  width: 2.4rem;
                  height: 2.4rem;
                }

                &.i_guest_name {
                  height: auto;
                  @extend .w_100;
                  border-radius: 0;
                }
              }
            }

            .remove_guest_btn {
              width: 50%;
              @extend .text_right;
            }
          }
        }
      }
    }

    .i_popup_footer {
      .button {
        .cancel_btn {
          @extend .fw_700;
          color: $primary-color;
        }

        .done_btn {
          background-color: $primary-color;
          max-width: 17.5rem;
        }
      }
    }
  }

  &.i_event_schedule {
    .i_popup_content {
      padding-left: 0;
      padding-right: 0;

      .i_popup_header {
        padding: 0 3.2rem 4rem;

        .i_popup_title {
          margin: 0;
          @extend .text_left;
          max-width: 100%;
        }

        .i_close {
          top: 3.2rem;
          right: 3.2rem;
          width: 3.5rem;
          height: 3.5rem;

          img {
            width: 3.5rem;
            height: 3.5rem;
          }
        }

        + .i_inner_content {
          padding: 0 3.2rem;
        }
      }

      .i_inner_content {
        .head_title {
          @extend .fw_600;
          color: $black-color;
          @extend .fs_32;
          padding-bottom: 2.8rem;
        }
      }
    }

    .i_content_wrapper {
      h4 {
        @extend .fw_600;
        color: $black-color;
        @extend .fs_24;
        line-height: 2.8rem;
        @extend .text_center;
        margin-top: 3.2rem;
        margin-bottom: 3.2rem;
      }

      span {
        @extend .d_block;
        @extend .text_center;
        margin-bottom: 3.2rem;

        &.day_time {
          @extend .fw_500;
          @extend .fs_20;
          color: $tab-text;
        }

        &.meet_link {
          @extend .fw_400;
          color: $primary-color;
          text-decoration: underline;
          @extend .fs_16;
        }
      }

      h3 {
        font-size: 3.2rem;
        color: $black-color;
        line-height: 2.8rem;
        @extend .fw_600;
        @extend .text_center;
      }

      ul {
        gap: 1rem;
        padding: 3.2rem 0;
        height: 17rem;
        overflow: auto;
        margin: 2rem 0;

        li {
          > div {
            &.images_wrap {
              width: 4rem;
              height: 4rem;
              border-radius: 10rem;
              @extend .over_hidden;

              img {
                width: 4rem;
                height: 4rem;
              }
            }
          }
        }
      }
    }

    .i_popup_footer {
      .finish_btn {
        background-color: $primary-color;
        max-width: 20rem;
        color: $white-color;
      }
    }
    .events_scheduled_wrap {
      max-height: 20rem;
      overflow: auto;
      .event_schedule_person {
        .events_schedule_status {
          @extend .m_0;
          display: flex !important;
          .annaunce_mentees {
            .pairs_img_view {
              .common_match {
                @extend .m_0;
                border-color: $white-color;
                .generate_pic {
                  max-width: 1.6rem;
                  min-width: 1.6rem;
                }
                .generate_name_of_mentees {
                  @extend .m_0;
                  max-width: 1.6rem;
                  min-width: 1.6rem;
                }
              }
            }
          }
          img{
            @extend .m_0;
          }
          .annaunce_mentees{
            @extend .m_0;
          }
        }
      }
    }
  }

  &.group_archive_pair {
    .i_popup_content {
      .i_popup_header {
        padding-bottom: 2.5rem;
      }

      .all_inner_content {
        @extend .pt_24;
        .all_group_list {
          max-height: 30rem;
          @extend .w_100;
          overflow: auto;
        }
        .imentor_info {
          padding-bottom: 0;
        }
        .i_popup_footer {
          padding-top: 0;
        }
      }
    }

    .group_particepent_wrapper {
      max-height: 22.8rem;
      overflow: auto;

      .group_particepent {
        @extend .mb_15;
        padding-right: 1.5rem;
        padding-bottom: 3.2rem;
        padding-top: 1.6rem;

        .group_detils {
          width: calc(33.33% - 1.5rem);
          @extend .d_flex;
          justify-content: flex-start;
          @extend .flex_direction_column;
          h6 {
            @extend .fs_14;
            @extend .fw_400;
            color: $black-color;
            @extend .mb_8;
          }

          p {
            &.name_of_title {
              @extend .fs_16;
              @extend .fw_700;
              color: $form-input-color;
              max-width: 20rem;
              .name_of_school {
                @extend .fs_12;
                @extend .fw_700;
                color: $black-color;
              }
            }
          }
        }
      }
    }
  }
  &.group_archive_pair_delete {
    .imentor_info {
      .error_restored {
        background: rgba(214, 68, 68, 0.1);
        > div {
          img {
            width: 2.4rem;
            height: 2.4rem;
          }
        }
      }
    }
  }

  &.i_add_note_popup {
    .i_close {
      @extend .position_absolute;
      top: 2.2rem;
      right: 3.2rem;
    }
    .i_popup_content {
      max-width: 62.4rem;
      padding: 0;
      .i_note_content {
        padding: 3.2rem;
      }
      .i_popup_header {
        h4 {
          color: $black-color;
          @extend .fs_18;
        }
        margin-bottom: 2.4rem;
      }
      .i_main_content {
        .form_group {
          textarea {
            &.form_control {
              resize: none;
              min-height: 14.4rem;
              color: $form-input-color;
              @extend .fs_16;
              &::placeholder {
                color: $form-input-color;
                opacity: 60%;
              }
            }
          }
        }
        .note_pop_btn {
          ul {
            li {
              .btn.danger_btn {
                background: no-repeat !important;
                color: $red-required !important;
                &:hover {
                  color: $white-color !important;
                }
              }
              button {
                &.primary_btn {
                  color: $form-input-color;
                  @extend .fw_700;
                  @extend .fs_16;
                  border: 0.1rem solid transparent;
                  width: 17.9rem;

                  &:hover {
                    color: $white-color;
                    border: 0.1rem solid $primary-color;
                  }
                  &.save_note_btn {
                    background-color: $primary-color;
                    color: $white-color;
                    border: 0.1rem solid transparent;
                    &:hover {
                      border: 0.1rem solid $primary-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .view_note {
        border-top: 0.1rem solid $border-color;
        min-height: 7.2rem;
        @extend .d_flex_center;
      }
    }
  }
  &.i_list_note_popup {
    .i_popup_content {
      max-width: 62.4rem;
      padding: 0;
      min-height: 55.5rem;
      @extend .over_hidden;
      .i_note_content {
        padding: 3.2rem;
        .note_list_wrap {
          height: 37.8rem;
          overflow: auto;
          &.no_data_found{
            @extend .d_flex;
            @extend .align_items_center;
            @extend .justify_content_center;
          }
          .note_list {
            padding: 1.6rem 1.2rem;
            border-bottom: 0.1rem solid $border-color;
            margin-top: 1.5rem;
            &:first-child{
              border-top: 0.1rem solid $border-color;
            }
            .note_list_inner {
              .profile_img {
                @extend .d_flex;
                @extend .align_items_center;
                padding: 0;
                span {
                  color: $black-color;
                  &.user_pic {
                    width: 4rem;
                    height: 4rem;
                    color: $white-color;
                    padding: 0;
                    img {
                      width: 4rem;
                      height: 4rem;
                    }
                  }
                }
              }
              p {
                font-size: 1.3rem;
                color: $grey-text;
                line-height: 2.1rem;
              }
              .date {
                color: $black-color;
                @extend .fs_16;
                @extend .fw_400;
                line-height: 2.24rem;
              }
            }
          }
        }
      }
      .i_popup_header {
        padding-bottom: 0;
        h4 {
          margin-right: 6rem;
        }
        .form_group {
          margin-bottom: 2.4rem;
          .form_control {
            padding-left: 3.5rem;
            min-height: 4rem;
            border-radius: 10rem;
          }
        }
        .serch_icon {
          @extend .position_absolute;
          top: 1rem;
          left: 0.8rem;
        }
      }
    }
  }
  &.delete_note_details {
    .i_popup_content {
      max-width: 52.4rem;
      .i_popup_footer {
        padding-top: 0;
        .btn_Cancel {
          border: 0;
          color: $primary-color;
          &:hover {
            color: $white-color;
          }
        }
        .btn {
          &.btn_Delete {
            height: 4.8rem;
            &:hover {
              color: $white-color;
            }
          }
        }
      }
      .i_close {
        @extend .position_absolute;
        right: 3.2rem;
        top: 2.5rem;
      }
      .i_popup_title {
        max-width: 37.3rem;
        margin: 0 auto;
      }
      .i_easther_howard {
        .imentor_logo {
          &::after {
            @extend .d_none;
          }
        }
      }
    }
  }
  &.i_new_broadcast_message {
    .i_popup_content {
      .inner_content {
        .school_list {
          li {
            .inner_data {
              .mentees_profile {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
    .i_popup{
      .i_popup_content{
        min-height: unset;
      }
    }
  }
  &.i_admin_move_popup{
    .i_popup_content{
      max-height: 60.7rem;
      height: 90%;
      @extend .d_flex;
      @extend .flex_direction_column;
      .i_popup_header{
        .i_popup_title{
          margin: 0;
        }
      }
      > .comman_head{
        box-shadow: 2px 2px 4px #7D7D7D14;
      }
      .active_tabing {
      ul.active_tabs{
        li{
          width: auto;
          padding-inline: 3.5rem;
          min-width: unset;
        }
      }
      }
      .inner_content{
        .tab_data.comman_head{
          padding: 0.8rem 1.6rem;
          .comman_menu{
            .form_group{
              max-width: 100%;
            }
          }
        }
      }
      .admin_user_list{
        flex: 1;
        @extend .over_hidden;
        .admin_us_li{
          &.no_data_found{
            @extend .h_100;
            @extend .d_flex;
            @extend .align_items_center;
            @extend .justify_content_center;
          }
        }
        ul{
          height: 100%;
          overflow: auto;
          li{
            padding: 2.4rem 2.2rem 2.4rem 3.8rem;
            @include trans;
            &:hover{
              background-color: $form-input-active-bg;
            }
            &:not(:last-child){
              border-bottom: 1px solid $border-color;
            }
            &.disable_permission{
              pointer-events: none;
              .admin_user_name{
                opacity: 0.2;
              }
            }
            .member_pic{
              width: 2.4rem;
              height: 2.4rem;
              @extend .over_hidden;
              border-radius: 10rem;
              background-color: $form-input-active-bg;
              img{
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .admin_user_name{
              padding-left: 1rem;
              p{
                color: $tab-text;
                @extend .montserrat_medium;
                @extend .fs_16;
              }
            }
          }
        }
        .admin_us_li{
          &.no_data_found{
            @extend .h_100;
            @extend .d_flex;
            @extend .align_items_center;
            @extend .justify_content_center;
          }
        }
      }
    }
  }
  &.i_admin_permission_popup{
    .i_popup_content{
      max-height: 36rem;
      height: auto;
      min-height: unset;
      .admin_user_list{
        ul{
          border-top: 1px solid $border-color;
          li{
            padding: 2.5rem 2.2rem;
            &:not(:last-child){
              border-bottom:  1px solid $border-color;
            }
            .admin_user_name{
              padding: 0;
            }
          }
        }
      }
    }
  }
  &.i_admin_permission_popup{
    .i_popup_content{
      max-height: 36rem;
      height: unset;
      min-height: unset;
      .admin_user_list{
        ul{
          border-top: 1px solid $border-color;
          li{
            padding: 2.5rem 2.2rem;
            &:not(:last-child){
              border-bottom:  1px solid $border-color;
            }
            .admin_user_name{
              padding: 0;
            }
          }
        }
      }
    }
  }
  &.i_admin_change_popup{
    .i_popup_content{
      max-width: 70rem;
      min-height: unset;
      .admin_profile_info{
        padding: 2.5rem 3.2rem 4rem;
        .admin_pr_img{
          max-width: 12.4rem;
          height: 12.4rem;
          width: 100%;
          @extend .m_auto;
          @extend .position_relative;
          .admin_avtar_img{
            @extend .w_100;
            @extend .h_100;
            @extend .radius_round;
            box-shadow: 0px 0px 30px #74747426;
            border: 2px solid $white-color;
            background-color: $form-input-active-bg;
            img{
              @extend .w_100;
              @extend .h_100;
              @extend .img_object_center;
            }
          }
          .user_icon {
            @extend .position_absolute;
            width: 4.8rem;
            height: 4.8rem;
            bottom: 0;
            right: 0;
            @extend .radius_round;
            @extend .d_flex;
            background: $white-color;
            img{
              width: 2.4rem;
              height: 2.4rem;
              margin: auto;
            }
          }
        }
        .admin_change_content{
          margin: 3.2rem 0;
          @extend .text_center;
          p{
            @extend .fs_16;
            line-height: 1.5;
          }
          strong{
            @extend .fw_700;
          }
        }
        .proceed_part{
          @extend .text_center;
          padding: 2.4rem 0 0;
          border-top: 1px solid $separtor-color;
          h6{
            @extend .fs_16;
            @extend .fw_700;
          }
          .proceed_btns{
            margin: 3.2rem 0 0;
            button{
              max-width: 200px;
              min-width: unset;
              width: 100%;
              @extend .justify_content_center;
              img {
                margin-right: 1.4rem;
                filter: brightness(21);
              }
              &.upload_img{
                @extend .fs_16;
              }
            }
          }
        }
      }
    }
  }
  .i_popup_content{
    @include media_query(two_extra_large) {
      width: calc(100% - 20px) !important;
    }
  }
}

.image_set_layer {
  padding-top: 4rem;
  padding-bottom: 4.8rem;
  gap: 3.1rem;

  span {
    @extend .fw_700;
  }

  .main_layer {
    @extend .w_100;
    max-width: 33.7rem;
    @extend .position_relative;

    .drag_circle {
      border-radius: 2.1rem;
      border: 1px solid $primary-color;
      background: $white-color;
      width: 2.1rem;
      height: 2.1rem;
      @extend .d_block;
      @extend .position_absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: grabbing;
      right: 0;
      z-index: 999;
    }
  }

  .border_layer {
    background: $form-input-border;
    border-radius: 1rem;

    .layer_blue {
      background-color: $primary-color;
      height: 0.8rem;
      width: 60%;
      border-radius: 1rem;
      @extend .position_relative;
    }
  }

  > div {
    width: 53%;

    input {
      @extend .w_100;
    }
  }
  .input_track {
    height: 0.5rem;
    @extend .w_100;
    border-radius: 0.4rem;
    align-self: center;
    > div {
      cursor: pointer !important;
    }
  }
  .input_dot_drag {
    width: 2.1rem;
    height: 2.1rem;
    border: 0.1rem solid $primary-color;
    background-color: $white-color;
    border-radius: 10rem;
    @extend .cursor_pointer;
  }
}

.dragBox {
  max-width: 25.5rem;
  @extend .w_100;
  color: $form-input-color;
  line-height: 140%;

  .drag_img {
    @extend .montserrat_bold;
    line-height: 140%;
  }

  input[type="file"] {
    @extend .position_absolute;
    height: 100%;
    @extend .w_100;
    opacity: 0;
    top: 0;
    left: 0;
  }
}

.i_select_country_popup {
  .i_popup_content {
    max-width: 56.1rem;
    @extend .w_100;
    padding: 0;

    .i_popup_header {
      padding: 3.2rem;

      .i_close {
        margin: unset;
      }

      .i_popup_title {
        margin: auto;
        @extend .fs_18;
        @extend .fw_700;
      }
    }

    .main_content {
      .form_group {
        margin: 0 3.2rem 3.2rem;
      }

      .all_country {
        max-height: 43.2rem;
        height: 100%;
        overflow: auto;
      }

      .all_sub_country {
        padding: 1rem 1.6rem 1rem 4.2rem;
        @extend .align_items_center;
        margin-bottom: 0.7rem;

        &:hover {
          background-color: $form-input-active-bg;
        }

        &.select_all_sub_country {
          background-color: #b6d4f8;
        }

        .flag_img {
          width: 2rem;
          height: 1.4rem;
        }

        .c_name {
          line-height: 140%;
          color: #2d4252;
          margin-left: 1rem;
        }
      }
    }

    .i_popup_footer {
      padding: 3.2rem;

      .btn_Cancel,
      .btn_select {
        max-width: 23.6rem;
      }
    }
  }
}

.zoom-content {
  width: 40rem;
  height: 30rem;
  background-color: lightgray;
  border: 0.1rem solid gray;
  transition: transform 0.3s ease;
}

.container-editPopup {
  @extend .position_relative;
  max-width: 34.1rem;
  margin: 0 auto;
  height: 34.1rem;
  @extend .over_hidden;
  border-radius: 1.6rem;

  .reactEasyCrop_Image {
    @extend .w_100;
  }
}

.cropperEditPopup {
  @extend .position_relative;
  @extend .w_100;
  height: 100%;
}

.archive_data {
  max-width: 40rem;
  @extend .w_100;

  .inner_wrap {
    @extend .d_flex;

    .top_img {
      width: 14rem;
      height: 14rem;
      &.remove_pop_img {
        height: 16.8rem;
      }
    }

    .all_details {
      margin: 1.2rem 1.6rem 1.2rem 2.4rem;
      @extend .d_flex;
      @extend .align_items_center;

      .youtube_min {
        @extend .d_flex;
        @extend .align_items_center;

        img {
          margin-right: 0.7rem;
        }

        .total_min {
          color: $form-input-color;
        }
      }

      .user_que {
        margin: 0.8rem 0;
      }
    }
  }
}

//Matches popup
.i_match_into_pair {
  .i_popup_content {
    max-width: 70rem;
    width: calc(100% - 20px);

    .i_popup_header {
      &.popup_center_title {
        .i_popup_title {
          @extend .fs_20;
        }
      }
    }

    .i_inner_content {
      background-color: $light-blue;
      padding: 2.1rem 4rem;
      border-radius: 0.8rem;
      @extend .d_flex_center;
      @extend .position_relative;

      h3 {
        @extend .d_none;
      }

      &::after {
        content: "";
        @extend .position_absolute;
        background: url(../images/union_gray.svg);
        background-repeat: no-repeat;
        width: 12.9rem;
        height: 13rem;
        bottom: 0;
        right: -3.4rem;
      }

      &::before {
        content: "";
        @extend .position_absolute;
        background: url(../images/union_gray_bg.svg);
        background-repeat: no-repeat;
        width: 12.9rem;
        height: 13rem;
        bottom: 1rem;
        left: 0px;
      }

      .matches_user {
        @extend .text_center;
        width: 16.5rem;

        h6 {
          @extend .fw_700;
          @extend .fs_14;
          line-height: 140%;
          max-width: 12rem;
          @extend .text_ellipsis;
          @extend .over_hidden;
          margin: 0 auto;
          @extend .white_space_nowrap;
        }

        .user_pic {
          width: 8.7rem;
          height: 8.7rem;
          @extend .m_auto;
          @extend .fs_28;
        }

        .profile_img {
          border-radius: 10rem;
          border: 0.3rem solid $white-color;
          width: 8.8rem;
          height: 8.8rem;
          @extend .over_hidden;
          margin: auto;

          img {
            @extend .img_object_center;
            @extend .w_100;
            height: 100%;
          }
        }

        .tag_name {
          border-radius: 5rem;
          border: 0.1rem solid $white-color;
          background: $tag-blue;
          @extend .d_flex_center;
          gap: 0.7rem;
          padding: 0.5rem 1.1rem;
          max-width: 9.2rem;
          @extend .w_100;
          margin: -1rem auto 0;
          @extend .position_relative;

          img {
            width: 1.7rem;
          }

          p {
            color: $secondary-color;
            @extend .fs_10;
            @extend .fw_700;
          }
        }
      }

      .s_checkmark {
        @extend .position_relative;
        z-index: 1;

        &::after {
          content: "";
          @extend .position_absolute;
          background: url(../images/Line1.svg);
          background-repeat: no-repeat;
          width: 9rem;
          height: 0.6rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
        }
      }

      &.i_pairs_archive_content {
        background: rgba(233, 234, 240, 0.3);

        .matches_user {
          min-width: 18rem;

          &:last-child {
            margin-left: 0;
          }
        }
      }

      &.i_pairs_unselect {
        .matches_user {
          min-width: 18rem;

          &:last-child {
            margin-left: 0;
          }
        }
      }
    }

    .imentor_info {
      padding: 3.2rem 0;
    }

    .i_popup_footer {
      .btn_request_info {
        max-width: 20rem;
      }
      .btn_approve {
        width: auto;
        max-width: unset;
        text-transform: inherit;
      }
      .btn_reject {
        max-width: 20rem;
        @extend .white_space_nowrap;
        text-transform: initial;
        img {
          filter: brightness(10);
        }
      }
    }
  }
}

.i_popup {
  .i_popup_content {
    .s_checkmark {
      .unselect_icon {
        background-color: $white-color;
        border-radius: 5rem;
        border: 0.1rem solid #28303f;
        width: 3.2rem;
        height: 3.2rem;
        @extend .d_flex_center;
        img {
          width: 2rem;
          height: 2rem;
        }
      }
    }

    .imentor_info {
      border-bottom: 0.1rem solid $separtor-color;

      &.border_0 {
        border-bottom: 0;
      }
    }

    .total_count {
      margin: 3.2rem 0;
      padding: 0 2rem;
      .sub_info {
        max-width: 18rem;
        @extend .w_100;
        min-width: 50%;
        padding: 2rem;
        &:not(:last-child) {
          border-right: 0.1rem solid $form-input-border;
          .title {
            @extend .text_left;
          }
        }
        &:last-child {
          @extend .text_left;
          .title {
            @extend .text_left;
          }
          .info_list {
            margin-left: 9rem;
          }
        }

        .title {
          color: $tab-text;
          @extend .text_left;
          margin-bottom: 0.8rem;
        }

        h3 {
          @extend .fw_600;
          font-size: 4rem;
          line-height: 110%;
        }
        .info_list {
          width: 12rem;
          margin: 0 0 0 10rem;
          @extend .text_left;
        }
      }
    }
  }
}

.i_unselect_match {
  .i_popup_content {
    .i_popup_title {
      max-width: inherit !important;
      font-size: 1.8rem !important;
    }

    .i_inner_content {
      .tag_name {
        p {
          color: $primary-color !important;
        }
      }
    }

    .s_checkmark {
      &::after {
        background: url(../images/m_line.svg) !important;
        width: 2.2rem !important;
        height: 3rem !important;
        top: 50%;
        left: -3rem !important;
        transform: translateY(-50%) !important;
      }

      &::before {
        content: "";
        @extend .position_absolute;
        background: url(../images/m_line.svg) !important;
        width: 2.2rem !important;
        height: 3rem !important;
        top: 50%;
        right: -3rem !important;
        transform: translateY(-50%) rotate(180deg) !important;
      }
    }
  }
}

.i_archive_pair {
  .i_popup_title {
    max-width: inherit !important;
    font-size: 1.8rem !important;
  }

  .all_inner_content {
    .s_checkmark {
      &::after {
        filter: grayscale(1);
        opacity: 0.5;
      }
    }

    .error_restored {
      background: rgba(199, 152, 55, 0.1);
    }
  }

  .sub_info {
    max-width: 50%;
  }
}

//broadcast popup
.i_msg_preview {
  .inner_content {
    .inner_data {
      .mentees_profile {
        @include profile_small;
        margin-right: 2.2rem;

        img {
          @extend .img_object_center;
        }
      }

      .m_name {
        color: $tab-text;
        @extend .fw_500;
        @extend .fs_16;
        line-height: 140%;
      }
    }
  }

  .form-group {
    .to_user {
      @extend .w_100;
    }

    .show_all_btn {
      max-width: 7rem;
      @extend .w_100;
      @extend .fw_700;
      color: $primary-color;
    }

    .multiple_users {
      .mentees_profile {
        border: 0.1rem solid #b6d4f8;
        width: 2.4rem;
        height: 2.4rem;
        @extend .d_flex_center;

        span {
          color: #093468;
          @extend .fw_500;
          @extend .fs_10;
        }

        img {
          @extend .img_object_center;
          @extend .w_100;
          max-width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }

  .i_popup_footer {
    padding-top: 0 !important;

    .btn_Delete {
      max-width: fit-content !important;
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}

.i_new_broadcast_message {
  .i_popup_content {
    min-height: 71.7rem;
    .inner_content {
      .filter_dropdown {
        @extend .p_0;
      }

      .form-wrapper {
        padding: 0.8rem 3.2rem;

        .form_group {
          margin-bottom: 0;
          max-width: unset;

          label {
            @extend .fs_14;
            color: $form-lable-color;
          }

          p {
            margin-top: 0.8rem;
          }

          .dropdown_with_btn {
            gap: 1rem;
            margin-bottom: 1.6rem;

            .App {
              @extend .w_100;

              .css-13cymwt-control {
                min-height: 4.8rem;
              }

              .css-1n6sfyn-MenuList,
              .css-1nmdiq5-menu {
                .css-xjlpp-option,
                .css-9of0na-option {
                  padding: 0 !important;
                  background: transparent !important;
                  .custom_dropdown {
                    padding: 1rem 1.6rem !important;
                    min-height: 5.6rem;
                  }
                }

                .inner_custom_dropdown {
                  img {
                    width: 3.2rem;
                    height: 3.2rem;
                    @extend .radius_round;
                    @extend .img_object_center;
                    &.generate_pic {
                      width: 2rem;
                      height: 2rem;
                      border-radius: 10rem;
                      margin-right: 0;
                    }
                  }

                  .new_announce_name {
                    padding: 0;
                  }
                }
              }
              .pairs_img_view {
                padding: 0;
                width: 3.2rem;
                height: 3.2rem;
                .user_pic {
                  padding: 0;

                  .common_match {
                    border: 0.3rem solid #e3e9f1;
                    border-radius: 10rem;
                    max-width: 2.5rem;
                    height: 1.5rem;
                    padding: 1rem;
                  }
                  img.generate_pic {
                    width: 1.6rem;
                    height: 1.6rem;
                    border-radius: 10rem;
                    min-width: 1.6rem;
                  }
                }
              }
            }

            .border_btn {
              min-width: unset;
              padding: 1rem 2.4rem;
            }
          }

          .reciptment_list {
            gap: 0.8rem;

            .more_school_list {
              .add_school_list {
                .add_school_inst_name {
                  margin: 0;
                  justify-content: flex-start;
                  max-width: 100%;
                  @extend .d_flex;
                  @extend .align_items_center;
                  background: $grey-color;
                  color: $black-color;
                  line-height: 2.4rem;
                  border-radius: 10rem;
                  padding: 0.6rem 1.6rem;
                  gap: 0.8rem;
                  img {
                    width: 1.6rem;
                    min-width: 1.6rem;
                    height: 1.6rem;
                    @extend .radius_round;
                  }
                }
              }
            }
          }
          .more_recipt_list {
            color: $black-color;
            @extend .fs_16;
            @extend .fw_500;
            line-height: 2.4rem;
            padding: 0.5rem 0;
            border-radius: 30px;
            border: 0.2rem solid $primary-color;
            background: $white-color;
            height: 4rem;
            min-width: 10rem;
          }
          &.announce_modal_drop {
            @extend .w_100;
            background: transparent;

            .css-1hb7zxy-IndicatorsContainer {
              @extend .d_block;
            }

            .css-13cymwt-control,
            .css-t3ipsp-control {
              background: unset;
            }

            .css-1hb7zxy-IndicatorsContainer {
              @extend .d_flex;
            }
          }
        }
      }
      &.send_main_form {
        .mail_msg {
          textarea {
            min-height: 33.3rem;
          }
        }
      }
    }

    .i_popup_footer {
      .btn_approve {
        gap: 1.2rem;
        max-width: 22.8rem;
      }

      .btn_request_info {
        max-width: 20rem;
      }
    }
  }
  .css-1nmdiq5-menu {
    .custom_dropdown {
      &.recommend_custom_dropdown {
        padding: 1rem 1.6rem !important;
        min-height: 5.6rem;
        @extend .justify_content_start;
        flex-direction: inherit;
        gap: 2rem;
      }
    }
  }
  .i_recipients_popup {
    .i_popup_content {
      .inner_content {
        .school_list {
          li {
            .inner_data {
              .mentees_profile {
                &.annaunce_mentees {
                  margin-right: 2rem;
                }
              }
            }
          }
        }
      }
    }
  }
  
}

.i_recipients_popup {
  .i_popup_content {
    padding: 0;

    .i_popup_header {
      padding: 2.5rem 3.2rem 1.7rem;
      padding-top: 2.5rem;

      .back_btn.return_page {
        @extend .position_absolute;
        @extend .fs_14;
        line-height: 2.4rem;
      }

      .i_popup_title {
        font-size: 1.6rem !important;
        color: $tab-head;
      }
    }

    .comman_head {
      ul.active_tabs {
        li {
          min-width: unset;
        }
      }
    }

    .inner_content {
      .recipients_tab {
        margin-bottom: 0.8rem;

        .react-tabs__tab-list {
          padding: 0 2.2rem;
          box-shadow: 0 1.4rem 1.4rem 0 rgb(116 116 116 / 5%);

          .react-tabs__tab {
            min-width: 12.1rem;
          }
        }
      }

      .tab_data {
        &.comman_head {
          padding: 0.8rem 1.2rem;

          .comman_menu {
            .form_group {
              max-width: unset;
            }
          }

          .close_icon {
            @extend .position_absolute;
            right: 0.8rem;
            top: 50%;
            width: 2.4rem;
            height: 2.4rem;
            transform: translateY(-50%);
          }
        }
      }

      .school_list {
        height: 41rem;
        overflow: auto;
        @extend .w_100;
        scrollbar-width: thin; /* For FireFox Only */

        li {
          @extend .w_100;
          @extend .d_flex_align;
          min-height: 7.2rem;
          border-bottom: 0.1rem solid $border-color;
          @extend .cursor_pointer;

          &:hover {
            background-color: $table-hover;
          }

          .check_with_name {
            @extend .w_100;
            padding: 0.8rem 2.4rem;
            @extend .d_flex_align;

            .schoo_name {
              @extend .d_flex_align;
              @extend .justify_content_between;
              @extend .fs_16;
              @extend .fw_400;
              line-height: 2.1rem;
              color: $tab-text;
              @extend .text_capitalize;
              @extend .flex_direction_column;
              @extend .align_items_start;

              .pair_title_name {
                @extend .d_block;
                color: $secondary-color;
                @extend .fs_10;
                @extend .fw_700;
                line-height: 1.4rem;
              }
            }
            .and_sign {
              display: flex;
              align-self: flex-start;
              padding: 0 0.5rem;
              padding-top: 0.2rem;
            }

            h6 {
              @extend .fw_700;
              @extend .fs_12;
              color: $secondary-color;
            }
          }

          .custom-checkbox {
            .checked {
              visibility: hidden;

              & + span::before {
                width: 2.4rem;
                height: 2.4rem;
                border-radius: 0.4rem;
                border: 0.2rem solid $tab-text;
              }

              & + span::after {
                top: 0.4rem;
                width: 0.6rem;
                height: 1.2rem;
              }

              &:checked + span:before {
                border-color: transparent;
              }
            }
          }

          .inner_data {
            .mentees_profile {
              @include profile_small;
              margin-left: 0;
              width: 3.4rem;
              max-width: 3.4rem;
              padding: 1px 0 0 10px;
              &.annaunce_mentees {
                margin-left: 2.2rem;
                margin-right: 0.8rem;
                img {
                  @extend .img_object_center;
                  width: 2.4rem;
                  height: 2.4rem;
                  max-width: 2.4rem;
                }
                .pairs_img_view {
                  .user_pic {
                    img {
                      width: 1.2rem;
                      height: 1.2rem;
                    }
                  }
                }
              }
              img {
                @extend .img_object_center;
                width: 2.4rem;
                max-width: 2.4rem;
                height: 2.4rem;
                border-radius: 10rem;
              }
              .pairs_img_view {
                .user_pic {
                  padding: 0;
                  width: 3.2rem;
                  height: 3.2rem;
                  .common_match {
                    width: 2.4rem;
                    height: 2.4rem;
                    img {
                      @extend .img_object_center;
                      @extend .w_100;
                      width: 1.6rem;
                      height: 1.6rem;
                      min-width: 1.6rem;
                      border-radius: 10rem;
                      &.generate_pic {
                        &.top_pic {
                          top: -0.8rem;
                          left: -0.5rem;
                        }
                        &.bottom_pic {
                          bottom: -0.8rem;
                          right: -0.5rem;
                        }
                      }
                    }
                    .generate_name_of_mentees {
                      &.top_pic {
                        top: -0.8rem;
                        left: -0.5rem;
                      }
                      &.bottom_pic {
                        bottom: -0.8rem;
                        right: -0.5rem;
                      }
                    }
                  }
                }
              }
            }
          }
          &.disableList {
            cursor: default;
            .check_with_name {
              h6 {
                color: $tab-text;
                opacity: 0.5;
                @extend .fs_12;
                @extend .fw_700;
              }
              > div {
                .custom-checkbox {
                  opacity: 0.5;
                }
                .inner_data {
                  .annaunce_mentees {
                    opacity: 0.5;
                  }
                  .schoo_name {
                    opacity: 0.5;
                  }
                  .and_sign {
                    opacity: 0.5;
                  }
                  .disable_list_icon {
                    &:hover {
                      .tooltip {
                        @extend .d_block;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.no_data_found {
          @extend .d_flex_center;
          @extend .fs_16;
          @extend .text_center;
          @extend .montserrat_bold;
        }
      }
    }

    .i_popup_footer {
      padding: 0 0.8rem;

      .btn_Delete {
        margin: 0.8rem 1.6rem;
        @extend .justify_content_start;
        @extend .p_0;
        color: $grey-text;
        border: 0;

        &.btn_clear {
          color: $primary-color;
        }
      }
    }
  }
}

.i_Delete_Draft_Msg {
  .i_popup_content {
    max-width: 41.1rem;

    .i_popup_header {
      padding: 0;
    }

    .i_popup_title {
      max-width: unset !important;
      padding-bottom: 1.6rem;
    }

    .i_popup_footer {
      button {
        max-width: 14rem;
        max-height: 4.8rem;
        border: 0 !important;
        color: $white-color !important;

        &.btn_reject {
          @extend .ml_0;
        }
        &.annou_border_btn {
          background: $white-color;
          color: $form-lable-color !important;
          border: 0.2rem solid $primary-color !important;
          &:hover {
            color: $white-color !important;
            border: 0.2rem solid $secondary-color !important;
          }
        }
      }
    }
  }
}

.i_introductory_msg {
  .i_popup_content {
    .inner_content {
      .msg_info {
        padding: 1.2rem 2.4rem;
        gap: 1.6rem;
      }

      .switch {
        padding: 1.6rem 2.4rem;
        gap: 1.6rem;
        justify-content: unset;

        .switch_btn {
          background: $border-color;
          transition: 0.2s ease-in-out;
        }

        .switch_name {
          @extend .fw_500;
          @extend .fs_16;
          color: $form-input-color;
        }
      }
    }

    .click_to_edit {
      background: #f3f3f3;
      padding: 3.2rem 7.6rem;

      .edit_popup {
        border-radius: 1.6rem;
        border: 0.1rem solid #b6d4f8;
        opacity: 0.8;
        background: $white-color;
        box-shadow: 0px 0px 35.45454406738281px 0px rgba(116, 116, 116, 0.15);
        max-width: 32.5rem;
        @extend .w_100;
        margin: auto;
        min-height: 47.7rem;
        height: 100%;
        @extend .position_relative;

        .top_header {
          padding: 1.6rem 2.4rem;

          .main_logo {
            @extend .d_flex_align;
            gap: 0.5rem;

            .imentor_logo {
              background-color: $secondary-color;
              width: 3.2rem;
              height: 3.2rem;
              border-radius: 10rem;
              filter: drop-shadow(0 0.1rem 0.2rem rgba(0, 0, 0, 0.25));
              @extend .d_flex_center;
            }

            p {
              @extend .fs_10;
              @extend .fw_600;
            }
          }

          h5 {
            color: $secondary-color;
            @extend .fw_600;
            @extend .fs_18;
            margin-top: 1.6rem;
          }
        }

        .btn_click_edit {
          border-radius: 0.8rem;
          border: 0.1rem solid $grey-text;
          @extend .fs_16;
          padding: 1.2rem 2.4rem;
          color: $grey-text;
          gap: 1.2rem;
          @extend .d_flex_center;
          margin: 2.4rem auto;
          @extend .fw_400;

          img {
            filter: opacity(0.5);
          }
        }

        .main_footer {
          @extend .position_absolute;
          bottom: 0;

          .msg_suggestion {
            padding: 0.8rem 2.4rem;
            gap: 0.6rem;
            @extend .d_flex;
            @extend .flex_wrap;

            .inner_msg {
              padding: 0.5rem 1.1rem;
              color: $tab-text;
              background-color: $search-input;
              @extend .d_inline_block;
              border-radius: 5rem;
              font-size: 0.95rem;
              line-height: 1.3rem;
              @extend .fw_500;
            }
          }

          .footer_chat {
            padding: 1.3rem 1.8rem;
            border-top: 0.1rem solid $border-color;
            @extend .d_flex_center;
            gap: 0.8rem;

            .f_icon {
              width: 2.4rem;
              height: 2.4rem;
              border: 0.1rem solid $border-color;
              border-radius: 5rem;
              @extend .d_flex_center;
            }

            form {
              .form_group {
                margin: 0;

                .form_control {
                  border-radius: 5rem;
                  background: $search-input;
                  border: unset;
                  padding: 1rem 1.4rem;
                  color: $tab-text;
                  @extend .fs_10;
                  min-height: 3.2rem;
                  min-width: 19rem;
                  @extend .w_100;
                }
              }
            }
          }
        }
      }

      &.active {
        padding: 1.6rem 3.2rem 3.2rem;

        .edit_popup {
          opacity: 1;
        }

        .editable_preview {
          @extend .fw_700;
          @extend .fs_16;
          color: #072851;
          margin-bottom: 0.8rem;
        }

        .main_group {
          padding: 0 1.6rem;

          .form_control {
            min-height: 7.7rem;
            padding: 0.8rem;
            color: $form-input-color;
            @extend .fs_14;
            resize: none;
            border: 0.1rem solid $border-color;
            &:focus {
              border: 0.1rem solid #1168d0;
              box-shadow: 0 0 3rem 0 rgba(116, 116, 116, 0.15);
            }
          }
        }

        .btn_click_edit {
          border: 0.2rem solid $primary-color;
          color: $form-lable-color;
          @extend .fw_700;

          img {
            filter: opacity(1);
          }
        }
      }
    }

    .i_popup_footer {
      padding: 1.6rem 2.4rem;

      button {
        max-width: unset;
        width: auto;
        gap: 1.2rem;

        img {
          margin: 0;
        }
      }
    }
  }

  .switch_btn:has(> .switch_checkbox:checked) {
    background: $primary-color !important;
    @include trans;
  }
}

.archived_questions {
  .i_popup_content {
    max-width: 46.2rem !important;
  }

  .i_popup_header {
    padding-bottom: 3.6rem;
  }

  .mentees_success_disable {
    margin-bottom: 2rem !important;
  }

  .disable_mentees {
    padding-top: 1.7rem !important;
  }
}

.i_introductory_msg {
  .i_popup_content {
    .inner_content {
      .msg_info {
        padding: 1.2rem 2.4rem;
        gap: 1.6rem;
        img {
          object-fit: contain;
        }
      }

      .switch {
        padding: 1.6rem 2.4rem;
        gap: 1.6rem;
        justify-content: unset;

        .switch_btn {
          background: $border-color;
          transition: 0.2s ease-in-out;
        }

        .switch_name {
          @extend .fw_500;
          @extend .fs_16;
          color: $form-input-color;

          &.text_blue {
            color: $primary-color;
            @extend .fw_700;
          }
        }
      }
    }

    .click_to_edit {
      background: #f4f9ff;
      padding: 3.2rem 7.6rem;

      .edit_popup {
        border-radius: 1.6rem;
        border: 0.1rem solid var(--secondary-light-active, #b6d4f8);
        opacity: 0.8;
        background: $white-color;
        box-shadow: 0px 0px 35.45454406738281px 0px rgba(116, 116, 116, 0.15);
        max-width: 32.5rem;
        @extend .w_100;
        margin: auto;
        min-height: 32rem;
        height: 100%;
        @extend .position_relative;

        .top_header {
          padding: 1.6rem 2.4rem;

          .main_logo {
            @extend .d_flex_align;
            gap: 0.5rem;

            .imentor_logo {
              background-color: $secondary-color;
              width: 3.2rem;
              height: 3.2rem;
              border-radius: 10rem;
              filter: drop-shadow(0 0.1rem 0.2rem rgba(0, 0, 0, 0.25));
              @extend .d_flex_center;
            }

            p {
              @extend .fs_10;
              @extend .fw_600;
            }
          }

          h5 {
            color: $secondary-color;
            @extend .fw_600;
            @extend .fs_18;
            margin-top: 1.6rem;
          }
        }

        .btn_click_edit {
          border-radius: 0.8rem;
          border: 0.1rem solid $grey-text;
          @extend .fs_16;
          padding: 1.2rem 2.4rem;
          color: $grey-text;
          gap: 1.2rem;
          @extend .d_flex_center;
          margin: 2.4rem auto;
          @extend .fw_400;
          @extend .white_space_nowrap;

          img {
            filter: opacity(0.5);
          }
        }

        .main_footer {
          @extend .position_absolute;
          bottom: 0;

          .msg_suggestion {
            padding: 0.8rem 2.4rem;
            gap: 0.6rem;
            @extend .d_flex;
            @extend .flex_wrap;

            .inner_msg {
              padding: 0.5rem 1.1rem;
              color: $tab-text;
              background-color: $search-input;
              @extend .d_inline_block;
              border-radius: 5rem;
              font-size: 0.95rem;
              line-height: 1.3rem;
              @extend .fw_500;
            }
          }

          .footer_chat {
            padding: 1.3rem 1.8rem;
            border-top: 0.1rem solid $border-color;
            @extend .d_flex_center;
            gap: 0.8rem;

            .f_icon {
              width: 2.4rem;
              height: 2.4rem;
              border: 0.1rem solid $border-color;
              border-radius: 5rem;
              @extend .d_flex_center;
            }

            form {
              .form_group {
                margin: 0;

                .form_control {
                  border-radius: 5rem;
                  background: $search-input;
                  border: unset;
                  padding: 1rem 1.4rem;
                  color: $tab-text;
                  @extend .fs_10;
                  min-height: 3.2rem;
                  min-width: 19rem;
                  @extend .w_100;
                }
              }
            }
          }
          &.introductory_footer {
            position: initial;
          }
        }
      }

      &.active {
        padding: 1.6rem 3.2rem 3.2rem;

        .edit_popup {
          opacity: 1;
        }

        .editable_preview {
          @extend .fw_700;
          @extend .fs_16;
          color: #072851;
          margin-bottom: 0.8rem;
        }

        .main_group {
          padding: 0 1.6rem;

          .form_control {
            min-height: 7.7rem;
            padding: 0.8rem;
            color: $form-input-color;
            @extend .fs_14;
            resize: none;

            &:focus {
              border: 0.1rem solid #1168d0;
              box-shadow: 0 0 3rem 0 rgba(116, 116, 116, 0.15);
            }
          }
        }

        .btn_click_edit {
          border: 0.2rem solid $primary-color;
          color: $form-lable-color;
          @extend .fw_700;

          img {
            filter: opacity(1);
          }
        }
      }
    }

    .i_popup_footer {
      padding: 1.6rem 2.4rem;

      button {
        min-width: 20rem;
        @extend .white_space_nowrap;
        width: auto;
        gap: 1.2rem;

        img {
          margin: 0;
        }
      }
    }
  }

  .switch_btn:has(> .switch_checkbox:checked) {
    background: $primary-color !important;
    @include trans;
  }
}

.archived_questions {
  .mentees_success_disable {
    margin-bottom: 2rem !important;
  }

  .disable_mentees {
    padding-top: 1.7rem !important;
  }

  .i_popup {
    background-color: transparent;
    position: unset;

    .i_popup_content {
      box-shadow: 0rem 0rem 3rem 0rem rgba(116, 116, 116, 0.15);
      max-width: 46.2rem !important;
      position: absolute !important;
      z-index: 2;
      top: -13.2rem;
      left: -48.6rem;
      transform: unset;

      .i_popup_header {
        padding-bottom: 3.6rem;
      }
    }
  }

  .overlay {
    z-index: 1;
    padding: 1rem;
    left: 0;
    top: 0;
    @extend .w_100;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    @extend .position_fixed;
  }
}

.i_alert_popup {
  .i_popup_content {
    max-width: 63.7rem;

    .i_popup_footer {
      button {
        max-width: 27.5rem;
      }
    }

    p {
      color: $form-input-color;
      line-height: 140%;
    }
  }
}

.down_arrow {
  &.short_list {
    &.descending {
      img {
        transform: rotate(180deg);
      }
    }
  }
}

.group_members_li {
  padding-right: 2.2rem;

  .group_members_inner {
    gap: 2.2rem;
  }

  .group_memebers_wrapper {
    min-height: 7.2rem;
    border-bottom: 0.1rem solid $border-color;
    padding-right: 3rem;
  }

  .files_wrapper {
    gap: 2.2rem;
  }

  .member_pic {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 5rem;
    @extend .over_hidden;

    img {
      width: 3.2rem;
      height: 3.2rem;
      @extend .img_object_center;
    }
  }

  .group_m_names {
    @extend .fw_500;
    @extend .fs_16;
    color: $tab-text;
    line-height: 2.4rem;
  }
}

.mentees_discard_changes {
  .i_delete_invited_popup {
    .btn_reject {
      margin-right: 3.2rem !important;
    }

    .save_exit_btn {
      min-height: 4.8rem !important;
    }

    .back_form {
      max-width: fit-content !important;
    }
  }
}

.i_popup_notification {
  background: transparent;
  z-index: 999;
  height: 85%;
  position: inherit;
  padding: 0;
  .i_popup_notify_content {
    max-width: 55.9rem;
    @extend .position_absolute;
    min-width: 55.9rem;
    z-index: 999;
    .inner_pop_notify {
      padding: 2.4rem;
      .i_popup_header {
        padding-bottom: 0;
        > span {
          color: $primary-color;
        }
      }
    }
    .i_pop_notify_details {
      border: 0.1rem solid $border-color;
      @extend .p_0;
      border-radius: 0.8rem;
      .all_inner_content {
        max-height: 50rem;
        overflow: auto;
        h4 {
          padding: 2.4rem;
        }
      }
      .notification_wrapper {
        li {
          margin-left: 0;
          @extend .position_relative;
          padding: 2.4rem;
          border-bottom: 0.1rem solid $border-color;
          .notification_main {
            gap: 3.6rem;
            .notify_pic {
              width: 5.5rem;
              height: 5.5rem;
              .user_pic {
                width: 5.5rem;
                height: 5.5rem;
                background: #d6e2f5;
                margin-right: 0;
                img {
                  width: 3.2rem;
                  height: 3.2rem;
                }
              }
              > img {
                width: 5.5rem;
                height: 5.5rem;
                @extend .radius_round;
              }
            }
            .notification_inner {
              .unread_notify {
                @extend .position_absolute;
                right: 3rem;
                top: 50%;
                @extend .vertical-center;
                @extend .d_none;
              }
              .user_project {
                @extend .fs_16;
                @extend .fw_600;
                color: $black-color;
                max-width: 36rem;
                > span {
                  @extend .fw_400;
                  word-break: break-word;
                }
                .events_approved {
                  @extend .fw_700;
                }
                > span.fw_700 {
                  @extend .fw_700;
                }
              }
              .bottom_sec {
                gap: 3.4rem;
                padding-top: 0.5rem;
                .time {
                  @extend .fs_16;
                  color: $tab-text;
                  @extend .fw_400;
                  @extend .position_relative;
                  &:after {
                    content: "";
                    background-color: $tab-text;
                    @extend .position_absolute;
                    width: 1rem;
                    height: 1rem;
                    top: 0.4rem;
                    right: 0;
                    transform: translateX(2.2rem);
                    @extend .radius_round;
                  }
                }
                .date {
                  @extend .fs_16;
                  color: $tab-text;
                  @extend .fw_400;
                }
              }
            }
          }
          &.unread {
            background: rgba(214, 226, 245, 0.2);
            .notification_main {
              .notification_inner {
                .unread_notify {
                  @extend .d_block;
                }
              }
            }
          }
        }
        .not_available {
          border-radius: 0.8rem;
          box-shadow: 0 0.4rem 4rem rgba(36, 73, 137, 0.08);
          max-height: 72rem;
          overflow-x: hidden;
          overflow-y: auto;
          background: rgba(214, 226, 245, 0.2);
          border-bottom: 0;
          padding: 2.5rem;
          color: #828282;
        }
      }
    }
    &.count_notification_pop {
      background-color: $white-color;
      margin: auto;
      border-radius: 2rem;
      box-shadow: 0rem 0rem 3rem 0rem rgba(116, 116, 116, 0.15);
      @extend .position_absolute;
      @extend .w_100;
      right: -5rem;
      max-width: 70rem;
      overflow-y: auto;
      overflow-x: hidden;
      @include trans;
      z-index: 999999;
      margin-top: 3rem;
      border-radius: 0.8rem;
    }
  }
}
