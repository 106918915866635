.potential_match_details {
  .select_match_header {
    padding: 12px 32px;
    h2 {
      @extend .fw_600;
      @extend .fs_24;
    }
    .match_selected {
      padding: 0.4rem 1.2rem;
      max-width: 31.3rem;
      @extend .w_100;
      border-radius: 0.6rem;
      min-height: 4.8rem;
      p {
        @extend .fs_16;
        @extend .fw_700;
        span {
          @extend .fw_400;
        }
      }
      &.d_flex_center {
        @extend .position_absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    .btn {
      img {
        margin: 0;
      }
    }
    &.Pairs_select_match_header {
      @extend .position_relative;
      .right_matches {
        gap: 3.2rem;
      }
    }
    .resend_btn {
      border-radius: 0.8rem;
    }
    .unselect_pair_right {
      button {
        &.unselect_btn {
          &:hover {
            background-color: $primary-color;
            color: $white-color;
            > img {
              filter: brightness(21);
            }
          }
        }
      }
    }
  }
  .main_content_sec {
    background-color: $light-blue;
    border-top: 1px solid #dceafb;
    border-bottom: 1px solid #dceafb;
    padding: 38px 0 92px;
    @extend .position_relative;
    &::after {
      content: "";
      background-image: url(../images/Union.svg);
      background-repeat: no-repeat;
      width: 130px;
      height: 130px;
      @extend .position_absolute;
      right: -28px;
    }
    &::before {
      content: "";
      background-image: url(../images/Union_bg.svg);
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      @extend .position_absolute;
      left: 102px;
      bottom: -26px;
    }
    .banner_matches_deatils {
      width: 50%;
      @extend .text_center;
      .user_roles {
        p {
          @extend .fw_700;
          color: $dark-blue;
          margin-left: 10px;
        }
      }
      .mentor_name {
        @extend .fs_24;
        @extend .fw_600;
        margin-top: 0.8rem;
        max-width: 45rem;
        @extend .text_ellipsis;
        @extend .over_hidden;
        @extend .white_space_nowrap;
        margin: 0.8rem auto auto auto;
        line-height: 3.4rem;
      }
    }
  }
  .main_send_msg {
    margin-top: -75px;
    @extend .position_relative;
    .inner_details {
      width: 50%;
      .profile_img {
        width: 120px;
        height: 120px;
        border-radius: 100px;
        @extend .over_hidden;
        border: 2.727px solid $white-color;
        box-shadow: 6.6666669845581055px 6.6666669845581055px
          33.33333206176758px 0px rgba(134, 172, 218, 0.44);
        margin: auto;
        &.potential_profile_pic {
          @extend .fs_40;
          @extend .d_flex_center;
          color: $white-color;
        }
        img {
          @extend .img_object_center;
          @extend .w_100;
          height: 100%;
        }
      }
      .unselect_btn {
        margin: 16px auto 0;
        &:hover {
          background-color: $primary-color;
          color: $white-color;
          > img {
            filter: brightness(21);
          }
        }
        img {
          margin-left: 1.2rem;
        }
      }
    }
  }
  .main_user_info {
    @extend .w_50;
    padding-top: 4rem;
    padding-bottom: 7.4rem;
    @extend .d_flex;
    @extend .justify_content_center;
    &:first-child {
      border-right: 0.2rem solid #dceafb;
    }
    .user_info {
      @extend .text_left;
      .sub_info {
        &:not(:first-child) {
          margin-top: 1.6rem;
        }
      }
      .label {
        @extend .fw_700;
        color: $tab-text;
      }
      .value {
        @extend .fw_700;
        color: $form-input-color;
        @extend .fs_16;
        margin-top: 0.4rem;
        line-height: 150%;
        word-wrap: break-word;
        &.location {
          max-width: 20rem;
          @extend .w_100;
          min-height: 4.8rem;
        }
      }
      &:first-child {
        margin-left: 0;
        padding-right: 4rem;
      }
    }
    &.pair_activity_info {
      @extend .w_100;
      padding: 1.6rem 4rem;
      border-right: 0;
      button {
        min-width: 18.2rem;
        gap: 1rem;
        min-height: 4rem;
        padding: 0;
        margin: auto auto 3.2rem auto;
        border: 0.2rem solid $primary-color !important;
        &:hover {
          border: 0.2rem solid $primary-color;
          img {
            filter: brightness(21);
          }
        }
      }
      &:first-child {
        padding-left: 11rem;
      }
    }
    + .last_interaction {
      width: 20%;
      padding-top: 0;
      margin-right: 0;
      .last_date {
        @extend .text_center;
        @extend .fw_400;
        @extend .fs_14;
        margin-bottom: 0.8rem;
        span {
          @extend .fw_700;
        }
      }
      .via_conversation {
        @extend .text_center;
        @extend .fw_700;
        @extend .fs_14;
        span {
          @extend .fw_400;
        }
      }
    }
    &:first-child {
      .user_info {
        &.potential_userinfo {
          padding-right: 0;
          padding-left: 8.5rem;
          min-width: 27rem;
          max-width: 27rem;
          .sub_info {
            .value {
              max-width: 20rem;
            }
          }
        }
      }
    }
    &:nth-child(2) {
      .user_info {
        &.potential_userinfo {
          padding-right: 0;
          padding-left: 8rem;
          min-width: 27rem;
          max-width: 27rem;
          .sub_info {
            .value {
              max-width: 20rem;
            }
          }
        }
      }
    }
  }
  .matches_per {
    max-width: 18.5rem;
    @extend .w_100;
    border-radius: 1.2623rem;
    border: 0.3rem solid transparent;
    padding: 0.8rem 1.2rem;
    min-height: 10rem;
    @extend .align_items_center;
    @extend .justify_content_center;
    @extend .position_absolute;
    top: 18%;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 3.2rem;
    }
    h6 {
      @extend .fs_32;
      color: #1b1b1b;
      @extend .fw_700;
      margin-left: 2.1rem;
      line-height: 110%;
    }
    &.pairs_matches_per {
      @extend .position_absolute;
      left: 29rem;
      right: 0;
      min-height: 4.8rem;
      padding: 1.2rem;
      border: 0;
      max-width: 100%;
      border-radius: 0.6rem;
      max-width: 31.3rem;
      margin: 0 auto;
      h6 {
        @extend .fw_700;
        color: $tab-head;
        @extend .fs_16;
        span {
          @extend .fw_400;
        }
      }
    }
    .matcher_per_success {
      @extend .position_absolute;
      top: -1.7rem;
      // left: 0;
      // right: 0;
      @extend .text_center;
    }
  }
  .pair_archive {
    button {
      gap: 1.2rem;
      @extend .fw_700;
      color: $primary-color;
      @extend .fs_16;
    }
  }
  .matching_questions_and_scores {
    border-radius: 0.8rem;
    border: 0.2rem solid #dceafb;
    max-width: 94rem;
    @extend .w_100;
    margin: auto;
    margin-bottom: 5rem;
    padding-top: 3.2rem;
    @extend .text_center;
    .top_header {
      padding: 0rem 3.2rem 0px 3.2rem;
    }
    .i_matching_header {
      @extend .fw_600;
      @extend .fs_32;
      line-height: 110%;
      margin-bottom: 0.8rem;
    }
    .all_questions {
      padding: 4.8rem 3.2rem 6.4rem 3.2rem;
      .sub_questions_and_scores {
        @extend .over_hidden;
        border-radius: 0.8rem;
        border: 0.2rem solid #b6d4f8;
        &:not(:last-child) {
          margin-bottom: 4.8rem;
        }
        .question {
          background-color: $light-blue;
          padding: 1.6rem 2.4rem;
          @extend .align_items_center;
          .round_number {
            width: 3.2rem;
            height: 3.2rem;
            min-width: 3.2rem;
            border-radius: 10rem;
            border: 0.1rem solid #b6d4f8;
            color: $form-lable-color;
            @extend .fs_16;
            @extend .fw_700;
            @extend .d_flex_center;
            line-height: 150%;
          }
          p {
            @extend .fs_18;
            @extend .fw_700;
            line-height: 140%;
            margin-left: 2.4rem;
          }
        }
        .q_options {
          padding: 2.5rem;
          > div{
            flex: 1;
          }
          .inner_question {
            padding: 1.6rem 2.4rem;
            max-width: 39.4rem;
            @extend .w_100;
            &:not(:last-child) {
              margin-bottom: 1.6rem;
            }
          }
        }
        .maching_scores {
          background-color: $light-blue;
          padding: 0.8rem 3.2rem;
          min-height: 8.6rem;
          @extend .d_flex_center;
          h6 {
            @extend .fs_40;
            @extend .fw_700;
            margin-left: 2.4rem;
            line-height: 4.4rem;
          }
        }
      }
    }
    .marching_pagination {
      border-top: 0.1rem solid #dceafb;
      box-shadow: 0px 0px 3rem 0px rgba(214, 226, 245, 0.2);
      .pagination {
        padding: 0;
        box-shadow: unset;
        min-width: inherit;
        .par_page {
          border: unset;
          .row_par_page {
            .basic-single {
              box-shadow: unset;
            }
          }
        }
      }
    }
    .pagination {
      @extend .p_0;
      border-top: 0.1rem solid #dceafb;
      .par_page {
        @extend .border_0;
        padding: 1.6rem 6.4rem;
      }
      .css-1dimb5e-singleValue {
        @extend .text_left;
      }
    }
    .radio-info {
      padding: 1.4rem;
      border: 0.1rem solid $form-input-border;
      border-radius: 0.8rem;
      @extend .w_100;
      transition: all 0.2s ease;
      width: 14rem;
      @extend .d_flex;

      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        @extend .position_absolute;
        left: -9999px;
      }

      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label {
        @extend .position_relative;
        padding-left: 2.7rem;
        @extend .cursor_pointer;
        line-height: 2rem;
        color: $tab-text;
        border: 0.1rem solid transparent;
        height: auto;
        @extend .d_flex;
        @extend .align_items_center;
      }

      [type="radio"]:checked + label {
        border-radius: 0.7rem;
        padding: 0 1.5rem 0 2.3rem;
        width: auto;
      }

      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        content: "";
        @extend .position_absolute;
        left: 0px;
        top: 50%;
        width: 2.4rem;
        height: 2.4rem;
        border: 0.2rem solid $tab-text;
        @extend .radius_round;
        background: $white-color;
        transform: translateY(-50%);
        margin: 0;
      }

      [type="radio"]:checked + label:before {
        left: 0.9rem;
      }

      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        content: "";
        width: 1.2rem;
        height: 1.2rem;
        @extend .position_absolute;
        top: 50%;
        left: 5px;
        @extend .radius_round;
        transform: translateY(-50%);
        opacity: 1;
      }

      [type="radio"]:checked + label:after {
        left: 1.2rem;
        background: $success-msg-green;
        top: 0;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 10rem;
        border: 0;
        margin: 0;
        transform: translate(-50%, -50%);
      }

      [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
        margin: 0;
      }

      [type="radio"]:checked + label:before {
        border-color: $success-msg-green;
        left: 0;
        margin: 0;
      }

      label {
        margin-bottom: 0;
        @extend .fs_14;
      }
    }
    .inner_question {
      &.radio-info {
        background-color: $white-color;
        border: 0.1rem solid $form-input-border;
        border-radius: 0.8rem;
        &.disabled_checked {
          border-radius: 0.8rem;
          background: rgba(231, 241, 253, 0.6);
          border-color: transparent;
          [type="radio"]:checked {
            cursor: default;
          }
          [type="radio"]:checked + label:after {
            background-color: #b6d4f8;
            border-color: transparent;
            left: 1.2rem;
            top: 0;
            margin: 0;
            transform: translate(-50%, -50%);
          }
          [type="radio"]:checked + label:before {
            border-color: #b6d4f8;
            margin: 0;
          }
        }
        &.checked {
          border-radius: 0.8rem;
          border: 0.1rem solid $success-msg-green;
          background: #f3fdf2;
        }
        &.disabled {
          background-color: $white-color;
          border: 0.1rem solid $form-input-border;
          [type="radio"]:not(:checked) + label:after {
            background-color: $white-color;
            border-color: transparent;
          }
          [type="radio"]:not(:checked) + label:before {
            border-color: #bdbdbd;
            margin: 0;
          }
        }
      }
    }
  }
  &.select_match_details {
    .select_match_header {
      .btn {
        &.primary_btn {
          &:hover {
            color: $primary-color;
            &::after {
              opacity: 0;
            }
          }
        }
        img {
          margin-right: 1.2rem;
        }
      }
    }
    .matches_per {
      top: 13%;
      &::before {
        content: "";
        background-image: url(../images/big_Success_Checkmark.svg);
        background-repeat: no-repeat;
        width: 5.3rem;
        height: 5.3rem;
        @extend .position_absolute;
        top: -3.8rem;
      }
    }
    .main_send_msg {
      .inner_details {
        border-right: 0.2rem solid #dceafb;
      }
    }
  }
  .pairs_main_wrapper {
    .container {
      max-width: 100%;
      margin: 0 auto;
    }
    .main_send_msg {
      .matches_per {
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-40%);
        max-width: unset;
        min-width: 18.5rem;
        width: fit-content;
      }
    }
    .pairs_activity_detail {
      padding: 0 10rem;
    }
  }
}

.inner_question {
  .custom-checkbox {
    @extend .align_items_center;
    .checked {
      visibility: hidden;
      & + span::before {
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 0.4rem;
        border: 0.2rem solid #bdbdbd;
        background: $border-color;
      }
      &:checked + span:before {
        background-color: $success-msg-green;
        border-color: transparent;
      }
      & + span::after {
        top: -0.3rem;
        width: 0.6rem;
        height: 1.2rem;
      }
      &:disabled {
        &:checked + span:before {
          background-color: #b6d4f8;
          border-color: transparent;
        }
      }
    }
    p {
      color: $tab-text;
      margin-left: 16px;
      @extend .text_left;
      line-height: 138%;
    }
    &.custom_radio {
      p {
        margin-left: 0;
      }
      input[type="radio"]:not(:checked) {
        + label {
          margin-right: 1rem;
        }
      }
    }
  }
}
.q_options .inner_question:has(> .custom-checkbox :checked) {
  border-radius: 0.8rem;
  border: 0.1rem solid $success-msg-green;
  background: #f3fdf2;
}
.q_options {
  .checked:checked ~ .q_label {
    @extend .fw_700;
  }
  .checked .checked:checked ~ .q_label{
    color: $form-lable-color;
  }
}
.q_options .checked:checked:disabled ~ .q_label {
  @extend .fw_400;
}

.radio {
  &:checked + span:before {
    background-color: $success-msg-green;
    border-color: transparent;
  }
  & + span::after {
    top: -0.3rem;
    width: 0.6rem;
    height: 1.2rem;
  }
  &:disabled {
    &:checked + span:before {
      background-color: #b6d4f8;
      border-color: transparent;
    }
  }
}
