.i_mentor_details_page{
    .mentor_details_header{
        padding: 12px 10px 12px 32px;
        .mentor_title{
            @extend .fw_500;
            @extend .fs_18;
            margin-left: 8px;
        }
    }
    .btn{
        img{
            margin-right: 12px;
        }
        &.primary_btn{
            &:hover{
                color: $primary-color;
                &::after{
                    opacity: 0;
                }
            }
        }
        &.unselect_btn{
            border: 0.2rem solid $primary-color !important;
            background: $white-color;
            padding: 1.2rem 1.5rem;
            &:first-child{
                margin-right: 16px;
            }
        }
    }
    .mentor_profile_banner{
        background-color: $light-blue;
        border-top: 1px solid #DCEAFB;
        border-bottom: 1px solid #DCEAFB;
        padding: 36px 28px 36px 23px;
        @extend .position_relative;
        z-index: 1;
        &::after{
            content: '';
            background-image: url(../images/match_detail_bg.svg);
            background-repeat: no-repeat;
            width: 91px;
            height: 100px;
            @extend .position_absolute;
            left: 438px;
            top: 0;
            z-index: -1;
        }
        &::before{
            content: '';
            background-image: url(../images/Union_bg.svg);
            background-repeat: no-repeat;
            width: 10rem;
            height: 10rem;
            @extend .position_absolute;
            left: 102px;
            bottom: -26px;
            z-index: -1;

        }
        .m_name{
            @extend .fw_600;
            @extend .fs_32;
            line-height: 110%;
            margin-left: 1.2rem;
        }
        .profile_img{
            border-radius: 10rem;
            border: 3.273px solid $white-color;
            width: 14.4rem;
            height: 14.4rem;
            @extend .over_hidden;
            margin-bottom: -110px;
            img{
                @extend .img_object_center;
                @extend .w_100;
                height: 100%;
                border-radius: 10rem;
            }
            .generater_name{
                @extend .d_flex_center;
                width: 100%;
                height: 100%;
                font-size: 3.4rem;
                color: $white-color ;
                @extend .over_hidden;
            }
        }
    }
    .main_m_tab{
        .mentor_tab{
            margin-left: 13rem;
            .tab_wrapper{
                border-bottom:.1rem solid $border-color;
                li{
                    @extend .fs_18;
                    line-height: 140%;
                    @extend .fw_500;
                    color: $dark-blue;
                    padding: 1.9rem 3.5rem 2rem;
                    &.active{
                        color: $dark-blue;
                        @extend .fw_700;
                        a{
                            &::after{
                                content: '';
                                @extend .position_absolute;
                                background-color:$primary-color;
                                @extend .w_100;
                                height: 4px;
                                bottom: 0;
                                left: 0;
                                margin: 0 auto;
                                border-radius: 50px;
                                -webkit-transition: all 0.35s ease-in-out;
                                transition: all 0.35s ease-in-out;
                            }
                        }
                    }
                    a{
                        @extend .position_relative;
                        padding: 20px 20px 22px;
                    }
                }
            }
        }
        .mentor_all_info{
            padding: 54px 32px 52px;
        }

    }
    .label,label{
        color: $tab-text;
        @extend .fs_14;
        @extend .fw_700;
        line-height: 140%;
    }
    .value{
        color:$form-input-color;
        @extend .fs_16;
        @extend .fw_700;
        line-height: 150%;
    }
    .mentor_all_info{
        .container{
            max-width: 61.8rem;
            @extend .w_100;
            .m_staus_date{
                padding-bottom: 3.2rem;
                padding-top: 3.2rem;
                margin-bottom: 3.2rem;
                max-width: 49.4rem;
                @extend .w_100;
                margin-top: 2.4rem;
                gap: 3.2rem;
                > div{
                    gap: 1.6rem;
                }
                .tag{
                    padding: 0.5rem 1.2rem;
                    border-radius: 6px;
                    line-height: 140%;
                    color: $tab-head;
                    margin-right: 32px;
                    @extend .fw_400;
                    border-radius: 6px;
                    &.status_active{
                        background-color: $success-color;
                    }
                    &.status_invite{
                        background-color: $grey-100;
                    }
                    &.status_pending{
                        background-color: $light-yellow;;
                    }
                }
                &.border_bottom{
                    border-bottom: 0.1rem solid #DCEAFB;
                    border-top: 0.1rem solid #DCEAFB;
                }
            }
            .all_info_details{
                padding: 48px 0;
                &:not(:last-child){
                    border-bottom: 1px solid #DCEAFB;
                }
                .title{
                    @extend .fw_600;
                    font-size: 24px;
                    line-height: 110%;
                    margin-bottom: 32px;
                }
                &.personal_info{
                    padding-top: 0;
                }
                table{
                    @extend .w_100;
                    tr{
                        &:first-child{
                            .label{
                                padding-top: 0;
                            }
                        }
                    }
                    .label{
                        padding-top: 16px;
                        padding-right: 32px;
                    }
                    .value{
                        height: 4.2rem;
                        > span{
                            max-width: 35rem;
                            @extend .text_ellipsis;
                            @extend .over_hidden;
                            img{
                                margin-right: 1rem;
                                vertical-align: top;
                            }
                        }
                    }
                }
                .preloaded_Mentees{
                    .inner_data{
                        padding: 12px 8px;
                        .mentees_profile{
                            @include profile_small;
                            margin-right: 16px;
                            img{
                                @extend .img_object_center;
                                width: 2.4rem;
                                height: 2.4rem;
                            }
                        }
                        .m_name{
                            color: $dark-blue;
                            @extend .fw_500;
                            line-height: 140%;
                            text-decoration: underline;
                        }

                    }
                }
            }
        }
        &.matching_que_tab{
            .form-wrapper{
                padding-top: 0;
                form{
                    .form-steps{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .all_info_details{
        .form-steps{
            .form_group{
                margin-bottom: 1.6rem;
                .language ul li {
                    border-radius: 3rem;
                    background: $grey-100;
                    padding: 0.75rem 1.21rem;
                    font-size: 1.2rem;
                }
                label{
                    margin-bottom: 0.4rem;
                }
                .selected_value{
                    background: #F7FAFE;
                    border-radius: 8px;
                    padding:8px 8px;
                    padding-left: 3.5rem;
                    min-height: 48px;
                    height: 100%;
                    @extend .d_flex;
                    @extend .align_items_center;
                    gap: 8px;
                    margin-bottom: 0.8rem;
                    @extend .position_relative;
                    p{
                        @extend .fs_16;
                        @extend .fw_700;
                        line-height: 150%;
                        color: $form-input-color;
                        &.light_value{
                            @extend .fs_14;
                            @extend .fw_500;
                        }
                    }
                    &::after{
                        content: '';
                        background-color: $primary-color;
                        width: 0.8rem;
                        height: 0.8rem;
                        @extend .position_absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 1.5rem;
                        @extend .radius_round;

                    }
                    &.not_selected_value{
                        padding-left: 0.8rem;
                        &::after{
                            @extend .d_none;
                        }
                    }
                }
                table {
                    margin-top: 2rem;
                    tr {
                      @extend .position_relative;
                      .radio-box {
                        justify-content: center;
                      .radio-info {
                        padding: 0;
                        width: 5.5rem;
                        @extend .d_flex_center;
                        border: unset;
                        [type="radio"]:checked + label{
                            border: none;
                        }
                        [type="radio"]:not(:checked) + label:before{
                            border: 2px solid #BDBDBD;
                        }
                        [type="radio"]:checked + label,
                        [type="radio"]:not(:checked) + label {
                          border: 0;
                        }
                        [type="radio"]:checked + label:before,
                        [type="radio"]:not(:checked) + label:before {
                          left: 50%;
                          top: 50%;
                          transform: translate(-50%, -50%);
                        }
                        [type="radio"]:checked + label:after,
                        [type="radio"]:not(:checked) + label:after {
                          left: 50%;
                          top: 50%;
                          transform: translate(-50%, -50%);
                        }
                      }
                    }
                      th {
                        padding-bottom: 1.6rem;
                        @extend .fw_400;
                        border-bottom: 0.1rem solid $form-input-border;
                        font-size: 1.2rem;
                        color: $tab-text;
                      }
                      td {
                        padding: 1.6rem;
                        border-bottom: 0.1rem solid $form-input-border;
                        img {
                          vertical-align: middle;
                        }
                      }
                    }
                  }
            }
        }
        .step-info {
            margin-bottom: 1.6rem;
            .step-count {
                @include step-count;
            }
            p {
              @extend .fw_700;
              font-size: 1.6rem;
              @extend .text_capitalize;
              color: $dark-bg;
            }
        }
        &.physical_emotional{
            .form-steps{
                margin-bottom: 4.8rem;
                .form_group{
                    &:not(:last-child){
                        margin-bottom: 3.2rem;
                    }
                    label{
                        margin-bottom: 0.8rem;
                    }
                }
            }    
        }
        .up_table_inner{
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

