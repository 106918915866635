.header_sticky {
  + .group_page {
    padding-top: 11rem;
    padding-bottom: 7rem;
  }
}
.group_page {
  .edit_partner {
    &.group_details {
      .edit_title_btn {
        .add_btn_group {
          .blue_border_btn {
            border: 0.2rem solid $primary-color !important;
            min-width: 18.7rem;
          }
        }
        .edit_partner_title {
          @extend .fw_500;
          font-size: 1.8rem;
        }
      }
      .edit_details {
        .edit_info {
          padding-bottom: 3.3rem;
        }
        .edit_assign_school_info {
          background-color: $white-color;
          .group_add_li {
            margin-bottom: 2rem;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .step-info {
    .step_contation_title {
      + button {
        min-width: 22.8rem;
        padding: 0;
        min-height: 4rem;
        border: 0.2rem solid $primary-color !important;
        font-size: 1.4rem;
        color: $form-lable-color;
        &:hover {
          color: $white-color;
        }
      }
    }
  }
  .user-info {
    .user_profile {
      @extend .w_100;
      @extend .justify_content_start;
      border-radius: 0.4rem;
      background: $lighten-blue;
      display: inline-flex;
      width: auto;
      padding: 1.6rem;
      height: unset;
      box-shadow: none;
      @extend .border_0;
      gap: 1.6rem;
      img{
        width: 4rem;
        height: 4rem;
        @extend .img_object_center;
      }
      .user_profile_pic {
        @extend .w_100;
        max-width: 4rem;
      }
      .user-text {
        p {
          @extend .fw_600;
          color: $dark-blue;
          font-size: 1.6rem;
        }
        span {
          @extend .fw_500;
          color: $tab-text;
          font-size: 1.2rem;
          line-height: 1.96rem;
        }
      }
    }
  }
  .add_btn {
    &.gray_btn {
      min-width: 22.6rem;
      @extend .justify_content_center;
      &.blue_btn {
        @include apply_bgcolor(Light_blue_clr);
      }
      &:hover {
        + .tooltip {
          @extend .d_block;
          right: 0;
          left: auto;
        }
      }
    }
  }
  .table_wrapper {
    .table_responsive {
      min-height: auto;
      .table {
        &.group_data_listing {
          tbody {
            tr {
              td {
                .status_tab {
                  &.school_inst_tab {
                    max-width: 20rem;
                  }
                }
              }
              .name_toolkit {
                .user_name {
                  max-width: 13rem;
                }
              }
              &:nth-last-child(-n + 4) {
                .dot_icon_modal {
                  .dot_menu_modal {
                    bottom: 0;
                  }
                }
              }
              &.first_one {
                .dot_icon_modal {
                  .dot_menu_modal {
                    @extend .position_absolute;
                  }
                }
                &:first-child {
                  .dot_icon_modal {
                    .dot_menu_modal {
                      @extend .position_fixed;
                      bottom: auto;
                    }
                  }
                }
                &:nth-child(2) {
                  .dot_icon_modal {
                    .dot_menu_modal {
                      @extend .position_fixed;
                      bottom: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .i_popup {
    .i_popup_content {
      .send_main_form {
        .form-group.mail_msg {
          textarea {
            min-height: 13.2rem;
          }
        }
      }
    }
  }
  .partner_modal {
    @include apply_animation(partner_modal_animate);
    .assign_institution {
      .assign_new_school {
        .assign_new {
          gap: 0.3rem;
        }
      }
    }
  }
}
.edit_assign_school_info {
  // background: rgba(231, 241, 253, 0.5);
  padding: 1.6rem;
  border-radius: 0.8rem;
  border: 0.1rem solid $grey-100;
  ul {
    &.group_memeber_list {
      padding-right: 1.2rem;
      margin-bottom: 2.4rem;
      .group_add_li {
        margin-bottom: 2rem;
        &:last-child {
          margin-bottom: 0;
        }
        .files_wrapper {
          gap: 1.6rem;
          .member_pic {
            width: 3.2rem;
            height: 3.2rem;
            @extend .over_hidden;
            border-radius: 5rem;
            .user_pic {
              margin-right: 0;
              width: 3.2rem;
              height: 3.2rem;
            }
            img {
              width: 3.2rem;
              height: 3.2rem;
              @extend .img_object_center;
            }
          }
          .group_m_names {
            @extend .fw_600;
            color: $dark-blue;
            font-size: 1.6rem;
            line-height: 2.4rem;
          }
        }
        .group_names {
          span {
            @extend .fw_700;
            font-size: 1.2rem;
            color: $dark-blue;
          }
        }
        button {
          &.group_names {
            @extend .position_relative;
            &:hover {
              .tooltip {
                @extend .d_block;
                left: auto;
                right: 0;
                color: $white-color;
              }
            }
          }
        }
      }
      p {
        @extend .fs_16;
        @extend .fw_400;
        color: $black-color;
      }
    }
    + .blue_border_btn {
      border: 0.2rem solid $primary-color !important;
      margin-left: auto;
    }
  }
}
.group_info {
  .edit_assign_school_info {
    background: rgba(231, 241, 253, 0.5);
    padding: 0;
    ul {
      padding-right: 0;
      .gruop_add_text {
        padding-top: 1.6rem;
        padding-left: 1.6rem;
      }
      li {
        padding: 1.6rem;
        margin-bottom: 0;
        &:hover {
          background: rgba(231, 241, 253, 0.5);
        }
      }
    }
    .blue_border_btn {
      margin-right: 1.6rem;
      margin-bottom: 1.6rem;
      &.out_of_box_btn {
        @extend .position_absolute;
        top: -6rem;
        right: 0;
      }
    }
  }
  &.goroup_detils_list {
    .edit_assign_school_info {
      background: transparent;
      padding: 0;
      margin-bottom: 2.4rem;
      .group_memeber_list {
        li {
          margin-bottom: 0;
        }
      }
    }
  }
}
