input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $black-color !important;
}

.btn_select {
  background: $grey-text !important;
}

.filter_dropdown {
  padding: 1.2rem 3.2rem 0;
  padding-left: 0;

  .raect_dropdown,
  .App {
    .css-13cymwt-control {
      border-radius: 0.4rem;
      border: 0.1rem solid $separtor-color;
      background: $white-color;
      min-height: 4rem;
      // background: url(../images/filter_drop_down_arrow.svg)no-repeat center right 16px #fff;
    }

    // .css-13ipsp-control{
    //     background: url(../images/filter_drop_down_arrow.svg)no-repeat center right 16px #fff;
    // }

    .css-1fdsijx-ValueContainer {
      padding: 0 0 0 1.6rem;
    }

    .css-1xc3v61-indicatorContainer,
    .css-15lsz6c-indicatorContainer {
      padding: 0.8rem 1.6rem 0.8rem 0;
    }

    .css-1dimb5e-singleValue {
      font-size: 1.6rem;
      color: $black-color;
      line-height: 140%;
    }

    .css-t3ipsp-control {
      border-color: $blue-color;
      box-shadow: 0 0 0 0.1rem $blue-color;
      min-height: 4rem;
    }

    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    .css-1xc3v61-indicatorContainer,
    .css-15lsz6c-indicatorContainer {
      &:first-child {
        display: none;
      }
    }

    .css-1n6sfyn-MenuList {
      border-radius: 0.4rem;
      border: 0.1rem solid var(--grey-colors-grey-4, #bdbdbd);
      box-shadow: 0 0 3rem 0 rgba(116, 116, 116, 0.15);

      .css-tr4s17-option {
        background-color: #b6d4f8;
        color: $black-color;
        @extend .fs_16;
        @extend .montserrat_bold;
        @extend .fw_700;
        line-height: 150%;
        color: $form-lable-color;
        .admin_type_filter {
          @extend .fw_700;
        }
      }

      div {
        @extend .fs_16;
        @extend .fw_500;
        line-height: 140%;
        @extend .montserrat_medium;
        color: $form-lable-color;
      }
    }
    .css-MenuList {
      background-color: transparent !important;
      .recommend_custom_dropdown {
        &.disableList {
          .checked {
            opacity: 0.5;
          }
          .inner_custom_dropdown {
            opacity: 0.5;
          }
          .disableList_icon {
            &:hover {
              .tooltip {
                @extend .d_block;
                top: -3.1rem;
              }
            }
          }
        }
      }
    }
    .css-t3ipis-control {
      &:hover {
        border-color: $blue-color !important;
      }
    }
    .css-1n6sfyn-MenuList {
      > div {
        // padding: 0;
      }
    }
    .custom_dropdown {
      &:hover {
        background-color: $form-input-active-bg;
      }
      .inner_custom_dropdown {
        padding: 0;
      }
    }
  }

  .form-wrapper {
    padding: 0;

    .form_group {
      max-width: 24rem;
      @extend .w_100;
      margin-bottom: 0;
      &.statue_tabing {
        &.mentor_status {
          max-width: 29.5rem;
        }
      }

      &:not(:last-child) {
        margin-right: 2.4rem;
      }

      label {
        @include label;
        font-size: 1.2rem;
        margin-bottom: 0.4rem;
      }

      .with_check_box {
        .css-1n6sfyn-MenuList {
          > div {
            padding: 0;
          }
        }

        input[type="checkbox"] {
          width: 1.6rem;
          height: 1.6rem;
        }

        .status {
          padding: 0.4rem 1.2rem;
          border-radius: 0.8rem;

          &.status_padding {
            background-color: $grey-100;
          }

          &.status_active {
            background-color: $success-clr-bg;
          }
        }

        .css-tr4s17-option,
        .css-d7l1ni-option,
        .css-10wo9uf-option {
          @extend .d_flex;
          @extend .align_items_center;
          padding: 1.5rem 1.6rem !important;

          label {
            @extend .fs_14;
            @extend .montserrat_regular;
            @extend .fw_400;
            color: $tab-head;
            margin-left: 1.6rem;
            margin-bottom: 0;
          }
        }
      }

      .main_serach_box_dropdown {
        .serach_box_dropdown {
          border-radius: 0.4rem;
          border: 0.1rem solid $separtor-color;
          background: $white-color;
          min-height: 4rem;
          @extend .align_items_center;
          justify-content: space-between;
          padding: 0 1.6rem;

          svg {
            opacity: 0.2;
          }
        }

        .css-13cymwt-control,
        .css-t3ipsp-control {
          border-radius: 5rem !important;
          @extend .position_absolute;
          z-index: 2;
          max-width: 22.4rem;
          @extend .w_100;
          margin-top: 2.4rem;
          left: 50%;
          transform: translateX(-50%);

          .css-1xc3v61-indicatorContainer {
            @extend .d_none;
          }
        }

        .css-1nmdiq5-menu {
          padding-top: 6.2rem;
          border-radius: 0.4rem;
          border: 0.1rem solid var(--grey-colors-grey-4, #bdbdbd);
          box-shadow: 0 0 3rem 0 rgba(116, 116, 116, 0.15);
          @include trans;

          .css-1n6sfyn-MenuList {
            border-radius: unset;
            border: unset;
            box-shadow: unset;
            border-top: 0.1rem solid $border-color;
            padding-top: 0;
          }
        }
      }
      &.error_msg {
        .err_field {
          top: 4.3rem;
        }
      }
      .date_range_picker_filter{
        right: 0;
        left: auto;
      }
    }

    .i_close {
      border: 0.1rem solid $border-color;
      border-radius: 50%;
      width: 4rem;
      height: 4rem;
      @extend .d_flex;
      padding: 0.4rem;
      margin-left: auto;
      @extend .position_relative;
      flex-shrink: 0;
      img {
        @extend .w_100;
      }

      &:hover {
        .tooltip {
          @extend .d_block;
          top: -4.5rem;
          left: -4rem;
        }
      }
    }
  }
}

/*  scroll css section here*/
.scrolltop {
  display: none;
  @extend .position_fixed;
  bottom: 75px;
  right: 36px;
  &.show {
    @extend .d_block;
  }
  // display: none;
  .scroll {
    background-color: $primary-color;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    @extend .d_flex_center;
    @extend .hover_transition;
    @extend .cursor_pointer;

    &:hover {
      background-color: $darl-blue-bg;
    }
  }

  .tooltip {
    @include tooltip();
    top: -4.7rem;
    left: -1.5rem;
  }

  &:hover {
    .tooltip {
      @extend .d_block;
    }
  }
}

.pairs_page{
  .filter_dropdown{
    .form-wrapper{
      .form_group{
        .date_range_picker_filter{
          right: unset;
          left: 0;
        }
      }
    }
  }
}

.loader_bg {
  @extend .w_100;
  height: 100%;
  // background: rgba(22, 35, 83, 0.3019607843);
  @extend .position_absolute;
  top: 0;
  z-index: 99999;
  left: 0;
  right: 0;
  @extend .m_auto;
}

.loader {
  @extend .position_absolute;
  @extend .vertical-center;
  left: 0;
  right: 0;
  @extend .text_center;
  @extend .d_flex_center;
  @extend .m_auto;

  img {
    animation: spin_witch 2s linear infinite;
  }
}

@keyframes spin_witch {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(0);
  }
}

.btn_loader {
  position: initial;
  transform: none;
  img {
    max-width: 2.2rem;
    margin: 0;
  }
}

.raect_dropdown {
  .css-1nmdiq5-menu {
    .css-qr46ko {
    }
    .css-tr4s17-option {
      background-color: #b6d4f8;
      font-weight: 700 !important;
      color: $form-lable-color;
      padding: 1.5rem 1.6rem;
    }
  }
  #react-select-3-option-0,
  #react-select-3-option-1 {
    @extend .fs_16;
    @extend .fw_500;
    line-height: 140%;
    color: $form-lable-color;
    padding: 1.5rem 1.6rem;
  }
  .css-d7l1ni-option {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 140% !important;
    color: $form-lable-color !important;
    padding: 1.5rem 1.6rem !important;
  }
  .css-10wo9uf-option {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 140% !important;
    color: $form-lable-color !important;
    padding: 15px 16px !important;
  }
}

//maches dropdown error msg
.que_option {
  .error_msg {
    .css-13cymwt-control {
      border: 0.2rem solid $error-msg !important;
    }
    input {
      border: transparent !important;
    }
  }
  .err_field {
    .error_icon {
      right: 1.5rem;
      top: 4.4rem;
      background-color: $white-color;
      @extend .position_absolute;
    }
  }
}

.particepent {
  .mentees_pairs {
    gap: 1rem;
    border-radius: 0.8rem;
    background: #4287ff21;
    min-height: 4rem;
    @extend .fw_700;
    @extend .fs_16;
    color: $dark-blue;
    padding: 0 1.5rem;
  }
}

input[type="file"],
input[type="file"]::-webkit-file-upload-button {
  @extend .cursor_pointer;
}

// React Transition Group

.transition-enter {
  opacity: 0;
  transform: scale(0.9);
}

.transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.4s, transform 0.4s;
}

.transition-exit {
  opacity: 1;
  transform: translateX(0.4);
  transition: opacity 0.4s, transform 0.4s;
}

.transition-exit-active {
  opacity: 0;
  transform: translateX(0.4);
  transition: opacity 0.8s, transform 0.8s;
  transition: opacity 0.4s, transform 0.4s;
}

.fade-enter div {
  overflow-y: hidden;
  max-height: 0;
  min-height: 0rem;
}

.fade-enter-active div {
  max-height: 55rem;
  transition: all 0.8s;
}
.fade-exit div {
  max-height: 55rem;
}
.fade-exit-active div {
  overflow-y: hidden;
  max-height: 0;
  transition: all 0.8s;
}

.droupdown-animation-enter .list_school {
  overflow-y: hidden;
  max-height: 0;
}
.droupdown-animation-enter-active .list_school {
  max-height: 29rem;
  transition: all 500ms ease-in-out;
}
.droupdown-animation-enter-done .list_school {
  max-height: 29rem;
}
.droupdown-animation-exit .list_school {
  max-height: 29rem;
}
.droupdown-animation-exit-active .list_school {
  overflow-y: hidden;
  max-height: 0;
  transition: all 500ms ease-out;
}

input[type="file"],
input[type="file"]::-webkit-file-upload-button {
  @extend .cursor_pointer;
}

// Table Transition

.table-transition-enter {
  opacity: 0;
  transform: scale(0.9);
}

.table-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.4s, transform 0.4s;
}

.table-transition-exit {
  opacity: 0;
  transform: translateX(0);
  transition: opacity 0, transform 0;
}

.table-transition-exit-active {
  opacity: 0;
  transform: translateX(0);
  transition: opacity 0, transform 0s;
}
input[type="file"],
input[type="file"]::-webkit-file-upload-button {
  @extend .cursor_pointer;
}

.css-1nmdiq5-menu {
  z-index: 9 !important;
}

.required_field {
  color: $red-required;
}