.matches_page {
  .add_plus_btn {
    .plus_icon {
      img {
        @extend .d_block;
      }
    }
  }
  .matches_page_question {
    padding-top: 2.2rem;
    .css-1dimb5e-singleValue {
      @extend .fs_16;
    }
    h4 {
      @extend .fw_700;
      font-size: 1.8rem;
      color: $primary-color;
    }
    .que_row {
      margin-bottom: 3.5rem;
      .que_count {
        p {
          @extend .fw_400;
          span {
            @extend .fw_700;
          }
        }
        .clear_filter {
          gap: 1.2rem;
          color: $grey-text;
          line-height: 2.4rem;
          margin-left: 2rem;
          @extend .justify_content_center;
          @extend .d_flex;
          @extend .fw_700;
          &.active {
            color: $primary-color;
            svg {
              path {
                stroke: $primary-color;
              }
            }
          }
        }
      }
      .sorting_row {
        ul {
          gap: 3rem;
          li {
            gap: 0.8rem;
            @extend .cursor_pointer;
            .down_arrow {
              color: $tab-text;
              @extend .d_flex_align;
              gap: 0.8rem;
              @extend .fs_16;
              @extend .fw_600;
              &.descending {
                img {
                  transform: rotate(180deg);
                }
              }
            }
            .first_time {
              @extend .position_relative;
              .switch {
                z-index: 8;
                @extend .position_relative;
                border-radius: 200px;
                // background: #D9D9D9;
                padding: 0.5rem 1rem;
              }
              .archived_questions {
                @extend .d_block;
              }
            }
            .archived_questions {
              @extend .d_none;
            }
          }
        }
      }
    }
    .main_categories {
      .title {
        min-height: 6.4rem;
        @extend .cursor_pointer;
        padding: 0 0.8rem;
        &:hover {
          border-radius: 0.8rem;
          background: rgba(231, 241, 253, 0.53);
        }
        .Drag_dots {
          margin-right: 0.8rem;
        }
        .open_close_arrow {
          @extend .d_flex_center;
          @extend .w_100;
          max-width: 4rem;
          height: 4rem;
        }
      }
      .question_list {
        border: 0.2rem solid #b6d4f8;
        box-shadow: 0rem 0.4rem 0.6rem 0rem rgba(155, 155, 155, 0.04);
        background-color: $white-color;
        border-radius: 0.8rem;
        @extend .cursor_pointer;
        margin-bottom: 2.4rem;
        &:hover {
          border: 0.2rem solid #b6d4f8;
          box-shadow: 0.8rem 0.4rem 2.4rem 0px rgba(151, 169, 185, 0.24);
          .que_sidebar {
            .Drag_dots {
              @extend .d_block;
            }
          }
        }
        .que_sidebar {
          border-right: 0.1rem solid $grey-100;
          max-width: 4.8rem;
          @extend .w_100;
          @extend .justify_content_center;
          align-items: end;
          @extend .d_grid;
          @extend .text_center;
          align-content: space-between;
          ul {
            li {
              margin: 0.8rem 0;
              &:hover {
                .tooltip {
                  @extend .d_block;
                }
              }
            }
          }
          .Drag_dots {
            @extend .d_none;
          }
        }
        .que_content_area {
          @extend .w_100;
          .question_alert {
            border-radius: 0.8rem;
            background: rgba(199, 152, 55, 0.1);
            margin: 1.6rem;
            padding: 1rem;
            .main_error_msg {
              gap: 1rem;
              margin-bottom: 1.2rem;
              .error_msg {
                @extend .fw_600;
              }
            }
          }
          .que_top_header {
            padding: 0 1.6rem;
            min-height: 5.6rem;
            @extend .d_flex_between;
            .left_side {
              @extend .d_flex_align;
              width: 54%;
              gap: 1.6rem;
              .que_count {
                @extend .fs_16;
                @extend .fw_500;
                color: $tab-text;
              }
              .tag {
                @extend .fs_12;
                @extend .fw_700;
                color: $dark-blue;
                background-color: $form-input-active-bg;
                padding: 0.4rem 0.8rem;
                border-radius: 8px;
              }
              .total_ans {
                @extend .d_flex;
                padding: 0.4rem 1.2rem;
                gap: 0.8rem;
                border: 0.1rem solid $separtor-color;
                border-radius: 8px;
                p {
                  @extend .fs_12;
                  @extend .fw_700;
                  color: $tab-head;
                }
              }
            }
            .right_side {
              width: 46%;
              @extend .position_relative;
              table {
                @extend .w_100;
                .date {
                  @extend .fs_16;
                  color: $form-lable-color;
                }
                .status_tab {
                  @extend .d_inline_block;
                }
                h5 {
                  @extend .fs_16;
                  @extend .fw_700;
                  color: $form-lable-color;
                }
                .text_right {
                  .menu_dots {
                    border-radius: 10rem;
                    border: 0.2rem solid transparent;
                    &:hover {
                      border: 0.2rem solid $blue-color;
                    }
                  }
                  .option_menu {
                    margin-left: auto;
                    padding: 0.5rem;
                    @extend .d_flex_center;
                    @extend .justify_content_end;
                    @extend .cursor_pointer;
                    &.active {
                      width: 4rem;
                      height: 4rem;
                      border: 0.2rem solid $blue-color;
                      @extend .radius_round;
                      background: $white-color;
                    }
                  }
                }
                .edit_icon {
                  @extend .position_relative;
                  img {
                    border: 0.2rem solid transparent;
                    border-radius: 10rem;
                    padding: 0.5rem;
                    &:hover {
                      border: 0.2rem solid $blue-color;
                    }
                  }
                  &:hover {
                    .tooltip {
                      @extend .d_block;
                      top: -4.3rem;
                    }
                  }
                }
                .dot_menu_modal {
                  max-width: 21.6rem;
                  @extend .position_absolute;
                  right: -1.6rem;
                  .list_dot_menu {
                    color: $form-lable-color;
                    @extend .fs_16;
                    @extend .fw_500;
                    opacity: 0.6;
                    &:hover {
                      opacity: 1;
                    }
                  }
                }
                &.question_listing {
                  td {
                    &:first-child {
                      width: 30%;
                    }
                    &:nth-child(2) {
                      width: 30%;
                    }
                    &:nth-child(3) {
                      width: 11%;
                      @extend .text_center;
                    }
                    &:nth-child(4) {
                      width: 20%;
                    }
                    &:nth-child(5) {
                      width: 0%;
                    }
                  }
                }
              }
            }
          }
          .que_option {
            padding: 0 0 2.4rem 1.6rem;
            width: 50%;
            .categories_name {
              @extend .fw_500;
              color: $blue-color;
            }
            .question {
              margin: 0.8rem 0;
              @extend .fw_700;
              font-size: 1.8rem;
              color: $form-input-color;
              line-height: 140%;
            }
            .inner_question {
              padding: 0.8rem 0;
              .custom-checkbox {
                p {
                  color: $title-color;
                }
              }
            }
          }
          .que_footer {
            border-top: 0.1rem solid $grey-100;
            background: #fafcff;
            padding: 1.7rem 0;
            @extend .d_flex_center;
            border-bottom-right-radius: 0.8rem;
            .down_arrow {
              @extend .position_relative;
              border-radius: 10rem;
              border: 0.1rem solid transparent;
              width: 2.4rem;
              height: 2.4rem;
              @extend .text_center;
              &:hover {
                border: 0.1rem solid $tab-text;
                .tooltip {
                  @extend .d_block;
                  top: -4rem;
                  left: -3rem;
                }
              }
            }
          }
        }
        &.add_question {
          .que_wrapper {
            .que_content_area {
              .que_top_header {
                padding: 0 1.6rem 0 2.4rem;
                .left_side {
                  @extend .justify_content_between;
                  @extend .w_100;
                  .close_icon {
                    width: 2.6rem;
                  }
                }
              }
              .que_option {
                padding: 1.6rem 2.4rem;
                .filter_dropdown {
                  padding: 0;
                  .form-wrapper {
                    margin-bottom: 0.8rem;
                    form {
                      max-width: inherit;
                      .form-steps {
                        // margin-bottom: 0;
                        .form_group {
                          padding: 0.8rem 0;
                          &:not(:last-child) {
                            // margin-bottom: 0.8rem;
                          }
                          .form_control {
                            border-color: $separtor-color;
                            &.blue_border {
                              border: 0.1rem solid $lighten-dark-blue;
                            }
                          }
                          &.edit_option {
                            img {
                              opacity: 0;
                            }
                            .form_control {
                              border-color: transparent;
                            }
                            &:hover {
                              .form_control {
                                border-color: $separtor-color;
                              }
                              img {
                                opacity: 1;
                              }
                            }
                          }
                        }
                      }
                    }
                    .form_group {
                      max-width: unset;
                      .form_control {
                        &.blue_border {
                          border: 0.1rem solid $lighten-dark-blue;
                        }
                      }
                      textarea {
                        min-height: 4.8rem;
                        padding: 1.2rem 1.5rem;
                        width: 100% !important;
                        border: 1px solid $separtor-color;
                      }
                      .radio-info {
                        width: unset;
                        padding: 0 1.6rem 0 0.8rem;
                        border: unset;
                        [type="radio"]:not(:checked) + label:before {
                          border: 0.2rem solid #bdbdbd;
                        }
                        [type="radio"]:checked + label,
                        [type="radio"]:not(:checked) + label {
                          border: 0;
                          outline: unset;
                          padding: 1rem 0rem 1rem 2.4rem;
                          width: unset;
                          height: 4.2rem;
                        }
                        [type="radio"]:checked + label:after {
                          left: 0.5rem;
                        }
                        [type="radio"]:checked + label:before {
                          left: 0;
                        }
                      }
                      .inner_question {
                        padding: 0 1.6rem 0 0.8rem;
                        padding-bottom: 0.8rem;
                        .custom-checkbox {
                          @extend .d_grid;
                          .checked + span::before {
                            margin-left: 0;
                            margin-top: 0;
                          }
                          .checked + span::after {
                            left: -0.1rem;
                          }
                          .checked + span {
                            width: 2.4rem;
                          }
                        }
                      }
                      .add_plus_icon {
                        @extend .cursor_pointer;
                        @extend .d_block;
                        width: 3.2rem;
                        height: 3.2rem;
                        @extend .position_absolute;
                        top: 1.6rem;
                        right: 1rem;
                      }
                      .input_remove {
                        top: 2.4rem;
                      }
                    }
                  }
                  .raect_dropdown {
                    .css-13cymwt-control,
                    .css-t3ipsp-control {
                      min-height: 4.8rem;
                    }
                  }
                  .raect_dropdown.blue_border {
                    .css-13cymwt-control {
                      border: 0.1rem solid $lighten-dark-blue;
                    }
                  }
                }
                .assign_btn {
                  &:hover {
                    color: $primary-color;
                    &::after {
                      background-color: transparent;
                    }
                  }
                }

                &.right_side_dropdown {
                  padding: 1.6rem 8.8rem 1.6rem 3.2rem;
                  .form_group {
                    &:not(:last-child) {
                      margin-right: 3.2rem;
                    }
                    .checkbox {
                      .custom-checkbox {
                        @extend .d_flex;
                        .checkmark {
                          margin-right: 0.6rem;
                        }
                        .checked {
                          + span {
                            &::after {
                              top: 0;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .que_footer {
                @extend .d_flex_between;
                padding: 0.8rem 1.6rem;
                .assign_btn {
                  min-width: 10.5rem;
                  &.save_btn {
                    @extend .fs_14;
                  }
                }
                .que_footer_wrap {
                  gap: 3.2rem;
                  .row_par_page {
                    gap: 3.2rem;
                    h4 {
                      @extend .fs_14;
                      @extend .fw_600;
                      line-height: 140%;
                      color: $tab-text;
                    }
                    .form_control {
                      @extend .radius_4;
                      padding: 0.8rem 1.6rem;
                      border: 0.1rem solid $separtor-color;
                      appearance: none;
                      outline: none;
                      background: url(../images/pagi_arrow.svg) no-repeat center
                        right$white-color;
                      background-size: auto;
                      @extend .w_100;
                      max-width: 20.8rem;
                      @extend .cursor_pointer;
                    }
                    .basic-single {
                      min-width: 20.8rem;
                      max-width: 20.8rem;
                      border-radius: 0.4rem;
                      background: $white-color;
                      box-shadow: 0px 0px 3rem 0px rgba(116, 116, 116, 0.15);
                      outline: none;
                      .css-1hb7zxy-IndicatorsContainer {
                        @extend .d_none;
                      }
                    }
                  }
                  .switch {
                    gap: 1.6rem;
                    .switch_name {
                      @extend .fs_16;
                      @extend .fw_500;
                      order: 2;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .filter_dropdown {
      .form-wrapper {
        .form_group {
          label {
            @extend .fs_14;
            @extend .fw_700;
            color: $form-lable-color;
          }
        }
      }
    }
  }
  .table_wrapper {
    .table_responsive {
      .table {
        tbody {
          tr {
            td {
              .som_percentage {
                .status_tab {
                  @extend .position_relative;
                }
                .name_toolkit {
                  &:hover {
                    .full_username {
                      width: 35.8rem;
                      @extend .d_block;
                      white-space: break-spaces;
                      left: -18rem;
                      bottom: 1.5rem;
                      top: auto;
                      @extend .text_center;
                    }
                  }
                  &.red_info_circle {
                    @extend .position_absolute;
                    top: -0.8rem;
                    right: -0.8rem;
                    background: $white-color;
                    border-radius: 10rem;
                    height: 2.4rem;
                    width: 2.4rem;
                    @extend .d_flex_center;
                    padding-top: 0;
                    outline: 0.5rem solid $white-color;
                    -webkit-border-radius: 10rem;
                    -moz-border-radius: 10rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .modal_comman {
    .delete_modal {
      &.skip_modal {
        .skip_user_modal {
          .table_responsive {
            .skip_modal_listing {
              tr {
                td {
                  &:first-child {
                    width: 10%;
                  }
                  &:nth-child(2) {
                    width: 10%;
                  }
                  &:nth-child(5) {
                    width: 12%;
                  }
                  &:nth-child(6) {
                    width: 6%;
                  }
                  .skip_data {
                    max-width: 14rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .som_drop {
    .css-1jqq78o-placeholder {
      color: $form-input-color !important;
      @extend .fw_500;
    }
  }
  .table_wrapper {
    .table_responsive {
      .table {
        tbody {
          tr {
            td {
              .name_toolkit {
                &:hover {
                  .full_username {
                    left: -0.7rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .form_group {
    .options_status {
      .all_location {
        .list_status {
          .select_tab {
            .region_tab {
              padding: 0.5rem 1.2rem;
            }
          }
        }
      }
    }
  }
  .form_group {
    .options_status {
      &.pairs_school_drop {
        .all_location {
          .i_main_options {
            .select_tab {
              span {
                @extend .d_block;
                padding-left: 0;
                padding-right: 0;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
  .filter_dropdown {
    .form-wrapper {
      .i_close {
        margin-top: 2rem;
      }
    }
  }
}
